import { HelperProps, helpers } from '@components';
import styled, { keyframes, css, Keyframes } from 'styled-components';
import { SpacingName } from '../Theme';

export const fadeUp = keyframes`
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const fadeIn = keyframes`
  100% {
    opacity: 1;
  }
`;

const centered = css`
  display: flex;
  align-content: center;
  justify-content: center;
`;

type AnimationProps = HelperProps & {
  centered?: boolean;
  seconds?: number;
  delaySeconds?: number;
  repeat?: boolean;
};

export const animation = (keyframes: Keyframes) => css<AnimationProps>`
  height: 100%;
  width: 100%;
  animation: ${keyframes} ${props => (props.seconds ? `${props.seconds}s` : '0.5s')} ease-out
    ${props => (props.delaySeconds ? `${props.delaySeconds}s` : '0s')} both;
  ${props => (props.centered ? centered : '')};
  ${props => (props.repeat ? 'animation-iteration-count: infinite;' : '')};
`;

export default {
  FadeUp: styled.div<
    AnimationProps & {
      amount?: SpacingName;
    }
  >`
    ${helpers};
    opacity: 0;
    transform: ${props => `translateY(${props.amount ? props.theme.spacing[props.amount] : props.theme.spacing.sm})`};
    ${animation(fadeUp)}
  `,
  FadeIn: styled.div<AnimationProps>`
    ${helpers};
    opacity: 0;
    ${animation(fadeIn)}
  `,
  FadeDown: styled.div<
    AnimationProps & {
      amount?: SpacingName;
    }
  >`
    ${helpers};
    opacity: 0;
    transform: ${props => `translateY(-${props.amount ? props.theme.spacing[props.amount] : props.theme.spacing.sm})`};
    ${animation(fadeUp)}
  `
};
