import styled from 'styled-components';
import helpers from '../helpers';

const Limit = styled.div`
  ${helpers};
`;

export default {
  xsmall: styled(Limit)`
    max-width: 560px;
  `,
  small: styled(Limit)`
    max-width: ${props => props.theme.breakpoints.small};
  `,
  medium: styled(Limit)`
    max-width: ${props => props.theme.breakpoints.medium};
  `,
  large: styled(Limit)`
    max-width: ${props => props.theme.breakpoints.large};
  `,
  xlarge: styled(Limit)`
    max-width: ${props => props.theme.breakpoints.xlarge};
  `
};
