import styled from 'styled-components';

import { CardVariant } from 'src/components/Card';
import { HelperProps, helpers, Tile } from '@components';
import { DesktopAndUp, LaptopAndUp } from '../stylingUtils';
import Theme, { SpacingList } from '../Theme';

export default {
  Container: styled.div<HelperProps & { noHoverEffect?: boolean; disabled?: boolean }>`
    ${helpers};
    height: 100%;
    position: relative;

    ${Tile} {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    &:hover {
      img {
        ${props => (props.noHoverEffect ? '' : 'transform: scale(1.05)')};
      }
    }

    ${props => (props.disabled ? 'pointer-events: none;' : '')};
  `,
  ImageContainer: styled.div<{ variant?: string }>`
    display: flex;
    justify-content: center;
    height: 220px;
    overflow: hidden;

    ${props => (props.onClick ? 'cursor: pointer;' : '')}

    ${props => LaptopAndUp(props)} {
      height: 190px;
    }

    ${props => DesktopAndUp(props)} {
      height: 220px;
    }

    img {
      width: 100%;
      object-fit: cover;
      transition: transform 100ms ease-out;
    }

    ${props => (props.variant === CardVariant.noImagePadding ? 'overflow: unset; img { object-fit: unset }' : '')}
  `,
  ContentContainer: styled.div<{
    disableSpaceBetween?: boolean;
    padding: boolean | keyof SpacingList | undefined;
    variant?: CardVariant;
    reversedColoristic?: boolean;
    hidePadding?: boolean;
    noContentColumnDirection?: boolean;
  }>`
    flex: 2;
    display: flex;
    ${helpers};

    ${props => (props.noContentColumnDirection ? '' : 'flex-direction: column;')}

    ${props => (props.disableSpaceBetween ? '' : 'justify-content: space-between;')}

    ${props => (props.reversedColoristic ? `background: ${Theme.colors.primary};` : '')}

    ${props => (props.hidePadding ? `padding: 0;` : '')}

    ${props =>
      props.variant === CardVariant.noImagePadding ? `padding: ${props.theme.spacing.md}; padding-top: 0` : ''}
  `
};
