import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { PrimitiveType } from 'intl-messageformat';
import { FormatXMLElementFn } from 'intl-messageformat';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { AppState } from 'src/redux/reducers';
import TranslationEditButton from '../TranslationEditButton';
import parse from 'html-react-parser';
import { nl2brPlainHtml } from '@utils/stringUtils';

export const NO_TRANSLATION_WARNING = 'No translation key provided';

const LocalizedText = (props: ResProps) => {
  return (
    <>
      {props.supportHtml ? (
        props.content ? (
          props.supportLineBreaksInHtml ? (
            parse(nl2brPlainHtml(props.content))
          ) : (
            parse(props.content)
          )
        ) : (
          <FormattedHTMLMessage
            id={props.id || NO_TRANSLATION_WARNING}
            values={props.values as Record<string, PrimitiveType>}
            defaultMessage={props.defaultMessage}
          />
        )
      ) : props.content ? (
        props.content
      ) : (
        <FormattedMessage
          id={props.id || NO_TRANSLATION_WARNING}
          values={props.values}
          defaultMessage={props.defaultMessage}
        />
      )}

      <TranslationEditButton translationKey={props.onPageEditorTranslationKey || props.id || 'none'} />
    </>
  );
};

const mapStateToProps = ({ appReducer }: AppState) => ({
  currentTranslationKey: appReducer.currentTranslationKey,
  translationUpdatingStatus: appReducer.translationUpdatingStatus
});

export type LocalizedTextData = Record<string, PrimitiveType | React.ReactElement | FormatXMLElementFn>;

type ResProps = ReturnType<typeof mapStateToProps> & {
  id?: string | undefined;
  values?: LocalizedTextData;
  description?: string;
  defaultMessage?: string | undefined;
  supportHtml?: boolean;
  //Works only with provided content and supportHtml set to true
  supportLineBreaksInHtml?: boolean;
  onPageEditorTranslationKey?: string;
  content?: string;
};

export default connect(mapStateToProps)(LocalizedText);
