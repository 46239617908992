import styled from 'styled-components';

export default {
  Container: styled.div<{ shouldHaveHover?: boolean; shouldUnderlineOnHover?: boolean; height?: string }>`
    svg,
    path {
      fill: ${props =>
        props.color ? props.theme.colors.text[props.color] : props.theme.colors.text.onWhite} !important;
      color: ${props =>
        props.color ? props.theme.colors.text[props.color] : props.theme.colors.text.onWhite} !important;
    }

    a {
      text-decoration: none;
      color: ${props => (props.color ? props.theme.colors.text[props.color] : props.theme.colors.text.onWhite)};
    }

    ${props => (props.shouldHaveHover ? 'cursor: pointer;' : '')}
    ${props =>
      props.shouldUnderlineOnHover
        ? `:hover {
        div {
          text-decoration: underline;
          text-decoration-color: ${props.color ? props.theme.colors.text[props.color] : props.theme.colors.text.onWhite}
        }
      }
      `
        : ''}

    ${props => (props.height ? `height: ${props.height};` : '')};

    //[JU] Why here is added this cursor when above there is a control over it already?
    cursor: pointer;

    //[JU] Because of the mess above, I added a strict check for the false value.
    //Unfortuntely I don't have time for now to check the references and assumptions about shouldHaveHover.
    ${props => (props.shouldHaveHover === false ? 'cursor: default;' : '')}
  `
};
