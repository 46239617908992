import { Tile } from '@components';
import styled from 'styled-components';

export default {
  Tile: styled(Tile)<{ active: boolean }>`
    background-color: ${props =>
      props.active ? props.theme.colors.text.onWhiteSelected : props.theme.colors.text.lightGraySecondary};
    border-radius: 1px;
    width: 18px;
    height: 8px;
  `
};
