import Flex from 'src/components/Flex';
import styled from 'styled-components';

export default {
  RelativeDiv: styled(Flex)`
    position: relative;
  `,
  ActionsContainer: styled(Flex)<{ isOpen: boolean }>`
    min-width: 250px;
    max-width: 400px;
    height: 40px;
    background: gray;
    position: absolute;
    top: 100%;
    margin-top: 8px;
    left: -40px;

    padding: 8px 0px;
    background-color: ${props => props.theme.colors.white};
    box-shadow: 5px 20px 50px 0px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    border: 1px solid ${props => props.theme.colors.lightGraySecondary};

    max-height: ${props => (props.isOpen ? '400px' : 0)};
    opacity: ${props => (props.isOpen ? 1 : 0)};
    transition: all 0.15s ease;
    pointer-events: ${props => (props.isOpen ? 'all' : 'none')};
    user-select: ${props => (props.isOpen ? 'auto' : 'none')};
  `,
  ActionItem: styled(Flex)`
    padding: 11px 16px;
    width: 100%;

    &:hover {
      background-color: ${props => props.theme.colors.background};
    }
  `
};
