import React from 'react';
import { AppTheme } from '../Theme';
import HeaderHeroStyles from './styles';

export type HeaderHeroProps = React.PropsWithChildren<{
  image?: string | null;
  positionHorizontal?: 'left' | 'center' | 'right';
  positionVertical?: 'top' | 'center' | 'bottom';
  adaptiveHeight?: boolean | null;
  backgroundColor?: keyof AppTheme['colors'] | null;
  imageSize?: 'conver' | 'contain' | string;
}>;

export type HeaderHeroContentProps = React.PropsWithChildren<{
  adaptiveHeight?: boolean | null;
}>;

const HeaderHero: React.FC<HeaderHeroProps> = ({
  children,
  image,
  positionHorizontal,
  positionVertical,
  adaptiveHeight,
  backgroundColor,
  imageSize
}) => (
  <HeaderHeroStyles.Container
    backgroundColor={backgroundColor}
    image={image}
    imageSize={imageSize}
    positionHorizontal={positionHorizontal}
    positionVertical={positionVertical}
  >
    <HeaderHeroStyles.Content adaptiveHeight={adaptiveHeight}>{children}</HeaderHeroStyles.Content>
  </HeaderHeroStyles.Container>
);

export default HeaderHero;
