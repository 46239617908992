import styled from 'styled-components';

const ImageLoader = styled.div<{ icons: string[] }>`
  :after {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
    content: ${props => props.icons.map(icon => `url(/static/icons/${icon}.svg)`).join(' ')};
  }
`;
export default ImageLoader;
