import React from 'react';

const useOpenableState = (
  initialState = false
): [boolean, { toggle: () => void; open: () => void; close: () => void; set(isOpen: boolean): void }] => {
  const [isOpen, setIsOpen] = React.useState(initialState);
  const toggle = React.useCallback(() => setIsOpen(!isOpen), [isOpen]);
  const open = React.useCallback(() => setIsOpen(true), []);
  const close = React.useCallback(() => setIsOpen(false), []);
  return [isOpen, { toggle, open, close, set: setIsOpen }];
};

export default useOpenableState;
