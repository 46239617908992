import React from 'react';

import Styles from './styles';
import { AppLink, Text, Tooltip } from '@components';

type AdaptiveTooltipTextProps = {
  text: string;
  link: string;
};

const isEllipsisActive = (e: HTMLElement) => {
  return e.offsetWidth < e.scrollWidth;
};

const AdaptiveTooltipText: React.FC<AdaptiveTooltipTextProps> = ({ text, link }) => {
  const adaptiveRef = React.useRef<HTMLDivElement>(null);
  const [showTooltip, setShowTooltip] = React.useState(false);

  React.useEffect(() => {
    if (adaptiveRef.current) {
      setShowTooltip(isEllipsisActive(adaptiveRef.current));
    }
  }, [adaptiveRef]);

  return (
    <Tooltip
      type={showTooltip ? 'hover' : 'disabled'}
      innerRender={() => <Text.Span16_26>{text}</Text.Span16_26>}
      noInline
      tooltipWidth={500}
    >
      {() => {
        return (
          <AppLink href={link}>
            <Styles.EllipsisContainer ref={adaptiveRef}>
              <Text.Span18_26_bold whiteSpace="nowrap">{text}</Text.Span18_26_bold>
            </Styles.EllipsisContainer>
          </AppLink>
        );
      }}
    </Tooltip>
  );
};

export default AdaptiveTooltipText;
