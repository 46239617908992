import React from 'react';
import LocalizedText from 'src/components/LocalizedText';
import TextIcon from 'src/components/TextIcon';
import TileTitleUrl from '../TileTitleUrl';
import Breakpoints from '../Breakpoints';
import { SVGIcon } from '@components';

type LinkTileProps = {
  title: string;
  uri: string;
};

const LinkTile: React.FC<LinkTileProps> = props => {
  return (
    <TileTitleUrl
      title={props.title}
      data-analytics-id={props['data-analytics-id']}
      icon={() => <SVGIcon height="48px" src="/static/icons/externalLink.svg" />}
      url={() => (
        <a href={props.uri} target="_blank" rel="noopener noreferrer">
          <Breakpoints.LaptopAndUp>
            <TextIcon
              shouldUnderlineOnHover
              variant="H6"
              rightIconComponent={() => <img src="/static/icons/semiArrowRightTinyPrimary.svg" />}
              color="onWhitePrimary"
            >
              <LocalizedText id="link" description="Link url" />
            </TextIcon>
          </Breakpoints.LaptopAndUp>
          <Breakpoints.TabletAndDown>
            <SVGIcon padding="md" paddingRight="none" src="/static/icons/semiArrowRightTinyPrimary.svg" />
          </Breakpoints.TabletAndDown>
        </a>
      )}
    />
  );
};

export default LinkTile;
