import styled, { css, keyframes } from 'styled-components';
import helpers, { GenericSpace, HelperProps, spacing2Value } from '../helpers';
import { InputColorName, TextColorName } from '../Theme';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

type RadioGraphicProps = {
  centeredButton?: boolean;
  graphic: string;
  graphicSize?: number;
  checkedGraphic?: string;
  hoveredGraphic?: string;
  disabledGraphic?: string;
};

const ImageBackground = (path: string, size?: number) => {
  return `background-repeat: no-repeat;
        background-position: center;
        background-size: ${size || 24}px; ${size || 24}px;
        background-color: transparent;
        background-image: url('${path}');
        width: ${size || 24}px;
        height: ${size || 24}px;
        `;
};

const inputStatus = css`
  &::after {
    position: absolute;

    font: normal normal normal 17px/1 'Font Awesome 5 Free';
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-weight: 900;

    box-sizing: border-box;

    margin: auto;
    right: 16px;
    pointer-events: none;

    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    bottom: 0;
    height: 1em;
    width: 1em;
  }
`;

const loadingStatus = css`
  ${inputStatus};
  &::after {
    content: '';
    box-sizing: border-box;
    animation: ${spin} 0.6s linear infinite;
    border-radius: 100%;
    border: 2px solid ${props => props.theme.colors.text.onWhiteLight};
    border-top-color: transparent;
    border-right-color: transparent;
    line-height: 1;
  }
`;

const successStatus = css`
  ${inputStatus};
  &::after {
    content: '';
    color: ${props => props.theme.colors.green};

    display: block;
    background-image: url('/static/icons/checkGreen.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 24px 24px;
    height: 24px;
    width: 24px;
  }
`;

const errorStatus = css<{ errorStatusBackgroundColor?: string }>`
  ${inputStatus};
  &::after {
    content: '';
    color: ${props => props.theme.colors.error};

    display: block;
    background-image: url('/static/icons/error.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 24px 24px;
    height: 24px;
    width: 24px;

    background-color: ${props => props.errorStatusBackgroundColor || '#fff'};
    box-shadow: 0px 0px 2px 4px ${props => props.errorStatusBackgroundColor || '#fff'};
  }
`;

const checkStyling = css<{ gap?: GenericSpace }>`
  transition: all 250ms ease-in-out 0s;
  /* Ref: https://codepen.io/spacemonkey/pen/vmZROv */
  /* Hide the actual checkbox input */
  position: absolute;
  opacity: 0;

  /* & + * = Label right next to the checkbox */
  & + * {
    /* Makes the label clickable */
    cursor: pointer;

    /* Position the label */
    position: relative;
    padding: 0;
    width: 100%;
    display: flex;
    align-items: center;

    /* Label(text) style */
    color: ${props => props.theme.colors.text.onWhite};
    font-size: 17px;
    line-height: 21px;
    letter-spacing: 0.4px;
    user-select: none;

    /* Checkbox background */
    &::before {
      /* Display it */
      content: '';
      margin-right: ${props => spacing2Value(props.gap || '16px')};
      display: inline-block;
      vertical-align: text-top;
      box-sizing: border-box;

      /* Size it */
      width: 22px;
      height: 22px;
      min-width: 22px;

      /* Colorize it */
      border: 2px solid ${props => props.theme.colors.checkbox.onWhite};
      border-radius: 1px;
      background-color: transparent;
    }

    /* Hovered border */
    &:hover {
      &::before {
        border: 2px solid ${props => props.theme.colors.primary};
      }
    }
  }

  /* If checkbox is Checked */
  &:checked + * {
    /* Background */
    &::before {
      background: ${props => props.theme.colors.primary};
      border-color: ${props => props.theme.colors.primary};
    }

    /* Checkmark */
    &::after {
      content: '';
      position: absolute;
    }
  }

  /* If checkbox is disabled */
  &:disabled + * {
    /* Label text */
    color: ${props => props.theme.colors.text.onWhiteDisabled};
    cursor: default;

    /* Checkbox background */
    &::before {
      box-shadow: none;
      background: transparent;
    }
  }
`;

export type InputTextProps = HelperProps & {
  success?: boolean;
  error?: boolean;
  fontSize?: string;
  textColor?: TextColorName;
  letterSpacing?: string;
  placeholderColor?: TextColorName;
  placeholderSize?: string;
  placeholderLineHeight?: string;
  borderColor?: InputColorName;
  borderColorOnFocus?: string | boolean;
  interactiveCursor?: boolean;
  extraPlaceholderControl?: React.ReactNode;
  placeholderGap?: number;
};

export default {
  Group: styled.div<HelperProps & { skipFirstMargin?: boolean }>`
    margin-bottom: ${props => props.theme.spacing.xmd};

    /* Every child but not children of child */
    > * {
      ${props =>
        props.skipFirstMargin
          ? `
        &:not(:first-child) {
          margin-top: ${props.theme.spacing.xsm};
        }
      `
          : `margin-top: ${props.theme.spacing.xsm};`};
    }
    ${props => (props.fullWidth ? 'width: 100%;' : '')}

    ${helpers};
  `,
  Text: styled.input.attrs({ type: 'text' })<InputTextProps>`
    transition: border-color 130ms ease-in-out 0s;
    /* Sizing and positioning */
    box-sizing: border-box;
    outline: none;
    width: 100%;
    padding: 13px;
    font-size: 17px;
    line-height: 26px;
    border-radius: 1px;
    cursor: ${props => props.interactiveCursor && 'pointer'};

    /* Coloring */
    border: 1px solid ${props => props.theme.colors.input.onWhite};
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.04);

    &:focus {
      ${props =>
        !props.error && !props.success && props.borderColorOnFocus !== false
          ? `border-color: ${props.borderColorOnFocus || props.theme.colors.text.onWhite}!important;`
          : ''};
    }

    &::placeholder {
      color: ${props =>
        (props.placeholderColor && props.theme.colors.text[props.placeholderColor]) ||
        props.theme.colors.text.lightGray}!important;
      font-size: ${props => props.placeholderSize || '17px'};
      line-height: 26px;
      opacity: 1; /* Firefox */
    }

    &:disabled {
      background-color: ${props => props.theme.colors.white};
      opacity: 0.5;
    }
    ${props => (props.height ? `height: ${props.height};` : '')}
    ${props => (props.success ? `border-color: ${props.theme.colors.green}; color: ${props.theme.colors.green};` : '')};
    ${props => (props.error ? `border-color: ${props.theme.colors.error}; color: ${props.theme.colors.error};` : '')};
    ${helpers};

    ${props => (props.fontSize ? `font-size: ${props.fontSize};` : '')}
    ${props => (props.letterSpacing ? ` letter-spacing: ${props.letterSpacing};` : '')}
    ${props => (props.textColor ? `color: ${props.theme.colors.text[props.textColor]};` : '')}
    ${props => (props.borderColor ? `border-color: ${props.theme.colors[props.borderColor]};` : '')}
  `,
  TextArea: styled.textarea<InputTextProps & { height?: number | string; resize?: string; noShadow?: boolean }>`
    font-family: 'Akkurat S-GE', 'Utopia S-GE', sans-serif;
    transition: border-color 130ms ease-in-out 0s;
    /* Sizing and positioning */
    box-sizing: border-box;
    outline: none;
    width: 100%;
    padding: 13px;
    font-size: 17px;
    line-height: 26px;
    border-radius: 1px;
    resize: ${props => props.resize || 'auto'};

    /* Coloring */
    border: 1px solid ${props => props.theme.colors.input.onWhite};
    box-shadow: ${props => (props.noShadow ? 'none' : '0 2px 6px 0 rgba(0, 0, 0, 0.04)')};

    &:focus {
      ${props => (!props.error && !props.success ? `border-color: ${props.theme.colors.text.onWhite};` : '')};
    }

    &::placeholder {
      color: ${props =>
        (props.placeholderColor && props.theme.colors.text[props.placeholderColor]) ||
        props.theme.colors.text.lightGray}!important;
      font-size: ${props => props.placeholderSize || '17px'}!important;
      //font-size: 17px;
      line-height: ${props => props.placeholderLineHeight || '26px'}!important;
      opacity: 1; /* Firefox */
    }

    &:disabled {
      background-color: ${props => props.theme.colors.white};
      opacity: 0.5;
    }
    ${props => (props.height ? `height: ${props.height};` : '')}
    ${props => (props.success ? `border-color: ${props.theme.colors.green}; color: ${props.theme.colors.green};` : '')};
    ${props => (props.error ? `border-color: ${props.theme.colors.error}; color: ${props.theme.colors.error};` : '')};
    ${helpers};
    ${props => (props.fontSize ? ` font-size: ${props.fontSize};` : '')}
    ${props => (props.letterSpacing ? ` letter-spacing: ${props.letterSpacing};` : '')}
    ${props => (props.textColor ? `color: ${props.theme.colors.text[props.textColor]};` : '')}
    ${props => (props.borderColor ? `border-color: ${props.theme.colors[props.borderColor]};` : '')}
  `,
  TextStatus: styled.div<{
    success?: boolean;
    error?: boolean;
    loading?: boolean;
    errorStatusBackgroundColor?: string;
  }>`
    position: relative;
    ${props => (props.success ? successStatus : '')};
    ${props => (props.error ? errorStatus : '')};
    ${props => (props.loading ? loadingStatus : '')};
  `,
  Checkbox: styled.input.attrs({ type: 'checkbox' })`
    ${checkStyling}

    /* If checkbox is Checked */
    &:checked + * {
      /* Background */
      &::before {
        background: ${props => props.theme.colors.primary};
        border-color: ${props => props.theme.colors.primary};
      }

      /* Checkmark */
      &::after {
        content: '';
        position: absolute;
        display: block;
        background-image: url('/static/icons/checkWhite.svg');
        background-repeat: no-repeat;
        background-position: center;
        padding: 3px;
        background-size: 16px 16px;
        height: 16px;
        width: 16px;
      }
    }
  `,
  Radio: styled.input.attrs({ type: 'radio' })`
    ${checkStyling};

    /* & + * = Label right next to the Radio */
    & + * {
      /* Radio background */
      &::before {
        border-radius: 50px;
      }
    }

    /* If Radio is Checked */
    &:checked + * {
      /* Radio */
      &::after {
        display: block;
        background-color: white;
        border-radius: 50px;
        background-position: center;
        height: 8px;
        width: 8px;
        margin-left: 7px;
      }
    }
  `,
  RadioGraphic: styled.input.attrs({ type: 'radio' })<RadioGraphicProps>`
    ${checkStyling};

    &::before {
      margin-right: 0px;
    }

    & + * {
      &::before {
        border: none;
        ${props => ImageBackground(props.graphic, props.graphicSize)}

        ${props => (props.centeredButton ? `left: 0;  right: 0; margin-left: auto;  margin-right: auto;` : '')}
      }

      &:hover {
        &::before {
          ${props => ImageBackground(props.hoveredGraphic || props.graphic, props.graphicSize)}
          //RadioGraphic is based on svg files instead of css styling so border style from checkStyling is not needed
          border: none;
        }
      }
    }

    &:checked + * {
      color: ${props => props.theme.colors.text.onWhitePrimary};

      &::before {
        ${props => ImageBackground(props.checkedGraphic || props.graphic, props.graphicSize)}
      }
    }

    &:checked + * {
      &::after {
        content: none;
      }
    }

    &:disabled + * {
      color: ${props => props.theme.colors.text.onWhite};
      cursor: default;

      &::before {
        ${props => ImageBackground(props.disabledGraphic || props.graphic, props.graphicSize)}
      }
    }
  `
};
