import styled from 'styled-components';
import helpers, { HelperProps } from '../helpers';

type SVGIconProps = HelperProps & {
  color?: string;
  opacity?: string;
  size?: string;
  minWidth?: string;
  minHeight?: string;
};

const SVGIcon = styled.img<SVGIconProps>`
  ${props => (props.size ? `height: ${props.size};` : '')};
  ${props => (props.color ? `color: ${props.color};` : '')};
  ${props => (props.opacity ? `opacity: ${props.opacity};` : '')};
  ${props => (props.onClick ? `cursor: pointer;` : '')};
  ${props => (props.minWidth ? `min-width: ${props.minWidth};` : '')};
  ${props => (props.minHeight ? `min-height: ${props.minHeight};` : '')};
  ${helpers};
`;

export default SVGIcon;
