import React from 'react';
import { numberNotation } from '../../utils/numberUtils';
import { useIntl } from 'react-intl';
import { getIdFromNotation, useLocale } from '../../locales';
import CountriesListStyles from './styles';
import { Country } from '../../redux/modules/dashboard/interfaces';
import BlankState from '../BlankState';
import { Flex, Loader, LocalizedText, Spacing, Text } from '@components';

type CountriesListProps = {
  title?: React.ReactElement | string;
  countryList?: Pick<Country, 'ISOAlpha2Code' | 'ISOAlpha3Code' | 'total' | 'name' | 'id'>[];
  loading?: boolean;
};

const CountryItem: React.FC<
  Partial<Pick<Country, 'ISOAlpha2Code' | 'total' | 'name' | 'ISOAlpha3Code'>> & {
    index: number;
    length: number;
    loading?: boolean;
  }
> = props => {
  const locale = useLocale();
  const intl = useIntl();
  const [val, notation, full] = numberNotation(Number(props.total), locale);

  let isoAlpha3Code: string;
  props.ISOAlpha3Code ? (isoAlpha3Code = props.ISOAlpha3Code.toLowerCase()) : (isoAlpha3Code = '');

  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      paddingTop={props.index === 0 ? undefined : 'default'}
      paddingBottom={props.index + 1 === props.length ? undefined : 'default'}
    >
      {props.loading ? (
        <Loader width="100%" padding="sm" considerSpacing />
      ) : (
        <>
          <Flex alignItems="center" gap>
            <CountriesListStyles.Flag>
              <img src={`/static/icons/flags/${props.ISOAlpha2Code}.svg`} />
            </CountriesListStyles.Flag>
            <Text.H6Responsive>{intl.formatMessage({ id: `countries.${isoAlpha3Code}` })}</Text.H6Responsive>
          </Flex>
          <Flex alignItems="flex-end">
            <Text.H6Responsive align="right">
              {notation === 'K' ? `CHF ${full} ` : `CHF ${val} `}
              {notation !== 'D' && notation !== 'K' && (
                <LocalizedText
                  id={getIdFromNotation(notation)}
                  values={{ number: val }}
                  description="Number notation"
                />
              )}
            </Text.H6Responsive>
          </Flex>
        </>
      )}
    </Flex>
  );
};

const CountriesList: React.FC<CountriesListProps> = props => (
  <Flex full vertical justifyContent="space-between">
    {props.title && <Spacing marginBottom="md">{props.title}</Spacing>}
    {props.loading ? (
      <CountriesListStyles.List>
        {Array(5)
          .fill(5)
          .map((_, index) => (
            <CountryItem key={index} index={index} length={3} loading />
          ))}
      </CountriesListStyles.List>
    ) : props.countryList && props.countryList.length > 0 ? (
      <CountriesListStyles.List>
        {props.countryList.map((country, i) => (
          <CountryItem
            key={country.id}
            {...country}
            index={i}
            length={props.countryList ? props.countryList.length : 0}
          />
        ))}
      </CountriesListStyles.List>
    ) : (
      <>
        <BlankState icon={<img src="/static/icons/listLight.svg" />}>
          <LocalizedText id="blankState.dashboard.countries" description="Countries blank slate" />
        </BlankState>
        <div></div>
      </>
    )}
  </Flex>
);

export default CountriesList;
