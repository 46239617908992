import moment from 'moment-timezone';
import React, { useState } from 'react';
import { PublishOnBackendQueueAction } from '../../redux/modules/queueing/types';
import { User } from '../../redux/modules/user/interfaces';
import TileTitleUrl from '../TileTitleUrl';
import getConfig from 'next/config';
import { SVGIcon, Spinner, LocalizedText, Breakpoints, TextIcon } from '@components';

type DownloadTileProps = {
  file?: {
    title: string;
    url: string;
    filename?: string;
  };
  loading?: boolean;
  user?: User & { currency?: string };
  doPublishOnBackendQueue?: (
    event: PublishOnBackendQueueAction['event'],
    variables: { userId?: number; fileName?: string; startDate?: string }
  ) => void;
  doPublishOnBackendQueueEvent?: PublishOnBackendQueueAction['event'];
};

const DownloadTile: React.FC<DownloadTileProps> = props => {
  const [loading, setLoading] = useState(false);
  const { doPublishOnBackendQueueEvent = 'clicked_download_on_country_guide' } = props;
  const { publicRuntimeConfig } = getConfig();

  const downloadFile = async (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    file?: DownloadTileProps['file']
  ) => {
    if (!file || loading) return null;
    event.preventDefault();
    if (props.user && props.doPublishOnBackendQueue && doPublishOnBackendQueueEvent) {
      props.doPublishOnBackendQueue(doPublishOnBackendQueueEvent, {
        userId: props.user.id,
        fileName: props.file && props.file.filename,
        startDate: moment.tz('Europe/Zurich').format('MM/DD/YYYY HH:mm:ss')
      });
    }
    try {
      setLoading(true);
      const config = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      };

      // Fetching file using our own cors proxy: https://dev.azure.com/S-GEDevops/DigitaleProdukte/_workitems/edit/14701
      const response = await fetch(`${publicRuntimeConfig.API_URI}/cors-proxy/${file.url}`, config);

      // Allowing user to download file
      const link = document.createElement('a');

      if (response.blob && typeof response.blob === 'function') {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(new Blob([blob]));
        link.href = url;
        link.setAttribute('download', file.filename || file.url.split('/').pop() || 'file.pdf');
      } else {
        // Not possible on current browser, just open the file
        link.href = file.url;
        link.target = '_blank';
      }
      // 3. Append to html page
      document.body.appendChild(link);
      // 4. Force download
      link.click();
      // 5. Clean up and remove the link
      if (link.parentNode) {
        link.parentNode.removeChild(link);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <TileTitleUrl
      loading={props.loading}
      title={props.file ? props.file.title : ''}
      icon={() => <SVGIcon height="48px" src="/static/icons/PDF.svg" />}
      url={() => (
        <a
          data-analytics-id={props['data-analytics-id']}
          href={props.file ? props.file.url : undefined}
          onClick={event => downloadFile(event, props.file)}
        >
          <Breakpoints.LaptopAndUp>
            {loading ? (
              <Spinner.Default color="onWhitePrimary" />
            ) : (
              <TextIcon
                shouldUnderlineOnHover
                variant="H6"
                rightIconComponent={() => <img src="/static/icons/downloadPrimary.svg" />}
                color="onWhitePrimary"
              >
                <LocalizedText id="download" description="Download url" />
              </TextIcon>
            )}
          </Breakpoints.LaptopAndUp>
          <Breakpoints.TabletAndDown>
            <SVGIcon padding="md" paddingRight="none" src="/static/icons/downloadPrimary.svg" />
          </Breakpoints.TabletAndDown>
        </a>
      )}
    />
  );
};

export default DownloadTile;
