import styled, { css } from 'styled-components';
import helpers, { HelperProps } from '../helpers';
import HexToRGB from '../HexToRGB';
import { AppTheme } from '../Theme';

export type ButtonVariant =
  | 'dark'
  | 'colored'
  | 'grey'
  | 'white'
  | 'allWhite'
  | 'whiteOnPrimary'
  | 'lightGreySecondaryOutline'
  | 'tertiary';

export type ButtonProps = HelperProps & {
  variant?: ButtonVariant; // White == default
  bgColor?: keyof AppTheme['colors']['button'];
  color?: keyof AppTheme['colors']['text'];
  fullWidth?: boolean;
  fontSize?: string;
  height?: string;
  width?: string;
  noHoverEffect?: boolean;
  selected?: boolean;
  disableMouseEvents?: boolean;
  selectedBackgroundColor?: keyof AppTheme['colors']['button'];
  selectedTextColor?: keyof AppTheme['colors']['text'];
};

const defaultButton = (bgColor: ButtonProps['bgColor'], props: ButtonProps, color: ButtonProps['color']) => css`
  background-color: ${props => (bgColor ? props.theme.colors.button[bgColor] : props.theme.colors.button.onWhite)};
  color: ${props => (color ? props.theme.colors.text[color] : props.theme.colors.text.onBlack)};
  border: none;
  transition: filter 130ms ease-in-out 0s;

  @media (hover: hover) {
    &:hover:not([disabled]) {
      text-decoration: underline;
      ${props.noHoverEffect ? '' : 'filter: brightness(0.85);'}
    }
  }

  &:active {
    text-decoration: underline;
    filter: brightness(0.75);
    transition: none;
  }
`;

const outlineButton = (color: string) => css`
  background-color: transparent;
  color: ${color};
  border: 2px solid ${color};
  transition: filter 130ms ease-in-out 0s;

  @media (hover: hover) {
    &:hover {
      background-color: ${`rgba(${HexToRGB(color)}, 0.1)`};
    }
  }

  &:active {
    background-color: ${`rgba(${HexToRGB(color)}, 0.15)`};
    transition: none;
  }
`;

const darkButton = css`
  ${props => outlineButton(props.theme.colors.text.onWhite)}
`;

const lightGreySecondaryOutline = css`
  ${props => outlineButton(props.theme.colors.text.lightGraySecondary)}
`;

const greyButton = css`
  ${props => outlineButton(props.theme.colors.button.onWhiteGrey)};
  transition: color 130ms ease-in-out 0s, background-color 130ms ease-in-out 0s, border-color 130ms ease-in-out 0s,
    filter 130ms ease-in-out 0s;
  color: ${props => props.theme.colors.text.onWhite};

  @media (hover: hover) {
    &:hover {
      background-color: ${props => props.theme.colors.button.onWhiteGrey};
      color: ${props => props.theme.colors.text.onPrimary};
      text-decoration: none;
    }
  }

  &:active {
    background-color: ${props => props.theme.colors.button.onWhiteGrey};
    color: ${props => props.theme.colors.text.onPrimary};
    text-decoration: none;
    filter: brightness(0.75);
    transition: none;
  }
`;

const allWhiteButton = css`
  ${props => outlineButton(props.theme.colors.button.white)};
  background-color: ${props => props.theme.colors.button.white};
  transition: color 130ms ease-in-out 0s, background-color 130ms ease-in-out 0s, border-color 130ms ease-in-out 0s,
    filter 130ms ease-in-out 0s;
  color: ${props => props.theme.colors.text.onWhite};

  @media (hover: hover) {
    &:hover {
      background-color: ${props => props.theme.colors.button.grey};
      border-color: ${props => props.theme.colors.button.grey};
      color: ${props => props.theme.colors.text.onPrimary};
      text-decoration: underline;
    }
  }

  &:active {
    background-color: ${props => props.theme.colors.button.onWhite};
    border-color: ${props => props.theme.colors.button.onWhite};
    color: ${props => props.theme.colors.text.onPrimary};
    text-decoration: underline;
    filter: brightness(0.75);
    transition: none;
  }
`;

const whiteOnPrimary = css`
  ${props => outlineButton(props.theme.colors.button.white)};
  background-color: ${props => props.theme.colors.button.white};
  transition: color 130ms ease-in-out 0s, background-color 130ms ease-in-out 0s, border-color 130ms ease-in-out 0s,
    filter 130ms ease-in-out 0s;
  color: ${props => props.theme.colors.primary};

  @media (hover: hover) {
    &:hover {
      background-color: ${props => props.theme.colors.button.white};
      border-color: ${props => props.theme.colors.button.white};
      color: ${props => props.theme.colors.primary};
      text-decoration: underline;
    }
  }

  &:active {
    background-color: ${props => props.theme.colors.button.white};
    border-color: ${props => props.theme.colors.button.white};
    color: ${props => props.theme.colors.primary};
    text-decoration: underline;
    filter: brightness(0.75);
    transition: none;
  }
`;

const tertiary = css`
  padding: 10px 24px;
  border: 2px solid ${props => props.theme.colors.lightGraySecondary};
  background: transparent;
  cursor: pointer;
  outline: none;

  @media (hover: hover) {
    &:hover {
      background-color: ${props => `rgba(${HexToRGB(props.theme.colors.lightGraySecondary)}, 0.2)`};
    }
  }
`;

const coloredButton = (color: ButtonProps['color']) => css`
  ${props => outlineButton(color ? props.theme.colors.text[color] : props.theme.colors.text.onWhitePrimary)}
`;

const getButtonVariant = (
  variant: ButtonProps['variant'],
  bgColor: ButtonProps['bgColor'],
  props: ButtonProps,
  color: ButtonProps['color']
) => {
  switch (variant) {
    case 'dark':
      return darkButton;
    case 'colored':
      return coloredButton(color);
    case 'grey':
      return greyButton;
    case 'allWhite':
      return allWhiteButton;
    case 'whiteOnPrimary':
      return whiteOnPrimary;
    case 'lightGreySecondaryOutline':
      return lightGreySecondaryOutline;
    case 'tertiary':
      return tertiary;
    default:
      return defaultButton(bgColor, props, color);
  }
};

export const buttonStyles = css<ButtonProps>`
  box-sizing: border-box;
  cursor: pointer;

  letter-spacing: 0.5px;
  font-weight: bold;
  outline: 0;
  padding: 0 ${props => props.theme.spacing.default};
  ${props => (props.fullWidth ? 'width: 100%' : '')};
  ${props => (props.fontSize ? `font-size: ${props.fontSize};` : 'font-size: 17px;')}

  &:disabled {
    cursor: default;
    opacity: 0.3;
  }

  ${props => getButtonVariant(props.variant, props.bgColor, props, props.color)};

  ${props =>
    props.selected && props.selectedBackgroundColor
      ? `background-color: ${props.theme.colors.button[props.selectedBackgroundColor]};`
      : ''}

  ${props =>
    props.selected && props.selectedTextColor ? `color: ${props.theme.colors.text[props.selectedTextColor]};` : ''}

  ${props => (props.disableMouseEvents ? ` pointer-events: none;` : '')}

  ${helpers};
  ${props => (props.height ? `height: ${props.height};` : '')};
  ${props => (props.width ? `width: ${props.width};` : '')};
`;

export default {
  Small: styled.button`
    height: 32px;
    ${buttonStyles};
  `,
  Medium: styled.button`
    height: 40px;
    ${buttonStyles};
  `,
  Large: styled.button`
    height: 48px;
    ${buttonStyles};
  `,
  xLarge: styled.button`
    height: 56px;
    ${buttonStyles};
  `,
  xxLarge: styled.button`
    height: 68px;
    ${buttonStyles};
  `,
  Auto: styled.button`
    ${buttonStyles};
  `
};
