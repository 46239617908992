import React from 'react';
import { useIntl } from 'react-intl';
import LocalizedText from 'src/components/LocalizedText';
import Styles from './styles';
import { separateLastWord } from 'src/utils/stringUtils';
import TranslationEditButton from 'src/components/TranslationEditButton';
import { emailAddress2AProps } from '@utils/miscUtils';
import { Flex, SVGIcon, Text } from '@components';

//Used in Posting of Employees,Country analyses top menu and left side menu
const MissingCountry = (props: {
  emailTranslationKey: string;
  missingCountryTranslationKey: string;
  letUsKnowTranslationKey: string;
}) => {
  const intl = useIntl();

  const linkText = intl.formatMessage({
    id: props.letUsKnowTranslationKey
  });
  const [firstPart, last] = separateLastWord(linkText);

  return (
    <Styles.Container vertical>
      <Text.Tiny size="16px" weight="bold" color="onWhite">
        <LocalizedText id={props.missingCountryTranslationKey} />
      </Text.Tiny>
      <Flex vertical={false} marginTop="xsm">
        <Text.Link
          {...emailAddress2AProps(
            `mailto:${intl.formatMessage({
              id: props.emailTranslationKey
            })}`
          )}
          data-analytics-id="98-contact-missing-country"
          size="13px"
          color="onWhitePrimary"
        >
          {/* Unfortunately an ugly but reliable workaround to keep the icon in the same line as the last word.
           Other approaches were now working for all browsers. Other approaches that were tried out: https://snook.ca/archives/html_and_css/icon-wrap-after-text */}
          {firstPart + ' '}
          <span>
            {last}
            <SVGIcon size="14px" src="/static/icons/semiArrowRightTinyPrimary.svg" />
          </span>
        </Text.Link>
        <TranslationEditButton translationKey={props.letUsKnowTranslationKey} />
      </Flex>
    </Styles.Container>
  );
};

export default MissingCountry;
