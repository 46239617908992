import styled from 'styled-components';
import { TabletAndUp } from '../stylingUtils';

export default {
  Container: styled.div``,
  Flag: styled.div`
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);

    display: inherit;

    img {
      height: auto;
      width: 32px;
    }

    ${props => TabletAndUp(props)} {
      img {
        height: auto;
        width: 42px;
      }
    }
  `,
  List: styled.div`
    /* For every element inside this component, but only the first nested */
    > * {
      /* If not the last one */
      &:not(:last-child) {
        border-bottom: 1px solid ${props => props.theme.colors.disabled};
      }
    }
  `
};
