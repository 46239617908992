import React from 'react';
import ChipListStyles from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Button, Flex } from '@components';

type Chip = { name: string; id: number | string } | { [key: string]: any };

type ChipListProps = {
  chips?: Chip[];
  onChipClick?: (chip: Chip, e: React.MouseEvent) => void;
  field?: string;
  disabled?: boolean;
  isFromSelect?: boolean;
};

const ChipList: React.FC<ChipListProps> = props => {
  const field = props.field || 'name';
  return (
    <ChipListStyles.Container isFromSelect={props.isFromSelect}>
      <Flex gap="sm">
        {props.chips && props.chips.length > 0
          ? props.chips.filter(Boolean).map(chip =>
              chip.name.length < 17 ? (
                <Button.Medium key={chip[field]} disabled={props.disabled} type="button">
                  <Flex alignItems="center" justifyContent="space-between">
                    <span>{chip[field]}</span>
                    {props.onChipClick && (
                      <span
                        onClick={e => {
                          if (props.onChipClick && !props.disabled) props.onChipClick(chip, e);
                        }}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </span>
                    )}
                  </Flex>
                </Button.Medium>
              ) : (
                <Button.Large key={chip[field]} disabled={props.disabled} type="button">
                  <Flex alignItems="center" justifyContent="space-between">
                    <span>{chip[field]}</span>
                    {props.onChipClick && (
                      <span
                        onClick={e => {
                          if (props.onChipClick && !props.disabled) props.onChipClick(chip, e);
                        }}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </span>
                    )}
                  </Flex>
                </Button.Large>
              )
            )
          : null}
      </Flex>
    </ChipListStyles.Container>
  );
};

export default React.memo(ChipList);
