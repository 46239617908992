import {
  LocalizedText,
  Text,
  TableAction,
  TableActionsCell,
  TablePaginationProps,
  TablePagination,
  TableBatchActions,
  ControlButton,
  Flex
} from '@components';
import React from 'react';
import Styles from './styles';
import _get from 'lodash/get';
import { useTableSelection } from './hooks';

export type TableRowType = Record<string, any>;

export type TableHeaderType = {
  key: string;
  label?: React.ReactNode;
  labelKey?: string;
  align?: 'left' | 'center' | 'right';
  width?: string;
  render?(value: any, row?: TableRowType): any;
};

export type TableProps = Partial<TablePaginationProps> & {
  headers: TableHeaderType[];
  rows: TableRowType[];
  actions?: ((row: TableRowType) => TableAction[]) | TableAction[];
  batchActions?: (Omit<TableAction, 'onClick'> & {
    onClick?(rows: number[]): Promise<void> | void;
  })[];

  dataTestId?: string;
};

const Table: React.FC<TableProps> = ({
  headers,
  rows,
  page,
  itemsPerPage = 10,
  total = 0,
  actions = [],
  batchActions = [],
  onPageChange,
  dataTestId
}) => {
  const {
    selectedRowIds,
    selectedRowMap,
    toggleRowSelection,
    hasSelectedRows,
    hasAllSelectedRows,
    toggleAllSelection
  } = useTableSelection(rows);

  const hasActions = typeof actions === 'function' || !!actions.length;
  const hasBatchActions = typeof batchActions === 'function' || !!batchActions.length;

  const actionsTestId = dataTestId ? `${dataTestId}-actions` : undefined;
  const rowTestId = dataTestId ? `${dataTestId}-row` : undefined;
  return (
    <>
      <Styles.TableContainer data-test-id={dataTestId}>
        <Styles.Table>
          <thead>
            <tr>
              {hasBatchActions && (
                <Styles.TableHeader width="70px">
                  <Flex gap="xxsm" alignItems="center">
                    <ControlButton
                      leftIcon={
                        hasAllSelectedRows
                          ? 'checkBoxPrimaryChecked'
                          : hasSelectedRows
                          ? 'checkBoxPrimaryIndeterminate'
                          : 'checkBoxPrimaryUnchecked'
                      }
                      iconSize="22px"
                      leftSpacing="0px"
                      onClick={toggleAllSelection}
                    />
                    {hasSelectedRows && (
                      <TableBatchActions
                        actions={batchActions.map(action => ({
                          ...action,
                          onClick: () => action.onClick?.(selectedRowIds)
                        }))}
                      />
                    )}
                  </Flex>
                </Styles.TableHeader>
              )}
              {headers.map(header => (
                <Styles.TableHeader key={header.key} align={header.align} width={header.width}>
                  <Text.Span16_26>
                    {header.labelKey ? <LocalizedText id={header.labelKey} /> : header.label}
                  </Text.Span16_26>
                </Styles.TableHeader>
              ))}
              {hasActions && (
                <Styles.TableHeader width="90px">
                  <Text.Span16_26>
                    <LocalizedText id="tableHeader.actions" />
                  </Text.Span16_26>
                </Styles.TableHeader>
              )}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => {
              const isSelected = selectedRowMap[row.id];
              return (
                <Styles.TableRow key={row._id || row.id || index} data-test-id={rowTestId} isSelected={isSelected}>
                  {hasBatchActions && (
                    <Styles.TableCell>
                      <ControlButton
                        leftIcon={isSelected ? 'checkBoxPrimaryChecked' : 'checkBoxPrimaryUnchecked'}
                        leftSpacing="0px"
                        onClick={() => toggleRowSelection(row.id)}
                        iconSize="22px"
                      />
                    </Styles.TableCell>
                  )}
                  {headers.map(header => (
                    <Styles.TableCell key={header.key}>
                      {header.render ? (
                        <Text.Span16_26>{header.render(_get(row, header.key), row)}</Text.Span16_26>
                      ) : (
                        <Text.Span16_26>{_get(row, header.key)}</Text.Span16_26>
                      )}
                    </Styles.TableCell>
                  ))}
                  {hasActions && (
                    <Styles.TableCell>
                      <TableActionsCell
                        actions={actions}
                        rowId={row._id || row.id}
                        row={row}
                        dataTestId={actionsTestId}
                      />
                    </Styles.TableCell>
                  )}
                </Styles.TableRow>
              );
            })}
          </tbody>
        </Styles.Table>
      </Styles.TableContainer>
      {!!page && page > 0 && !!onPageChange && (
        <TablePagination page={page} itemsPerPage={itemsPerPage} total={total} onPageChange={onPageChange} />
      )}
    </>
  );
};

export default React.memo(Table);
export * from './components';
