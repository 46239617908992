import { Flex, Tile } from '@components';
import styled from 'styled-components';

export default {
  Container: styled(Flex)`
    position: relative;
  `,
  DropdownContent: styled(Tile).attrs({ variant: 'raised', padding: 'md' })<{ isActive?: boolean }>`
    opacity: ${props => (props.isActive ? 1 : 0)};
    position: absolute;
    pointer-events: ${props => (props.isActive ? 'all' : 'none')};
    width: max-content;
    top: 100%;
    z-index: 999;
    right: -${props => props.theme.spacing.xsm};
    transition: opacity 250ms cubic-bezier(0.33, 0.7, 0.32, 1) 0ms, transform 250ms cubic-bezier(0.33, 0.7, 0.32, 1) 0ms,
      -webkit-transform 250ms cubic-bezier(0.33, 0.7, 0.32, 1) 0ms;
  `
};
