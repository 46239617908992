import { ControlButton, LocalizedText, TableAction, Text } from '@components';
import React from 'react';
import Styles from './styles';
import { useDetectClickOutside } from '@hooks/useDetectClickOutside';
import useOpenableState from '@hooks/useOpenableState';

type TableBatchActionsProps = {
  actions: (Omit<TableAction, 'onClick'> & {
    onClick?(): void;
  })[];
};
export const TableBatchActions: React.FC<TableBatchActionsProps> = ({ actions }) => {
  const [isOpen, { set, toggle }] = useOpenableState(false);
  const outisideClickRef = useDetectClickOutside({
    onTriggered: () => {
      if (isOpen) {
        set(false);
      }
    }
  });
  return (
    <Styles.RelativeDiv ref={outisideClickRef}>
      <ControlButton leftIcon="semiArrowDownTiny" iconSize="16px" leftSpacing="0px" onClick={toggle} />
      <Styles.ActionsContainer isOpen={isOpen}>
        {actions.map((action, i) => (
          <Styles.ActionItem key={i} alignItems="center" onClick={action.onClick}>
            <Text.Span16_26 letterSpacing="0.5px">
              {action.labelKey ? <LocalizedText id={action.labelKey} /> : action.label}
            </Text.Span16_26>
          </Styles.ActionItem>
        ))}
      </Styles.ActionsContainer>
    </Styles.RelativeDiv>
  );
};
