import React, { useEffect, useState } from 'react';
import Breakpoints from '../Breakpoints';
import { debounce } from '../../utils/helpers';
import HorizontalScroll from '../HorizontalScroll';
import TableOfContentsStyles from './styles';
import { SpacingName } from '../Theme';
import { Tooltip, Text } from '@components';

export type TableOfContentsItem = {
  icon?: (active: boolean) => React.ReactElement;
  title?: string;
  anchor: string;
  hide?: boolean;
  hideOnMobile?: boolean;
  text?: string;
  translationKey?: string;
};

export type TableOfContentsProps = {
  contents: TableOfContentsItem[];
  spacing?: SpacingName;
  offsetTop?: number;
  floatingPosition?: boolean;
  item2Component?: (
    item: TableOfContentsItem,
    onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>, hash: string) => void,
    isSelected: boolean
  ) => React.ReactNode;
};

const TableOfContents: React.FC<TableOfContentsProps> = props => {
  const [activeElementAnchor, setActiveElementAnchor] = useState('');

  useEffect(() => {
    const threshold = 200;

    const getElements = () => {
      return props.contents
        .map(item => ({ ...item, element: document.querySelector(item.anchor) }))
        .filter(x => x.element)
        .map(x => ({
          ...x,
          distanceToTop: (x.element as Element).getBoundingClientRect().top + window.pageYOffset,
          distanceToBottom: (x.element as Element).getBoundingClientRect().bottom + window.pageYOffset
        }));
    };
    let elements = getElements();

    const updateActiveElement = () => {
      // Find the closest element to the scroll bar
      const elementsFound = elements.filter(
        x => window.pageYOffset >= x.distanceToTop - threshold && window.pageYOffset <= x.distanceToBottom + threshold
      );

      // Set that as active
      if (elementsFound && elementsFound.length > 0) {
        const activeElement = elementsFound[elementsFound.length - 1];
        setActiveElementAnchor(activeElement.anchor);
      } else {
        setActiveElementAnchor('');
      }
    };

    let ticking = false;

    const onScroll = () => {
      // Throttle the scroll
      if (!ticking) {
        window.requestAnimationFrame(() => {
          updateActiveElement();
          ticking = false;
        });
      }
      ticking = true;
      onChange();
    };

    const onChange = () => {
      debounce(
        () => {
          elements = getElements();
        },
        'toc-update-elements',
        500
      );
    };

    updateActiveElement();

    window.addEventListener('scroll', onScroll, false);
    window.addEventListener('change', onChange, false);
    return () => {
      window.removeEventListener('scroll', onScroll);
      window.removeEventListener('change', onChange);
    };
  }, [props.contents]);

  const onClick = (e: React.MouseEvent<HTMLElement, MouseEvent>, hash: string) => {
    // For some reason, if your mouse is hovering the middle of the page
    // When the browser is natively scrolling. The scroll stops.

    // By doing via javascript, this issue is fixed.
    e.preventDefault();
    setTimeout(function () {
      const element = document.querySelector(hash);

      if (element) {
        window.location.hash = hash;
        // Subtract the navbar height to the position
        window.scrollTo(window.scrollX, (element as any).offsetTop - (props.offsetTop || 115));
      }
    }, 50);
  };

  return (
    <TableOfContentsStyles.Container
      data-analytics-id="table-of-contents-navbar"
      data-test-id="table-of-contents-navbar"
      floatingPosition={props.floatingPosition}
    >
      <Breakpoints.TabletAndDown>
        <HorizontalScroll hideArrowsMobile>
          {props.contents
            .filter(x => !x.hideOnMobile)
            .filter(x => !x.hide)
            .map(item => (
              <TableOfContentsStyles.Item key={item.anchor} active={item.anchor === activeElementAnchor}>
                <a href={item.anchor} onClick={e => onClick(e, item.anchor)}>
                  <Text.SmallerParagraph
                    weight="bold"
                    padding="sm"
                    color={item.anchor === activeElementAnchor ? 'onWhitePrimary' : undefined}
                  >
                    {item.title}
                  </Text.SmallerParagraph>
                </a>
              </TableOfContentsStyles.Item>
            ))}
        </HorizontalScroll>
      </Breakpoints.TabletAndDown>
      <Breakpoints.LaptopAndUp>
        {props.contents
          .filter(x => !x.hide)
          .map(item =>
            item.title ? (
              <TableOfContentsStyles.Item
                active={item.anchor === activeElementAnchor}
                key={item.anchor}
                spacing={props.spacing}
              >
                <div>
                  <Tooltip
                    mini
                    type="hover"
                    anchor="RIGHT_CENTER"
                    innerRender={() => (
                      <Text.SmallerParagraph weight="bold" whiteSpace="pre">
                        {item.title}
                      </Text.SmallerParagraph>
                    )}
                  >
                    {isOpen => (
                      <a href={item.anchor} onClick={e => onClick(e, item.anchor)}>
                        {props.item2Component
                          ? props.item2Component(item, onClick, item.anchor === activeElementAnchor)
                          : item.icon && item.icon(isOpen || item.anchor === activeElementAnchor)}
                      </a>
                    )}
                  </Tooltip>
                </div>
              </TableOfContentsStyles.Item>
            ) : (
              props.item2Component && props.item2Component(item, onClick, item.anchor === activeElementAnchor)
            )
          )}
      </Breakpoints.LaptopAndUp>
    </TableOfContentsStyles.Container>
  );
};

TableOfContents.defaultProps = {
  floatingPosition: true
};

export default TableOfContents;
