import React, { useEffect, useRef, useState } from 'react';

export const DynamicWrapper = (
  wrapper: (children: React.ReactElement) => React.ReactElement,
  children: React.ReactElement
) => wrapper(children);

export const waitForDOMElement = (
  selector: string,
  parent?: Element | Document,
  waitForAbsence = false
): Promise<void> => {
  return new Promise(resolve => {
    const interval = setInterval(() => {
      const divElement = (parent || document).querySelector(selector);
      if ((divElement && !waitForAbsence) || (!divElement && waitForAbsence)) {
        resolve();
        clearInterval(interval);
      }
    }, 10);
  });
};

export type FunctionComponentReferenceType = ((instance: unknown) => void) | React.MutableRefObject<unknown> | null;

export const waitForDOMElementAbsence = (selector: string, parent?: Element | Document): Promise<void> => {
  return waitForDOMElement(selector, parent, true);
};

export const As = <DestinationType>(obj?: object) => {
  return (obj as any) as DestinationType;
};

export const getRef = <FunctionComponentType>(ref: React.MutableRefObject<any>) => {
  return new Promise<FunctionComponentType>((resolve, reject) => {
    if (ref.current) {
      resolve(As<FunctionComponentType>(ref.current));
    } else {
      reject();
    }
  });
};

/*
  In the application sometimes occurs situation when the internal component state is not
  being used. An axample might be setInterval started at the begining of the component
  live cycle. The interval handler  will then use just the initial state.
  Related discussion: https://github.com/facebook/react/issues/14010
  To overcame this issue use useTrigger that might be used to make sure the actual latest context data/component state is being used.
  Example usage:

  const [searchTrigger, trigggerSearch] = useTrigger();

  useEffect(() => {
    setInterval(()=>{ trigggerSearch() }, 10000)
  }, []);

  useEffect(() => {
    if (searchTrigger != 'initial') {
      //do the search here
    }
  }, [searchTrigger]);
*/
let counter = 0;
export const useTrigger = () => {
  const [trigger, setTrigger] = useState('initial' as 'initial' | number);
  return [
    trigger,
    () => {
      counter++;
      //Overflow prevention
      if (counter > 10000000000) counter = 0;
      setTrigger(counter);
    }
  ] as const;
};

export const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
