import { MobileAndDown, TabletAndDown } from 'src/components/stylingUtils';
import styled from 'styled-components';

export default {
  EllipsisContainer: styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 470px;

    ${props => TabletAndDown(props)} {
      max-width: 400px;
    }

    ${props => MobileAndDown(props)} {
      max-width: 200px;
    }

    &:hover {
      > span {
        text-decoration: underline;
      }
    }
  `
};
