import React from 'react';

export const nl2br = (text: string) => {
  if (typeof text !== 'string') {
    return text;
  }
  if (!text || text.trim() === '') {
    return text;
  }

  const newlineRegex = /(\r\n|\n\r|\r|\n)/g;

  return text.split(newlineRegex).map(function (line, index) {
    if (line.match(newlineRegex)) {
      return React.createElement('br', { key: index });
    }
    return line;
  });
};

export const nl2brPlainHtml = (text: string) => {
  if (!text || typeof text !== 'string' || text.trim() === '') return text;
  return text.replace(/\n/g, '<br/>');
};

export const separateLastWord = (text: string) => {
  const n = text.lastIndexOf(' ');
  const last = text.substring(n + 1, text.length);
  const firstPart = text.substring(0, n);
  return [firstPart, last];
};

// As this function might return special HTML mark you should always
// use "parse" in the final text display to properly handle the special ellipsis character
export const limitLength = (text: string, maxSize: number) => {
  if (text.length > maxSize) {
    return text.slice(0, maxSize - 3) + '&#8230;';
  }
  return text;
};

//Default String.split returns [""] in case the string is ""
export const splitStringNoEmpty = (text?: string, separator = ';') => {
  const trimmedText = text && text.trim();
  if (trimmedText) {
    return trimmedText.split(separator);
  }
  return [];
};

//Make the text lowercase and replaces all not letters or numeric characters with "-"
export const text2NormalizedText = (text: string) => {
  if (!text || typeof text !== 'string' || text.trim() === '') return text;
  return text
    .trim()
    .replace(/[^0-9a-zA-Z]/g, '-')
    .toLocaleLowerCase();
};

export const containsOnlyEmail = (text?: string) => {
  if (text) {
    const re = /^\S+@\S+\.\S+$/;
    return re.test(text.trim());
  }
  return false;
};

export function findTheLongestWordSize(text?: string) {
  if (text) {
    const longestWord = text.split(' ').sort(function (a, b) {
      return b.length - a.length;
    });
    return longestWord[0].length;
  } else {
    return 0;
  }
}

export const capitalizeFirstCharacter = (text?: string) => {
  if (!text) {
    return text;
  }
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const repeatText = (text?: string, amount?: number) => {
  if (amount) {
    return Array.from({ length: amount || 0 }, () => text).join('');
  }
  return '';
};

export const replaceAll = function (text: string, pattern: any, newStr: string) {
  // If a regex pattern
  if (Object.prototype.toString.call(pattern).toLowerCase() === '[object regexp]') {
    return text.replace(pattern, newStr);
  }

  // If a string
  return text.replace(new RegExp(pattern, 'g'), newStr);
};

export const replaceAllMultiplePatterns = function (text: string, patterns: any[], newStr: string) {
  let result = text;
  for (const pattern of patterns) {
    result = replaceAll(result, pattern, newStr);
  }
  return result;
};

export const kebabCase2CamelCase = (text: string) =>
  text.replace(/[-]./g, match => {
    return match.replace('-', '').toLocaleUpperCase();
  });

// Example: this is simple text with [action a] and [action b] some further text
export const text2ActionTokens = (text: string): { content: string; type: 'text' | 'action' }[] => {
  const allTokens = text.match(/(^[^\[]*)|(\[[^\]]*?\])|([^\]]*$)|([^\]\[]*)/gm);
  return (
    allTokens?.map(token => {
      if (token.startsWith('[')) {
        return {
          content: token.replace('[', '').replace(']', ''),
          type: 'action'
        };
      } else {
        return {
          content: token,
          type: 'text'
        };
      }
    }) || []
  );
};
