import { helpers } from '@components';
import styled from 'styled-components';

export default {
  Container: styled.div`
    ${helpers}

    a {
      text-decoration: none;
    }
    a:hover {
      color: ${props => props.theme.colors.primary};
    }

    /* Tablet */
    @media (max-width: ${props => props.theme.breakpoints.small}) {
      margin-bottom: ${props => props.theme.spacing.md};
    }
  `,
  Social: styled.div`
    border-radius: 50%;
    background-color: ${props => props.theme.colors.disabled};
    width: 42px;
    height: 42px;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    :hover {
      background-color: ${props => props.theme.colors.primary};
      img,
      svg {
        filter: brightness(0) invert(1);
      }
    }

    img,
    svg {
      width: 24px;
    }
  `
};
