import React, { CSSProperties } from 'react';
import { Arrow, HoverProps, ToggleLayer, Transition, useHover } from 'react-laag';
import { AnchorEnum } from 'react-laag/dist/ToggleLayer/types';
import ResizeObserver from 'resize-observer-polyfill';
import { DynamicWrapper } from 'src/utils/reactUtils';
import { withTheme } from 'styled-components';
import { AppTheme, SpacingName } from '../Theme';
import TooltipStyles from './styles';

export type TooltipProps = {
  anchor?: AnchorEnum;
  type: 'hover' | 'click' | 'disabled';
  mini?: boolean;
  noPadding?: boolean;
  containerVerticalPadding?: SpacingName;
  containerHorizontalPadding?: SpacingName;
  padding?: SpacingName;
  innerRender: (isOpen: boolean, close: () => void) => React.ReactElement;
  theme: AppTheme;
  children?: (isOpen: boolean, toggle: () => void) => React.ReactElement;
  tooltipWidth?: number;
  triggerOffset?: number;
  leftOffset?: number;
  minWidth?: number;
  autoWidth?: boolean;
  //In cade this property is specified the "children" property will be not used.
  //The advancedTargetComponent provides more control over the styles and causes so no extra wrapper is used.
  advancedTargetComponent?: (
    isOpen: boolean,
    ref: React.RefObject<any>,
    onClick?: () => void,
    hoverProps?: HoverProps
  ) => React.ReactElement;
  possibleAnchors?: AnchorEnum[];
  disablePointerEventsOnTooltip?: boolean;
  noInline?: boolean;
  hideArrow?: boolean;
};

const Tooltip: React.FC<TooltipProps> = props => {
  const [isOpen, hoverProps] = useHover({ hideOnScroll: true });

  const contentStyle: CSSProperties = {
    zIndex: 2147483009,
    backgroundColor: props.theme.colors.white,
    padding: props.noPadding
      ? 0
      : props.mini
      ? props.theme.spacing.xsm
      : props.padding
      ? props.theme.spacing[props.padding]
      : props.theme.spacing.default,
    ...(props.containerVerticalPadding ? { paddingBottom: props.theme.spacing[props.containerVerticalPadding] } : {}),
    ...(props.containerVerticalPadding ? { paddingTop: props.theme.spacing[props.containerVerticalPadding] } : {}),
    ...(props.containerHorizontalPadding ? { paddingLeft: props.theme.spacing[props.containerHorizontalPadding] } : {}),
    ...(props.containerHorizontalPadding
      ? { paddingRight: props.theme.spacing[props.containerHorizontalPadding] }
      : {}),
    boxShadow: props.mini
      ? `-4px 0 7px 0 rgba(0,0,0,0.08), 5px 20px 50px 0 rgba(0,0,0,0.1)`
      : `5px 0px 50px 0 rgba(0, 0, 0, 0.2)`,
    borderRadius: 4,
    maxWidth: props.tooltipWidth,
    wordWrap: 'normal'
  };

  if (!props.mini && props.autoWidth !== true) {
    contentStyle.minWidth = props.minWidth || 200;
  }

  return (
    <>
      {DynamicWrapper(
        children =>
          props.advancedTargetComponent ? (
            children
          ) : (
            <TooltipStyles.Container noInline={props.noInline}>{children}</TooltipStyles.Container>
          ),
        <ToggleLayer
          ResizeObserver={ResizeObserver}
          fixed
          container={typeof window !== 'undefined' ? () => window?.document?.body : undefined}
          isOpen={props.type === 'hover' ? isOpen : undefined}
          renderLayer={({ isOpen, layerProps, arrowStyle, layerSide, close }) =>
            isOpen && (
              // This is the content that will be put INSIDE the tooltip
              <Transition isOpen={isOpen}>
                {(isOpen, onTransitionEnd) => (
                  <div
                    ref={layerProps.ref}
                    onTransitionEnd={onTransitionEnd}
                    style={{
                      ...layerProps.style,
                      ...contentStyle,
                      left: props.leftOffset
                        ? (layerProps.style.left as number) + props.leftOffset
                        : layerProps.style.left,
                      opacity: isOpen ? 1 : 0,
                      pointerEvents: props.disablePointerEventsOnTooltip === true ? 'none' : undefined
                    }}
                  >
                    {props.innerRender(isOpen, close)}
                    {!props.hideArrow && (
                      <Arrow
                        size={props.mini ? 5 : 10}
                        style={arrowStyle}
                        backgroundColor={contentStyle.backgroundColor}
                        layerSide={layerSide}
                      />
                    )}
                  </div>
                )}
              </Transition>
            )
          }
          placement={{
            anchor: props.anchor,
            autoAdjust: true,
            triggerOffset: props.triggerOffset || 15,
            possibleAnchors: props.possibleAnchors
          }}
          closeOnOutsideClick
        >
          {props.advancedTargetComponent == null
            ? ({ isOpen, triggerRef, toggle }) => (
                // This is the element the tooltip will be attached to
                <span
                  ref={triggerRef}
                  onClick={props.type === 'click' ? toggle : undefined}
                  {...(props.type === 'hover' ? hoverProps : {})}
                >
                  {props.children && props.children(isOpen, toggle)}
                </span>
              )
            : ({ isOpen, triggerRef, toggle }) =>
                //This is the element the tooltip will be attached to
                //Advanced version - no any wrapper introduced so we have a better control over the style in this case.
                props.advancedTargetComponent &&
                props.advancedTargetComponent(
                  isOpen,
                  triggerRef,
                  props.type === 'click' ? toggle : undefined,
                  props.type === 'hover' ? hoverProps : undefined
                )}
        </ToggleLayer>
      )}
    </>
  );
};

export default withTheme(Tooltip);
