import React from 'react';
import { ControlButton, LocalizedText } from '@components';
import Link from 'next/link';
import Styles from './styles';
import { ControlButtonProps } from '../ControlButton';

type StickyControlButtonProps = ControlButtonProps & {
  href: string;
  dataAnalyticsId?: string;
  translationKey?: string;
  linkVariant?: 'close';
  isFixed: boolean;
  backgroundColor?: string;
};

const StickyControlButton: React.FC<StickyControlButtonProps> = ({
  href,
  dataAnalyticsId,
  translationKey,
  linkVariant,
  isFixed,
  backgroundColor,
  ...rest
}) => {
  return (
    <Link href={linkVariant == 'close' ? 'javascript:window.close();' : href} data-analytics-id={dataAnalyticsId}>
      <Styles.StickyButtonWrapper isFixed={isFixed} backgroundColor={backgroundColor}>
        <ControlButton
          leftIcon={linkVariant == 'close' ? 'crossSmallPrimary' : 'semiArrowLeftPrimary'}
          variant="Span14_22_bold"
          textProps={{
            color: 'onWhitePrimary'
          }}
          underlineOnHover={!isFixed}
          leftSpacing={isFixed ? '0' : undefined}
          {...rest}
        >
          <LocalizedText id={translationKey || 'backToList'} />
        </ControlButton>
      </Styles.StickyButtonWrapper>
    </Link>
  );
};

export default StickyControlButton;
