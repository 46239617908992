import {
  AdminMode,
  EditTranslationAction,
  EDIT_TRANSLATION,
  SET_ADMIN_MODE,
  UPDATE_TRANSLATION,
  UPDATE_TRANSLATION_CANCELLED,
  UPDATE_TRANSLATION_FAILED,
  UPDATE_TRANSLATION_SUCESS,
  REFRESHING_APP_SUCESS,
  RefreshingAppSuccessAction,
  SetAdminModeAction,
  UpdateTranslationAction,
  UpdateTranslationCancelledAction,
  UpdateTranslationFailedAction,
  UpdateTranslationSuccessAction,
  RetrieveTranslationsAction,
  RETRIEVE_TRANSLATIONS,
  RetrieveTranslationsSuccessAction,
  RETRIEVE_TRANSLATIONS_SUCCESS,
  RetrieveTranslationsFailureAction,
  RETRIEVE_TRANSLATIONS_FAILURE
} from './types';

export const doSetAdminMode = (adminMode: AdminMode): SetAdminModeAction => ({
  adminMode,
  type: SET_ADMIN_MODE
});

export const doEditTranslation = (translationKey: string | null): EditTranslationAction => ({
  translationKey,
  type: EDIT_TRANSLATION
});

export const doUpdateTranslation = (currentTranslationKey: string, newValue: string): UpdateTranslationAction => ({
  newValue: newValue,
  currentTranslationKey: currentTranslationKey,
  type: UPDATE_TRANSLATION
});

export const doUpdateTranslationSuccess = (
  currentTranslationKey: string,
  newValue: string
): UpdateTranslationSuccessAction => {
  return {
    type: UPDATE_TRANSLATION_SUCESS,
    currentTranslationKey,
    newValue
  };
};

export const doUpdateTranslationFailed = (): UpdateTranslationFailedAction => ({
  type: UPDATE_TRANSLATION_FAILED
});

export const doRefreshingAppSuccess = (): RefreshingAppSuccessAction => {
  return {
    type: REFRESHING_APP_SUCESS
  };
};

export const doUpdateTranslationCancelled = (): UpdateTranslationCancelledAction => {
  return {
    type: UPDATE_TRANSLATION_CANCELLED
  };
};

export const doRetrieveTranslations = (
  language: RetrieveTranslationsAction['language']
): RetrieveTranslationsAction => {
  return {
    type: RETRIEVE_TRANSLATIONS,
    language
  };
};

export const doRetrieveTranslationsSuccess = (
  translations: RetrieveTranslationsSuccessAction['translations']
): RetrieveTranslationsSuccessAction => {
  return {
    type: RETRIEVE_TRANSLATIONS_SUCCESS,
    translations
  };
};

export const doRetrieveTranslationsFailed = (): RetrieveTranslationsFailureAction => {
  return {
    type: RETRIEVE_TRANSLATIONS_FAILURE
  };
};
