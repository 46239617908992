import styled from 'styled-components';
import helpers, { HelperProps } from '../helpers';

const Container = styled.div<{ tocGutter?: boolean; fullWidth?: boolean } & HelperProps>`
  ${props => (props.fullWidth ? '' : 'margin: 0 26px;')}

  /* Tablet */
  @media (min-width: ${props => props.theme.breakpoints.small}) {
    ${props => (props.fullWidth ? '' : 'margin: 0 56px;')}
  }

  /* Laptop */
  @media (min-width: ${props => props.theme.breakpoints.large}) {
    /* Adds a space if the page does have a toc */
    ${props => (props.fullWidth ? '' : `margin: 0 48px 0 ${props.tocGutter ? `calc(48px + 78px)` : `48px`};`)}
  }

  /* Desktop */
  @media (min-width: ${props => props.theme.breakpoints.xlarge}) {
    ${props => (props.fullWidth ? '' : 'max-width: 1200px;')}
    ${props => (props.fullWidth ? '' : 'margin: 0 auto;')}
  }

  ${helpers};
`;

export const ContainerPadding = styled.div<{ tocGutter?: boolean }>`
  padding: 0 26px;

  /* Tablet */
  @media (min-width: ${props => props.theme.breakpoints.small}) {
    padding: 0 56px;
  }

  /* Laptop */
  @media (min-width: ${props => props.theme.breakpoints.large}) {
    /* Adds a space if the page does have a toc */
    padding: 0 48px 0 ${props => (props.tocGutter ? `calc(48px + 78px)` : `48px`)};
  }

  /* Desktop */
  @media (min-width: ${props => props.theme.breakpoints.xlarge}) {
    padding: 0 calc((99vw - 1200px) / 2);
  }
`;

export default Container;
