import React, { useEffect } from 'react';
import HorizontalScrollStyles from './styles';
import { useHorizontalScroll } from './utils';

type HorizontalScrollProps = React.PropsWithChildren<{
  calculateHeight?: boolean;
  gapSize?: string;
  hideArrows?: boolean;
  hideArrowsMobile?: boolean;
  rightArrowIcon?: React.ReactElement;
  scrollContentTravelDistance?: number;
  updateScrollOnContentChange?: boolean;
}>;

const HorizontalScroll = React.forwardRef<null, HorizontalScrollProps>((props, ref) => {
  const { containerRef, contentRef, transitionEnd, onScroll, onClick, currentOverflow } = useHorizontalScroll({
    // How much will be scrolled when the use clicks the arrow
    scrollContentTravelDistance: props.scrollContentTravelDistance || 300,
    calculateHeight: props.calculateHeight
  });

  useEffect(() => {
    if (props.updateScrollOnContentChange) onScroll();
  }, [props.children]);

  return (
    <HorizontalScrollStyles.Wrapper ref={ref}>
      <HorizontalScrollStyles.Container
        ref={containerRef}
        onScroll={onScroll}
        data-overflowing=""
        displayGrab={currentOverflow !== 'none'}
      >
        <HorizontalScrollStyles.Content ref={contentRef} onTransitionEnd={transitionEnd} gapSize={props.gapSize}>
          {props.children}
        </HorizontalScrollStyles.Content>
      </HorizontalScrollStyles.Container>
      <HorizontalScrollStyles.SideButton
        left
        onClick={() => onClick('left')}
        hideMobile={props.hideArrowsMobile}
        hide={props.hideArrows}
      >
        {/* The right arrow is flipped via css */}
        {props.rightArrowIcon ? props.rightArrowIcon : <img src="/static/icons/semiArrowRightTinyGray.svg" />}
      </HorizontalScrollStyles.SideButton>
      <HorizontalScrollStyles.SideButton
        onClick={() => onClick('right')}
        hideMobile={props.hideArrowsMobile}
        hide={props.hideArrows}
      >
        {props.rightArrowIcon ? props.rightArrowIcon : <img src="/static/icons/semiArrowRightTinyGray.svg" />}
      </HorizontalScrollStyles.SideButton>
    </HorizontalScrollStyles.Wrapper>
  );
});

export default HorizontalScroll;
