import React from 'react';
import getConfig from 'next/config';
import FooterStyles from './styles';
import { useIntl } from 'react-intl';
import { Container, Flex, Text, LocalizedText, Breakpoints } from '@components';

const { publicRuntimeConfig } = getConfig();

const Footer: React.FC<{ tocGutter?: boolean }> = props => {
  const intl = useIntl();
  return (
    <FooterStyles.Container marginVertical="xxlg">
      <Container tocGutter={props.tocGutter}>
        <Flex vertical alignItems="stretch" justifyContent="space-between" gap="lg" full>
          <Flex mobile justifyContent="space-between" gap="lg">
            <div>
              <Text.Caption weight="bold">
                <LocalizedText id="officialProgram" description="Official program label" />
              </Text.Caption>
              <img src="/static/icons/switzerlandTradeLogo.svg" />
            </div>
            <Flex alignItems="center" gap="sm">
              <a
                href={publicRuntimeConfig.TWITTER_FOOTER_URL}
                target="_blank"
                rel="noopener noreferrer"
                data-analytics-id="11-12-13-click-on-footer-social"
              >
                <FooterStyles.Social>
                  <img src="/static/icons/twitter.svg" />
                </FooterStyles.Social>
              </a>
              <a
                href={publicRuntimeConfig.LINKEDIN_FOOTER_URL}
                target="_blank"
                rel="noopener noreferrer"
                data-analytics-id="11-12-13-click-on-footer-social"
              >
                <FooterStyles.Social>
                  <img src="/static/icons/linkedIn.svg" />
                </FooterStyles.Social>
              </a>
              <a
                href={publicRuntimeConfig.YOUTUBE_FOOTER_URL}
                target="_blank"
                rel="noopener noreferrer"
                data-analytics-id="11-12-13-click-on-footer-social"
              >
                <FooterStyles.Social>
                  <img src="/static/icons/youtube.svg" />
                </FooterStyles.Social>
              </a>
            </Flex>
          </Flex>
          <Flex mobile gap="md">
            <Breakpoints.Mobile>
              <Text.Tiny weight="bold" size="13px">
                © {new Date().getFullYear()} SWITZERLAND GLOBAL ENTERPRISE
              </Text.Tiny>
            </Breakpoints.Mobile>
            <Breakpoints.TabletAndUp>
              <Text.Tiny size="13px">© {new Date().getFullYear()} SWITZERLAND GLOBAL ENTERPRISE</Text.Tiny>
            </Breakpoints.TabletAndUp>
            <Text.Tiny size="13px" weight="bold">
              <a
                href={intl.formatMessage({ id: 'signup.termsAndConditionsLink' })}
                target="_blank"
                rel="noopener noreferrer"
                data-analytics-id="9-10-footer-terms-conditions"
              >
                <LocalizedText id="terms" description="Terms footer url" />
              </a>
            </Text.Tiny>
          </Flex>
        </Flex>
      </Container>
    </FooterStyles.Container>
  );
};

export default Footer;
