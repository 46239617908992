import styled, { css } from 'styled-components';
import { buttonStyles } from '../Button';

const linkStyles = css`
  ${buttonStyles};
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`;

//Default export contains a definition of styled buttons with different dimensions.
//To avoid the confusion I keep the NativeLinkButton component separated.
export const NativeLinkButton = styled.a`
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`;

export default {
  Small: styled.a`
    ${linkStyles};
    line-height: 32px;
    height: 32px;
  `,
  Medium: styled.a`
    ${linkStyles};
    line-height: 40px;
    height: 40px;
  `,
  Large: styled.a`
    ${linkStyles};
    line-height: 48px;
    height: 48px;
  `,
  xLarge: styled.a`
    ${linkStyles};
    line-height: 56px;
    height: 56px;
  `,
  xxLarge: styled.a`
    ${linkStyles};
    line-height: 68px;
    height: 68px;
  `
};
