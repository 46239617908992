import styled from 'styled-components';
import { LaptopAndUp } from '../stylingUtils';

export default {
  Container: styled.div<{ displayGrab?: boolean }>`
    /* Make this scrollable when needed */
    overflow-x: auto;
    /* We don't want vertical scrolling */
    overflow-y: hidden;
    /* For WebKit implementations, provide inertia scrolling */
    -webkit-overflow-scrolling: touch;
    /* We don't want internal inline elements to wrap */
    white-space: nowrap;

    /* Make an auto-hiding scroller for the people using IE */
    -ms-overflow-style: -ms-autohiding-scrollbar;

    &::-webkit-scrollbar {
      display: none;
    }

    ${props => (props.displayGrab ? 'cursor: grab;' : '')}
  `,
  Content: styled.div<{ gapSize?: string }>`
    float: left;

    > * {
      display: inline-block;
      font-size: 1.2rem;
      border: 1px solid transparent;
    }

    ${props => (props.gapSize ? `display: flex; gap: ${props.gapSize}` : '')}
  `,
  SideButton: styled.button.attrs({ type: 'button' })<{ left?: boolean; hideMobile?: boolean; hide?: boolean }>`
    /* Reset the button */
    appearance: none;
    background: transparent;
    padding: 0;
    border: 0;
    opacity: 0;
    transition: opacity 120ms ease-in-out;
    z-index: 99;

    /* Now style it as needed */
    position: absolute;
    top: 0;
    bottom: 0;

    &:focus {
      outline: 0;
    }

    img {
      height: 46px;
    }

    > * {
      transition: transform 100ms ease-in-out;
    }

    &:hover {
      > * {
        transform: scale(1.3) ${props => (props.left ? 'rotateZ(180deg)' : '')};
      }
    }

    &:active {
      > * {
        transform: scale(1.1) ${props => (props.left ? 'rotateZ(180deg)' : '')};
        transition: none;
      }
    }

    ${props =>
      props.left
        ? `
      left: calc(calc(46px + ${props.theme.spacing.xsm}) * -1);

      /* Rotate the arrow */
      > * { transform: rotateZ(180deg); }

      [data-overflowing='both'] ~ &,
      [data-overflowing='left'] ~ & {
        opacity: 1;
        cursor: pointer;
      }
    `
        : `
      right: calc(calc(46px + ${props.theme.spacing.xsm}) * -1);

      [data-overflowing="both"] ~ &,
      [data-overflowing="right"] ~ &{
          opacity: 1;
          cursor: pointer;
      }
    `};

    ${props =>
      props.hideMobile
        ? `
      display: none;

      ${LaptopAndUp(props)} {
        display: inherit;
      }
    `
        : ''};

    ${props =>
      props.hide
        ? `
      display: none;

      ${LaptopAndUp(props)} {
        display: none;
      }
    `
        : ''};
  `,
  Wrapper: styled.div`
    position: relative;
    font-size: 1.2rem;
  `
};
