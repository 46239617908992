import styled from 'styled-components';

export default {
  Container: styled.div`
    > * {
      > * {
        :first-child {
          margin-right: ${props => props.theme.spacing.sm};
        }
      }

      @media (min-width: ${props => props.theme.breakpoints.small}) and (max-width: ${props =>
          props.theme.breakpoints.large}) {
        flex-direction: column;
        align-items: flex-start;
        > * {
          :first-child {
            margin-right: initial;
            margin-bottom: ${props => props.theme.spacing.sm};
          }
        }
      }
    }
  `,
  Avatar: styled.img`
    width: 100%;
    object-fit: cover;
    transition: transform 100ms ease-out;
    height: 64px;
    width: 64px;
    border-radius: 50px;
  `
};
