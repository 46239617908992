import { Loader, Tile } from '@components';
import React from 'react';
import { SpacingName } from '../Theme';

import CardStyles from './styles';

export enum CardVariant {
  noImagePadding = 'noImagePadding'
}

export type CardProps = React.PropsWithChildren<{
  disableSpaceBetween?: boolean;
  full?: boolean;
  imageSrc?: string;
  imageWrapper?: (image: React.ReactElement) => React.ReactElement;
  loading?: boolean;
  onClick?: () => void;
  reversedColoristic?: boolean;
  spacing?: SpacingName;
  variant?: CardVariant;
  hidePadding?: boolean;
  noHoverEffect?: boolean;
  noContentColumnDirection?: boolean;
  disabled?: boolean;
}>;

const Card: React.FC<CardProps> = props => (
  <CardStyles.Container disabled={props.disabled} noHoverEffect>
    <Tile full={props.full} noPadding>
      {props.loading ? (
        <CardLoading {...props} />
      ) : (
        <>
          {props.imageSrc &&
            (props.imageWrapper ? (
              props.imageWrapper(
                <CardStyles.ImageContainer
                  data-analytics-id={props['data-analytics-id']}
                  onClick={props.onClick}
                  variant={props.variant}
                >
                  <img src={props.imageSrc} />
                </CardStyles.ImageContainer>
              )
            ) : (
              <CardStyles.ImageContainer
                data-analytics-id={props['data-analytics-id']}
                onClick={props.onClick}
                variant={props.variant}
              >
                <img src={props.imageSrc} />
              </CardStyles.ImageContainer>
            ))}
          <CardStyles.ContentContainer
            disableSpaceBetween={props.disableSpaceBetween}
            reversedColoristic={props.reversedColoristic}
            padding={props.spacing ? props.spacing : 'xmd'}
            variant={props.variant}
            hidePadding={props.hidePadding}
            noContentColumnDirection={props.noContentColumnDirection}
          >
            {props.children}
          </CardStyles.ContentContainer>
        </>
      )}
    </Tile>
  </CardStyles.Container>
);

const CardLoading: React.FC<CardProps> = props => (
  <>
    <Loader fullWidth paddingVertical="xxlg" />
    <CardStyles.ContentContainer padding={props.spacing ? props.spacing : 'xmd'}>
      {props.children}
    </CardStyles.ContentContainer>
  </>
);

export default Card;
