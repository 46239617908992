import styled, { keyframes } from 'styled-components';
import { AppTheme } from '../Theme';
import helpers, { HelperProps } from '../helpers';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

export type SpinnerProps = HelperProps & {
  color?: keyof AppTheme['colors']['text'];
  size?: string;
};

export default {
  Default: styled.div<SpinnerProps>`
    content: '';
    box-sizing: border-box;
    width: ${props => props.size || '1em'};
    height: ${props => props.size || '1em'};
    border-radius: 50%;
    border: 2px solid ${props => (props.color ? props.theme.colors.text[props.color] : props.theme.colors.text.onWhite)};
    border-top-color: transparent;
    border-right-color: transparent;
    animation: ${spin} 0.6s linear infinite;
    margin: auto;

    ${helpers}
  `
};
