import React from 'react';
import { Flex, LocalizedText, TableRowType, TooltipSelect } from '@components';

export type TableAction = {
  label?: React.ReactNode;
  labelKey?: string;
  onClick?(id: string, row?: TableRowType): Promise<void> | void;
  dataValue?: string;
};

export type TableActionsCellProps = {
  actions: ((row: TableRowType) => TableAction[]) | TableAction[];
  rowId: string;
  row: TableRowType;
  dataTestId?: string;
};

export const TableActionsCell: React.FC<TableActionsCellProps> = ({ actions, rowId, row, dataTestId }) => {
  const options = typeof actions === 'function' ? actions(row) : actions;
  if (!options.length) {
    return <></>;
  }
  return (
    <Flex justifyContent="flex-end">
      <TooltipSelect
        options={options.map(action => ({
          text: action.labelKey ? <LocalizedText id={action.labelKey} /> : action.label,
          value: action.dataValue,
          onClick: () => {
            action.onClick?.(rowId, row);
          },
          selected: false
        }))}
        rightIcon="more"
        rightActiveIcon="more"
        tooltipWidth={130}
        minWidth={130}
        paddingOnlyInItems
        itemVerticalPadding="xsm"
        variant="Span14_20"
        weight={400}
        tooltipAnchor="TOP_CENTER"
        containerVerticalPadding="xsm"
        highlightItemsOnHover
        highlightItemsTextOnHover={false}
        dataTestId={dataTestId}
      />
    </Flex>
  );
};
