const debounceIds: string[] = [];
export const debounce = (func: (...args: any[]) => void, debounceName: string, delayMs: number) => {
  clearTimeout(debounceIds[debounceName]);
  debounceIds[debounceName] = setTimeout(func, delayMs);
};

export const cancelDebounce = (debounceName: string) => {
  clearTimeout(debounceIds[debounceName]);
};

const lastCallsMs: { [key: string]: number } = {};
export const throttle = (func: () => void, name: string, minimumDelayMs: number) => {
  const lastCallMs = lastCallsMs[name];
  const nowMs = new Date().getTime();
  if (!lastCallMs || nowMs - lastCallMs > minimumDelayMs) {
    func();
    lastCallsMs[name] = nowMs;
  }
};
