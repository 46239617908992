import { RequestResult } from 'src/interfaces/requests';
import { Locale } from '../../../locales';

export const EDIT_TRANSLATION = 'app/EDIT_TRANSLATION';
export const UPDATE_TRANSLATION = 'app/UPDATE_TRANSLATION';
export const UPDATE_TRANSLATION_SUCESS = 'app/UPDATE_TRANSLATION_SUCESS';
export const UPDATE_TRANSLATION_CANCELLED = 'app/UPDATE_TRANSLATION_CANCELLED';
export const UPDATE_TRANSLATION_FAILED = 'app/UPDATE_TRANSLATION_FAILED';
export const REFRESHING_APP_SUCESS = 'app/REFRESHING_APP_SUCESS';
export const SET_ADMIN_MODE = 'app/SET_ADMIN_MODE';

export const RETRIEVE_TRANSLATIONS = 'app/RETRIEVE_TRANSLATIONS';
export const RETRIEVE_TRANSLATIONS_SUCCESS = 'app/RETRIEVE_TRANSLATIONS_SUCCESS';
export const RETRIEVE_TRANSLATIONS_FAILURE = 'app/RETRIEVE_TRANSLATIONS_FAILURE';

export type AdminMode = 'translations-editing' | null;

export interface SetAdminModeAction {
  type: typeof SET_ADMIN_MODE;
  adminMode: AdminMode;
}

export interface EditTranslationAction {
  type: typeof EDIT_TRANSLATION;
  translationKey: string | null;
}

export interface UpdateTranslationAction {
  type: typeof UPDATE_TRANSLATION;
  currentTranslationKey: string;
  newValue: string;
}

export interface UpdateTranslationSuccessAction {
  type: typeof UPDATE_TRANSLATION_SUCESS;
  currentTranslationKey: string;
  newValue: string;
}

export interface UpdateTranslationCancelledAction {
  type: typeof UPDATE_TRANSLATION_CANCELLED;
}

export interface UpdateTranslationFailedAction {
  type: typeof UPDATE_TRANSLATION_FAILED;
}

export interface RefreshingAppSuccessAction {
  type: typeof REFRESHING_APP_SUCESS;
}

export interface RetrieveTranslationsAction {
  type: typeof RETRIEVE_TRANSLATIONS;
  language: Locale;
}

export interface RetrieveTranslationsSuccessAction {
  type: typeof RETRIEVE_TRANSLATIONS_SUCCESS;
  translations: Record<string, string>;
}

export interface RetrieveTranslationsFailureAction {
  type: typeof RETRIEVE_TRANSLATIONS_FAILURE;
  error?: RequestResult;
}
