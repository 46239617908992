import SVGIcon from 'src/components/SVGIcon';
import styled from 'styled-components';

export default {
  EditButton: styled.span<{ absolute?: boolean }>`
    margin-left: 5px;
    /*  position: absolute; */
    ${props => (props.absolute ? 'position: absolute;' : '')}
    z-index: 10000;
    cursor: pointer;
    /*    margin-top: 0px;
    margin-left: -20px; */

    ${SVGIcon} {
      opacity: 0.6;
      &:hover {
        opacity: 1;
      }
    }
  `
};
