import { HelperProps, helpers } from '@components';
import styled from 'styled-components';
import { DesktopAndUp, LaptopAndUp, TabletAndUp } from '../stylingUtils';

export default {
  Mobile: styled.div<HelperProps>`
    ${props => TabletAndUp(props)} {
      display: none;
    }

    ${helpers};
  `,
  TabletOnly: styled.div<HelperProps>`
    display: none;
    ${props => TabletAndUp(props)} {
      display: inherit;
    }
    ${props => LaptopAndUp(props)} {
      display: none;
    }

    ${helpers};
  `,
  TabletAndDown: styled.div<HelperProps>`
    ${props => LaptopAndUp(props)} {
      display: none;
    }

    ${helpers};
  `,
  TabletAndUp: styled.div<HelperProps>`
    display: none;
    ${props => TabletAndUp(props)} {
      display: inherit;
    }

    ${helpers};
  `,
  LaptopAndUp: styled.div<HelperProps>`
    display: none;
    ${props => LaptopAndUp(props)} {
      display: inherit;
    }

    ${helpers};
  `,
  LaptopAndDown: styled.div<HelperProps>`
    ${props => DesktopAndUp(props)} {
      display: none;
    }

    ${helpers};
  `
};
