import { Tile } from '@components';
import styled from 'styled-components';
import { LaptopAndUp, MobileAndDown } from '../stylingUtils';

export default {
  Container: styled.div`
    position: relative;
    z-index: 2147483006;
    ${Tile} {
      min-height: 100%;
      overflow-x: hidden;

      > * {
        min-height: 100%;
      }
    }
  `,
  Close: styled.div`
    cursor: pointer;
    ${props => LaptopAndUp(props)} {
      transition: transform 100ms ease-in-out;

      &:hover {
        transform: scale(1.3);
      }
    }
  `,
  ContentContainer: styled(Tile)`
    padding: ${props => props.theme.spacing.md} ${props => props.theme.spacing.md}
      ${props => props.theme.spacing.default};

    ${props => MobileAndDown(props)} {
      padding: ${props => props.theme.spacing.default};
    }
  `,
  Content: styled.div``
};
