import { SVGIcon } from '@components';
import React from 'react';
import { SpacingName } from '../Theme';

export type CheckboxState = 'checked' | 'unchecked' | 'indeterminate';

export default (props: {
  state?: CheckboxState;
  paddingRight?: SpacingName;
  variant?: 'square' | 'circle';
  isHovered?: boolean;
}) => {
  const checkedSrc =
    props.variant == 'circle'
      ? '/static/icons/circleOnWhiteSelectedBold-o.svg'
      : '/static/icons/checkBoxPrimaryChecked.svg';

  const uncheckedSrc =
    props.variant == 'circle'
      ? props.isHovered
        ? '/static/icons/circleOnWhiteSelected1-o.svg'
        : '/static/icons/circleOnWhite1-o.svg'
      : props.isHovered
      ? '/static/icons/checkBoxPrimary-o.svg'
      : '/static/icons/checkBoxPrimaryUnchecked.svg';

  const indeterminateSrc =
    props.variant == 'circle'
      ? '/static/icons/circleOnWhiteSecondary.svg'
      : '/static/icons/checkBoxPrimaryIndeterminate.svg';

  return (
    <SVGIcon
      size="22px"
      marginLeft="xxxsm"
      paddingRight={props.paddingRight}
      src={props.state === 'checked' ? checkedSrc : props.state === 'unchecked' ? uncheckedSrc : indeterminateSrc}
    />
  );
};
