import React, { useEffect, useState } from 'react';
import Styles from './styles';

const StickyContainer = (props: {
  fixedPositionScrollThresholdY: number;
  topPositionInFixedMode: number;
  topPositionInAbsoluteMode?: number;
  children: (isFixed: boolean) => React.ReactElement;
}) => {
  const [fixed, setFixed] = useState(false);

  const onScroll = () => {
    setFixed(window.pageYOffset >= props.fixedPositionScrollThresholdY);
  };

  useEffect(() => {
    onScroll();
    window.addEventListener('scroll', onScroll, false);
    window.addEventListener('resize', onScroll, false);
    return () => {
      window.removeEventListener('scroll', onScroll);
      window.removeEventListener('resize', onScroll);
    };
  }, []);

  return (
    <Styles.Container
      topPos={props.topPositionInFixedMode}
      topPositionInAbsoluteMode={props.topPositionInAbsoluteMode}
      fixed={fixed}
    >
      {props.children(fixed)}
    </Styles.Container>
  );
};

export default StickyContainer;
