import { css } from 'styled-components';
import Theme, { SpacingName } from './Theme';

export type GenericSpace = SpacingName | boolean | string;

export const spacing2Value = (spacing: GenericSpace, valueOnTrue?: string) =>
  typeof spacing == 'boolean' ? (spacing === true ? valueOnTrue || '8px' : '0px') : Theme.spacing[spacing] || spacing;

//The string value for margins should be used only in case of non standard paddings in design were used.
export interface MarginHelperProps {
  marginTop?: GenericSpace;
  marginRight?: GenericSpace;
  marginBottom?: GenericSpace;
  marginLeft?: GenericSpace;
  marginVertical?: GenericSpace;
  marginHorizontal?: GenericSpace;
  margin?: GenericSpace;
}

export const margin = css<MarginHelperProps>`
  ${props => (props.margin ? `margin: ${spacing2Value(props.margin, props.theme.spacing.sm)};` : '')}
  ${props => (props.marginTop ? `margin-top: ${spacing2Value(props.marginTop, props.theme.spacing.sm)};` : '')}
  ${props => (props.marginRight ? `margin-right: ${spacing2Value(props.marginRight, props.theme.spacing.sm)};` : '')}
  ${props => (props.marginBottom ? `margin-bottom: ${spacing2Value(props.marginBottom, props.theme.spacing.sm)};` : '')}
  ${props => (props.marginLeft ? `margin-left: ${spacing2Value(props.marginLeft, props.theme.spacing.sm)};` : '')}
  ${props =>
    props.marginVertical
      ? `margin-top: ${spacing2Value(props.marginVertical, props.theme.spacing.sm)};
      margin-bottom: ${spacing2Value(props.marginVertical, props.theme.spacing.sm)};`
      : ''}
  ${props =>
    props.marginHorizontal
      ? `margin-left: ${spacing2Value(props.marginHorizontal, props.theme.spacing.sm)};
    margin-right: ${spacing2Value(props.marginHorizontal, props.theme.spacing.sm)};`
      : ''}
`;

//The string value for paddings should be used only in case of non standard paddings in design were used.
interface PaddingHelperProps {
  paddingTop?: GenericSpace;
  paddingRight?: GenericSpace;
  paddingBottom?: GenericSpace;
  paddingLeft?: GenericSpace;
  paddingVertical?: GenericSpace;
  paddingHorizontal?: GenericSpace;
  padding?: GenericSpace;
}

const padding = css<PaddingHelperProps>`
  ${props => (props.padding ? `padding: ${spacing2Value(props.padding, props.theme.spacing.sm)};` : '')}
  ${props => (props.paddingTop ? `padding-top: ${spacing2Value(props.paddingTop, props.theme.spacing.sm)};` : '')}
  ${props => (props.paddingRight ? `padding-right: ${spacing2Value(props.paddingRight, props.theme.spacing.sm)};` : '')}
  ${props =>
    props.paddingBottom ? `padding-bottom: ${spacing2Value(props.paddingBottom, props.theme.spacing.sm)};` : ''}
  ${props => (props.paddingLeft ? `padding-left: ${spacing2Value(props.paddingLeft, props.theme.spacing.sm)};` : '')}
  ${props =>
    props.paddingVertical
      ? `padding-top: ${spacing2Value(props.paddingVertical, props.theme.spacing.sm)};
        padding-bottom: ${spacing2Value(props.paddingVertical, props.theme.spacing.sm)};`
      : ''}
  ${props =>
    props.paddingHorizontal
      ? `padding-left: ${spacing2Value(props.paddingHorizontal, props.theme.spacing.sm)};
        padding-right: ${spacing2Value(props.paddingHorizontal, props.theme.spacing.sm)};`
      : ''}
`;

export type HelperProps = MarginHelperProps &
  PaddingHelperProps & {
    noMargin?: boolean;
    noPadding?: boolean;
    fullWidth?: boolean;
    fullHeight?: boolean;
  };

const helpers = css<HelperProps>`
  ${props => (props.noMargin ? `margin: 0;` : '')}
  ${margin}

  ${props => (props.noPadding ? `padding: 0;` : '')}
  ${padding}
  
  ${props => (props.fullWidth ? `width: 100%;` : '')}
  ${props => (props.fullHeight ? `height: 100%;` : '')}
`;

interface ColorHelperProps {
  color?: string;
}

export const color = css<ColorHelperProps>`
  ${props => (props.color ? props.theme.colors[props.color] : 'inherit')}
`;
export const border = css`
  border-radius: ${props => props.theme.spacing.sm};
`;

export default helpers;
