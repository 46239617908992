import { Flex, Text } from '@components';
import React from 'react';
import BlankStateStyles from './styles';

type BlankStateProps = React.PropsWithChildren<{ icon: React.ReactElement }>;

const BlankState: React.FC<BlankStateProps> = props => {
  return (
    <BlankStateStyles.Container>
      <Flex vertical alignItems="center" justifyContent="center" gap="default">
        {props.icon}
        <Text.Paragraph color="onWhiteLight" align="center">
          {props.children}
        </Text.Paragraph>
      </Flex>
    </BlankStateStyles.Container>
  );
};

export default BlankState;
