import { Button, Flex } from '@components';
import styled from 'styled-components';

export default {
  Container: styled.div<{ isFromSelect?: boolean }>`
    ${Flex} {
      display: initial;
    }

    ${Button.Medium} {
      height: 37px;
      font-size: 15px;
      padding: 0;
      margin-bottom: 15px;
      &:not(:last-child) {
        ${props => props.isFromSelect && `margin-bottom: ${props.theme.spacing.sm};`}
      }
      ${Flex} {
        > * {
          /* Raise the icon padding to increase its click area */
          &:not(:last-child) {
            padding-left: ${props => props.theme.spacing.sm};
          }
          &:last-child {
            padding: ${props => `${props.theme.spacing.xsm} ${props.theme.spacing.sm}`};
          }
        }
      }
    }

    ${Button.Large} {
      height: 37px;
      font-size: 15px;
      padding: 0;
      text-align: left;
      margin-bottom: 15px;
      &:not(:last-child) {
        ${props => props.isFromSelect && `margin-bottom: ${props.theme.spacing.sm};`}
      }
      ${Flex} {
        > * {
          /* Raise the icon padding to increase its click area */
          &:not(:last-child) {
            padding-left: ${props => props.theme.spacing.sm};
          }
          &:last-child {
            padding: ${props =>
              `${props.theme.spacing.xsm} ${props.theme.spacing.sm} ${props.theme.spacing.xsm} ${props.theme.spacing.xsm}`};
          }
        }
      }
    }
  `
};
