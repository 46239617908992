import React from 'react';
import { LocalizedText, Text } from '@components';
import { RequestResult } from '../../interfaces/requests';

type GenericErrorProps = React.PropsWithChildren<{
  error?: RequestResult<void> | null;
  onlyGenericMessage?: boolean;
}>;

const getErrorKey = (error: RequestResult<void>): string => {
  // Status and api key handling
  if (error.status === 500) {
    return 'commonApiErrors.500OrNoInternet';
  }
  if (error.status === 429) {
    return 'commonApiErrors.429';
  }
  // Message handling
  switch (error.message) {
    case 'Network Error':
      return 'commonApiErrors.500OrNoInternet';
    case '':
      return '';
    default:
      return error.message || '';
  }
};

const GenericError: React.FC<GenericErrorProps> = props => {
  if (!props.error) return null;
  const errorKey = getErrorKey(props.error);
  if (!errorKey) return null;
  return (
    <Text.Paragraph color="onWhiteError" align="center">
      {props.onlyGenericMessage ? (
        <LocalizedText id="commonApiErrors.general" description="Generic error" />
      ) : (
        <LocalizedText id={errorKey} description="Generic error" />
      )}
    </Text.Paragraph>
  );
};

export default React.memo(GenericError);
