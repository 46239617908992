import styled from 'styled-components';

export default {
  Container: styled.div`
    img,
    svg {
      color: ${props => props.theme.colors.text.onWhiteDisabled};
      width: 32px !important;
      height: auto !important;
      max-height: 32px;
    }
  `
};
