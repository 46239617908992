import { User } from '../redux/modules/user/interfaces';
import { Locale } from '../locales';

const addBookingUrlParam = (q: Array<string>, type: string, name: string, value: string | undefined) => {
  if (value) {
    q.push(`${type}[${name}]=${encodeURIComponent(value)}`);
  }
};

export const getBookingUrl = (
  url: string | undefined,
  user: User | undefined,
  locale: Locale,
  marketName?: string,
  returnEncodedQueryValueOnly?: boolean
) => {
  if (!url) {
    return '';
  }
  const [path, search] = url.split('?');
  const q = [];
  if (search) {
    q.push(search);
  }
  if (user) {
    const { firstName, lastName, workEmailAddress, phone, company, gender = '' } = user;
    const { street, postalCode, city, name: companyName } = company || {};
    const genderValue = { mr: '38594', ms: '38595' }[gender];
    const languageValue = { de: '38619', en: '38620', fr: '38621', it: '38622' }[locale];
    let addParam = addBookingUrlParam.bind(null, q, 'customer');
    addParam('prename', firstName);
    addParam('lastname', lastName);
    addParam('email', workEmailAddress);
    addParam = addBookingUrlParam.bind(null, q, 'custom_field');
    addParam('3754', genderValue);
    addParam('6548', phone);
    addParam('6551', street);
    addParam('6549', postalCode);
    addParam('6550', city);
    addParam('3684', companyName);
    addParam('6544', languageValue);
    addParam('5964', marketName);
  }
  if (q.length) {
    const encodedParams = Buffer.from(q.join('&')).toString('base64');
    if (returnEncodedQueryValueOnly) return encodedParams;
    url = path + '?q=' + encodedParams;
  }
  return url;
};

let index = 1;
export const getGUID = () => {
  if (index > 1000000000) index = 1;
  return '' + Math.random().toString(16).slice(2) + index++;
};

export const url2AbsoluteUrl = (url?: string) => {
  if (url) {
    if (url.startsWith('http:') || url.startsWith('https:') || url.startsWith('ftp:')) {
      return url;
    }
    return `//${url}`;
  }
};

export const pickProperties = <FinalType = any>(object?: any, properties?: string[]) => {
  if (object && properties) {
    const result = {};
    for (const property of properties) {
      result[property] = object[property];
    }
    return result as FinalType;
  }

  return {};
};

const onClickEmailHandler = (emailHrefWithMailToPart: string) => {
  return (event: any) => {
    window.open(emailHrefWithMailToPart, 'Mail');
    event.preventDefault();
  };
};

export const emailAddress2AProps = (emailHrefWithMailToPart: string) => {
  return {
    href: emailHrefWithMailToPart,
    onClick: onClickEmailHandler(emailHrefWithMailToPart)
  };
};
