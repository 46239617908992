import { Flex, FlexProps, Text } from '@components';
import React, { useState } from 'react';
import Styles from './styles';

type ToggleProps = FlexProps & {
  setChecked(value: boolean): void;
  defaultValue?: boolean;
  title?: React.ReactNode;
  className?: string;
  disabled?: boolean;
};

const Toggle = (props: ToggleProps) => {
  const { defaultValue = false, disabled = false, title, ...rest } = props;
  const [checked, setChecked] = useState(defaultValue);
  const onToggleClick: React.ChangeEventHandler<HTMLInputElement> = event => {
    const isChecked = event.target.checked;
    setChecked(isChecked);
    props.setChecked(isChecked);
  };
  return (
    <Flex gap="sm" alignItems="center" {...rest}>
      <Text.Span16_24_bold>{title}</Text.Span16_24_bold>
      <Styles.ToggleLabel disabled={disabled}>
        <input type="checkbox" checked={checked} onChange={onToggleClick} disabled={disabled} />
        <i />
      </Styles.ToggleLabel>
    </Flex>
  );
};

export default React.memo(Toggle);
