import { Flex, helpers, Scrollbar } from '@components';
import styled from 'styled-components';
import { SpacingName } from '../Theme';

type TooltipItemProps = {
  padding?: SpacingName;
  verticalPadding?: SpacingName;
  highlightOnHover?: boolean;
  highlightTextOnHover?: boolean;
};

type ItemsContainerProps = {
  maxHeight?: string;
};

export const TooltipSelectStyle = {
  Container: styled(Flex)`
    ${helpers}
  `,
  TooltipItem: styled.div<TooltipItemProps>`
    cursor: pointer;
    user-select: none;
    max-width: 456px;
    color: ${props => props.theme.colors.text.onWhitePrimary};
    margin-top: -1.5px;

    padding: ${props => props.theme.spacing[props.padding || 'none']};

    ${props =>
      props.verticalPadding &&
      `padding-top: ${props.theme.spacing[props.verticalPadding]}; padding-bottom: ${
        props.theme.spacing[props.verticalPadding]
      };`}

    &:hover {
      ${props => (props.highlightOnHover ? `background-color: ${props.theme.colors.background};` : '')}
      transition: color 100ms ease-out;
      transition: background-color 100ms ease-out;

      * {
        ${props => (props.highlightTextOnHover ? `color: ${props.theme.colors.text.onWhitePrimary};` : '')}
      }
    }
  `,
  ItemsContainer: styled.div<ItemsContainerProps>`
    max-height: ${props => props.maxHeight};
    overflow-y: auto;
    ${Scrollbar}

    @media (max-width: ${props => props.theme.breakpoints.xsmall}) {
      width: 300px;
    }
  `
};

TooltipSelectStyle.TooltipItem.defaultProps = {
  highlightOnHover: false,
  highlightTextOnHover: true
};

TooltipSelectStyle.ItemsContainer.defaultProps = {
  maxHeight: '300px'
};

export default TooltipSelectStyle;
