import React from 'react';
import { availableLocales, Locale, useLocale } from 'src/locales';
import { ControlButton, ControlDropdown, LocalizedText, Flex } from '@components';
import { useRouter } from 'next/router';
import useOpenableState from '@hooks/useOpenableState';

const LanguageSelector: React.FC = () => {
  const locale = useLocale();
  const router = useRouter();
  const [isActive, { toggle, close }] = useOpenableState(false);
  const handleLanguageChange = (key: Locale) => {
    if (locale !== key) {
      window.location.assign(`/${key}${router.asPath}`);
    }
  };
  return (
    <ControlDropdown
      activeOnHover
      text={locale.toUpperCase()}
      handleClick={toggle}
      selected={isActive}
      handleClose={close}
    >
      <Flex vertical gap="sm">
        {availableLocales.map(key => (
          <Flex key={key} onClick={() => handleLanguageChange(key)}>
            <ControlButton activeOnHover active={key === locale}>
              <LocalizedText id={`locale${key.toUpperCase()}`} />
            </ControlButton>
          </Flex>
        ))}
      </Flex>
    </ControlDropdown>
  );
};

export default React.memo(LanguageSelector);
