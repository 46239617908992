import { Animations } from '@components';
import styled from 'styled-components';

export type FormFieldErrorVariant = 'default' | 'absolute-small-spacing';

export default {
  Container: styled(Animations.FadeDown)<{ variant?: FormFieldErrorVariant; maxWidth?: string }>`
    ${props =>
      props.variant == 'absolute-small-spacing'
        ? 'position: absolute; height: auto!important; width: auto!important; '
        : ''}

    ${props => (props.maxWidth ? `max-width: ${props.maxWidth};` : '')}
    font-weight: normal;
    cursor: default !important;
  `
};
