import { Flex } from '@components';
import styled from 'styled-components';

export default {
  Container: styled(Flex)`
    padding-right: 15px;

    span {
      white-space: nowrap !important;
    }
  `
};
