import { useState, useEffect } from 'react';
import { breakpoints } from 'src/components/Theme';

const getWindowWidth = (): number => {
  if (process.browser) {
    return window.innerWidth;
  }

  return -1;
};

export const useWindowWidth = (): number => {
  const [windowWidth, setWindowWidth] = useState(getWindowWidth());

  useEffect(() => {
    if (process.browser) {
      function handleResize() {
        setWindowWidth(getWindowWidth());
      }

      window.addEventListener('resize', handleResize);
      handleResize();

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [process.browser]);

  return windowWidth;
};

export const useBreakpoints = (customTreshold1?: number, customTreshold2?: number) => {
  const [mobile, setMobile] = useState(undefined as boolean | undefined);
  const [mobileAndDown, setMobileAndDown] = useState(undefined as boolean | undefined);
  const [custom1, setCustom1] = useState(undefined as boolean | undefined);
  const [custom2, setCustom2] = useState(undefined as boolean | undefined);
  const [tabletAndUpInTheMiddle, setTabletAndUpTheMiddle] = useState(undefined as boolean | undefined);
  const [tabletAndUp, setTabletAndUp] = useState(undefined as boolean | undefined);
  const [tabletAndDown, setTabletAndDown] = useState(undefined as boolean | undefined);
  const [laptopAndUp, setLaptopAndUp] = useState(undefined as boolean | undefined);
  const [laptopAndDown, setLaptopAndDown] = useState(undefined as boolean | undefined);
  const [desktopAndUp, setDesktopAndUp] = useState(undefined as boolean | undefined);

  useEffect(() => {
    if (process.browser) {
      function handleResize() {
        const width = getWindowWidth();
        setMobile(width == -1 ? undefined : width < breakpoints.tablet);
        setMobileAndDown(width == -1 ? undefined : width <= breakpoints.mobile);
        setCustom1(width == -1 || customTreshold1 == null ? undefined : width < customTreshold1);
        setCustom2(width == -1 || customTreshold2 == null ? undefined : width < customTreshold2);
        setTabletAndUp(width == -1 ? undefined : width >= breakpoints.mobile);
        setTabletAndUpTheMiddle(width == -1 ? undefined : width >= breakpoints.tablet);
        setTabletAndDown(width == -1 ? undefined : width <= breakpoints.laptop);
        setLaptopAndUp(width == -1 ? undefined : width >= breakpoints.laptop);
        setLaptopAndDown(width == -1 ? undefined : width <= breakpoints.desktop);
        setDesktopAndUp(width == -1 ? undefined : width >= breakpoints.desktop);
      }

      window.addEventListener('resize', handleResize);
      handleResize();
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [process.browser]);

  return {
    mobile,
    mobileAndDown,
    custom1,
    custom2,
    tabletAndUp,
    //To be normalized/removed/replaced with custom1/custom2 if needed
    tabletAndUpInTheMiddle,
    tabletAndDown,
    laptopAndUp,
    laptopAndDown,
    desktopAndUp
  };
};

const getElementTop = (element: any) => {
  const offset = element.getBoundingClientRect();
  return offset.top + window.scrollY;
};

const getTopElement = (elements: NodeListOf<Element>) => {
  if (elements?.length) {
    let maxTopElement = elements[0];
    elements.forEach(item => {
      const offset = getElementTop(item);
      const maxTopElementOffset = getElementTop(maxTopElement);

      if (offset < maxTopElementOffset) {
        maxTopElement = item;
      }
    });
    return maxTopElement;
  }
  return null;
};

export const scrollToElement = (props: {
  elementSelector: string;
  dalay?: number;
  offsetTop?: number;
  noUrlChange?: boolean;
}) => {
  setTimeout(function () {
    const elements = document.querySelectorAll(props.elementSelector);
    const topElement = getTopElement(elements);
    if (topElement) {
      const offset = getElementTop(topElement);
      if (!props.noUrlChange) window.location.hash = props.elementSelector;
      window.scrollTo(window.scrollX, offset - (props.offsetTop !== undefined ? props.offsetTop : 115));
    }
  }, props.dalay || 50);
};

export const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const updatePosition = () => {
      setScrollPosition(window.pageYOffset);
    };
    window.addEventListener('scroll', updatePosition);
    updatePosition();
    return () => window.removeEventListener('scroll', updatePosition);
  }, []);

  return scrollPosition;
};
