import React from 'react';
import { LocalizedText, Text } from '@components';
import { useField } from 'formik';
import Styles, { FormFieldErrorVariant } from './styles';

export const FormFieldError = (props: {
  fieldName?: string;
  errorPrefix?: string;
  variant?: FormFieldErrorVariant;
  maxWidth?: string;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta] = useField(props.fieldName || 'no-field');
  const hasError = !!meta.error && !!meta.touched;
  return (
    (hasError && (
      <Styles.Container seconds={0.2} variant={props.variant} maxWidth={props.maxWidth}>
        <Text.SmallerParagraph
          color="onWhiteError"
          marginTop={props.variant == 'absolute-small-spacing' ? 'xxxsm' : 'xsm'}
        >
          <LocalizedText id={`${props.errorPrefix ? `${props.errorPrefix}.` : ''}${meta.error}`} />
        </Text.SmallerParagraph>
      </Styles.Container>
    )) || <></>
  );
};
