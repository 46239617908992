import styled, { css } from 'styled-components';

export default {
  StickyButtonWrapper: styled.div<{ isFixed: boolean; backgroundColor?: string }>`
    display: inline-block;
    margin: 0px 0 0 30px;
    cursor: pointer;
    background-color: ${props => props.backgroundColor || 'white'};
    width: fit-content;

    ${props =>
      props.isFixed &&
      css`
        padding-inline: 8px;
        border-radius: 40px;
        box-shadow: 5px 20px 50px rgba(0, 0, 0, 0.204054);
        height: 32px;
        line-height: 18px;
        span,
        p {
          width: 0px;
          height: 18px;
          overflow: hidden;
        }
        span {
          margin: 0px;
        }
        img {
          height: 18px;
        }
      `}
    &:hover {
      span,
      p {
        width: inherit;
        ${props =>
          props.isFixed &&
          css`
            padding-left: 7px;
            padding-right: 7px;
            transform: translateY(-2px);
          `}
      }
    }
  `
};
