export {};

declare global {
  interface Array<T> {
    //Returns first element if exists otherwise returns null
    findFirst(predicate: (value: T, index: number, obj: T[]) => unknown, thisArg?: any): T | undefined;
  }
}

if (!Array.prototype.findFirst) {
  Array.prototype.findFirst = function <T>(predicate: (value: T, index: number, obj: T[]) => unknown): T | undefined {
    const elements = this.filter(predicate);
    return (elements && elements.length > 0 && elements[0]) || null;
  };
}

export const uniqueStringArrray = (array?: string[]) => {
  return [...new Set(array || [])];
};

export const removeWhitespaceTexts = (array?: string[]) => {
  return array?.filter(element => element.trim() != '');
};

export const sortArrayByList = (array: any[], topElements: any[], element2property?: (element: any) => any) => {
  return array.sort((a, b) => {
    return (
      topElements.indexOf((element2property ? element2property(b) : b) || '') -
      topElements.indexOf((element2property ? element2property(a) : a) || '')
    );
  });
};

export const hasSetElement = (set: Set<any>, element: any, areObjectsTheSame?: (a: any, b: any) => boolean) => {
  for (const value of set.values()) {
    if (areObjectsTheSame) {
      if (areObjectsTheSame(value, element)) {
        return true;
      }
    } else {
      if (value === element) return true;
    }
  }
  return false;
};

//Doesn't support objects - just basic types
export const doArraysContainTheSameElements = (
  arrayA?: any[],
  arrayB?: any[],
  areObjectsTheSame?: (a: any, b: any) => boolean
) => {
  const setA = new Set(arrayA || []);
  const setB = new Set(arrayB || []);
  return setB.size === setA.size && [...setB].every(b => hasSetElement(setA, b, areObjectsTheSame));
};
