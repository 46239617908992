import { Flex, Text } from '@components';
import React from 'react';
import ConsultantHeadStyles from './styles';

type ConsultantHeadProps = {
  firstName: string;
  lastName: string;
  avatar: string;
  title?: string;
};

const ConsultantHead: React.FC<ConsultantHeadProps> = props => {
  const fullName = () => {
    return `${props.firstName} ${props.lastName}`;
  };
  return (
    <ConsultantHeadStyles.Container>
      <Flex alignItems="center">
        {props.avatar && <ConsultantHeadStyles.Avatar src={props.avatar} />}
        <div>
          {(props.firstName || props.lastName) && (
            <Text.ParagraphResponsive weight="bold">{fullName()}</Text.ParagraphResponsive>
          )}
          {props.title && <Text.SmallerParagraph color="onWhiteLight">{props.title}</Text.SmallerParagraph>}
        </div>
      </Flex>
    </ConsultantHeadStyles.Container>
  );
};
export default ConsultantHead;
