import { Flex, FlexProps } from '@components';
import { IconDefinition, SizeProp } from '@fortawesome/fontawesome-svg-core';
import React, { FC, useState } from 'react';
import { DataAnalytics } from 'src/utils/dataAnalytics';
import Icon from '../Icon';
import Text, { TextProps } from '../Text';
import { AppTheme, SpacingName } from '../Theme';
import TextIconStyles from './styles';

export type TextIconProps = React.PropsWithChildren<
  {
    variant?: keyof typeof Text;
    faLeftIcon?: IconDefinition;
    leftIconComponent?: (active: boolean) => React.ReactElement;
    faRightIcon?: IconDefinition;
    rightIconComponent?: (active: boolean) => React.ReactElement;
    spacing?: SpacingName;
    iconPaddingBottom?: SpacingName;
    //Default: true
    extraTextTopMargin?: boolean;
    faIconSize?: SizeProp;
    activeColor?: keyof AppTheme['colors']['text'];
    shouldHaveHover?: boolean;
    shouldUnderlineOnHover?: boolean;
    onClick?: (e?: any) => void;
  } & FlexProps &
    TextProps &
    DataAnalytics
>;

const TextIcon: React.FC<TextIconProps> = props => {
  const TextComponent: FC<TextProps> = Text[props.variant || 'SmallerParagraph'];
  const spacing = props.spacing || 'xsm';
  const [isActive, setIsActive] = useState(false);

  return (
    <TextIconStyles.Container
      shouldUnderlineOnHover={props.shouldUnderlineOnHover}
      shouldHaveHover={props.shouldHaveHover}
      color={isActive ? 'onWhitePrimary' : props.color}
      onMouseEnter={() => {
        if (props.shouldHaveHover) {
          setIsActive(true);
        }
      }}
      onMouseLeave={() => {
        if (props.shouldHaveHover) {
          setIsActive(false);
        }
      }}
      onClick={(e: any) => {
        if (props.shouldHaveHover) {
          // Flash red when clicked;
          setIsActive(true);
          setTimeout(() => setIsActive(false), 1000);
        }
        props.onClick?.(e);
      }}
      height={props.height}
      data-analytics-id={props['data-analytics-id']}
    >
      <Flex {...props} alignItems="center">
        <Icon
          left
          faIcon={props.faLeftIcon}
          iconComponent={props.leftIconComponent}
          spacing={spacing}
          faIconSize={props.faIconSize}
          isActive={isActive}
          paddingBottom={props.iconPaddingBottom}
        />
        <TextComponent
          size={props.size}
          weight={props.weight}
          color={isActive ? 'onWhitePrimary' : props.color}
          decoration={props.decoration}
          whiteSpace={props.whiteSpace}
          wordWrap={props.wordWrap}
          numberOfLines={props.numberOfLines}
          height={props.height}
          marginTop={props.extraTextTopMargin === false ? 'none' : '3px'}
        >
          {props.children}
        </TextComponent>
        <Icon
          faIcon={props.faRightIcon}
          iconComponent={props.rightIconComponent}
          spacing={spacing}
          faIconSize={props.faIconSize}
          isActive={isActive}
          paddingBottom={props.iconPaddingBottom}
        />
      </Flex>
    </TextIconStyles.Container>
  );
};

TextIcon.defaultProps = {
  iconPaddingBottom: 'none'
};

export default TextIcon;
