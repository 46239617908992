import { useEffect, useRef, useState } from 'react';

export const useHorizontalScroll = (settings: { scrollContentTravelDistance: number; calculateHeight?: boolean }) => {
  const containerRef = useRef<null | HTMLDivElement>(null);
  const contentRef = useRef<null | HTMLDivElement>(null);

  const [travelState, setTravelState] = useState({ scrollContentTravelling: false, scrollContentTravelDirection: '' });

  // Ref: https://benfrain.com/a-horizontal-scrolling-navigation-pattern-for-touch-and-mouse-with-moving-current-indicator
  const determineOverflow = () => {
    if (containerRef.current && contentRef.current) {
      const containerMetrics = containerRef.current.getBoundingClientRect();
      const containerMetricsRight = Math.floor(containerMetrics.right);
      const containerMetricsLeft = Math.floor(containerMetrics.left);
      const contentMetrics = contentRef.current.getBoundingClientRect();
      const contentMetricsRight = Math.floor(contentMetrics.right);
      const contentMetricsLeft = Math.floor(contentMetrics.left);

      if (containerMetricsLeft > contentMetricsLeft && containerMetricsRight < contentMetricsRight) {
        return 'both';
      } else if (contentMetricsLeft < containerMetricsLeft) {
        return 'left';
      } else if (contentMetricsRight > containerMetricsRight) {
        return 'right';
      } else {
        return 'none';
      }
    } else {
      return 'unloaded';
    }
  };

  const updateAttribute = () => {
    if (containerRef && containerRef.current) {
      containerRef.current.setAttribute('data-overflowing', determineOverflow());
    }
  };

  let ticking = false;

  const onScroll = () => {
    if (!ticking) {
      window.requestAnimationFrame(() => {
        updateAttribute();
        ticking = false;
      });
    }
    ticking = true;
  };

  const onClick = (direction: 'right' | 'left') => {
    // If in the middle of a move, return
    if (travelState.scrollContentTravelling || !containerRef.current || !contentRef.current) {
      return;
    }

    // We do want a transition when moving
    contentRef.current.style.transition = 'transform 120ms ease-in-out';

    const overflow = determineOverflow();

    // If we have content overflowing both sides or on the direction
    if (overflow === direction || overflow === 'both') {
      let availableScroll = 0;

      if (direction === 'left') {
        // Find how far this panel has been scrolled
        availableScroll = containerRef.current.scrollLeft;
      } else {
        // Get the right edge of the container and content
        const scrollContentRightEdge = contentRef.current.getBoundingClientRect().right;
        const scrollContentScrollerRightEdge = containerRef.current.getBoundingClientRect().right;

        // Now we know how much space we have available to scroll
        availableScroll = Math.floor(scrollContentRightEdge - scrollContentScrollerRightEdge);
      }

      // If the space available is less than two lots of our desired distance, just move the whole amount
      // otherwise, move by the amount in the settings
      if (availableScroll < settings.scrollContentTravelDistance) {
        contentRef.current.style.transform = `translateX(${direction === 'right' ? '-' : ''}${availableScroll}px)`;
      } else {
        contentRef.current.style.transform = `translateX(${direction === 'right' ? '-' : ''}${
          settings.scrollContentTravelDistance
        }px)`;
      }

      // Update our settings
      setTravelState({ scrollContentTravelDirection: direction, scrollContentTravelling: true });
    }

    // Now update the attribute in the DOM
    updateAttribute();
  };

  const transitionEnd = () => {
    if (!containerRef.current || !contentRef.current) {
      return;
    }

    // Get the value of the transform, apply that to the current scroll position (so get the scroll pos first) and then remove the transform
    const styleOfTransform = window.getComputedStyle(contentRef.current, null);
    const tr = styleOfTransform.getPropertyValue('-webkit-transform') || styleOfTransform.getPropertyValue('transform');

    // If there is no transition we want to default to 0 and not null
    const amount = Math.abs(parseInt(tr.split(',')[4]) || 0);
    contentRef.current.style.transition = 'none';
    contentRef.current.style.transform = 'none';

    // Now lets set the scroll position
    if (travelState.scrollContentTravelDirection === 'left') {
      containerRef.current.scrollLeft = containerRef.current.scrollLeft - amount;
    } else {
      containerRef.current.scrollLeft = containerRef.current.scrollLeft + amount;
    }

    setTravelState({ scrollContentTravelDirection: '', scrollContentTravelling: false });

    // Now update the attribute in the DOM
    updateAttribute();
  };

  const setupDrag = (lastClientX?: number, lastClientY?: number, pushed?: number) => {
    if (!containerRef.current || !contentRef.current) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {};
    }

    // Ref: https://github.com/asvd/dragscroll/blob/master/dragscroll.js
    let newScrollX = 0,
      newScrollY = 0;

    const el = containerRef.current;
    const cont = (el as any).container || el;
    let scroller = (el as any).scroller || el;

    const mouseDown = (e: MouseEvent) => {
      if (determineOverflow() !== 'none') {
        el.style.cursor = 'grabbing';
      }

      if (!el.hasAttribute('nochilddrag') || document.elementFromPoint(e.pageX, e.pageY) == cont) {
        pushed = 1;
        lastClientX = e.clientX;
        lastClientY = e.clientY;

        e.preventDefault();
      }
    };

    const mouseEvent = () => {
      if (determineOverflow() !== 'none') {
        el.style.cursor = 'grab';
      }
      pushed = 0;
    };

    const mouseEnter = () => {
      if (determineOverflow() !== 'none') {
        el.style.cursor = 'grab';
      } else {
        el.style.cursor = 'initial';
      }
    };

    const mouseMove = (e: MouseEvent) => {
      if (pushed && lastClientX && lastClientY) {
        scroller.scrollLeft -= newScrollX = -lastClientX + (lastClientX = e.clientX);
        scroller.scrollTop -= newScrollY = -lastClientY + (lastClientY = e.clientY);
        if (el == document.body) {
          (scroller = document.documentElement).scrollLeft -= newScrollX;
          scroller.scrollTop -= newScrollY;
        }
      }
    };

    el.addEventListener('mousedown', mouseDown, false);
    el.addEventListener('mouseup', mouseEvent, false);
    el.addEventListener('mouseleave', mouseEvent, false);
    el.addEventListener('mouseenter', mouseEnter, false);
    el.addEventListener('mousemove', mouseMove, false);

    return () => {
      el.removeEventListener('mousedown', mouseDown);
      el.removeEventListener('mouseup', mouseEvent);
      el.removeEventListener('mouseleave', mouseEvent);
      el.removeEventListener('mouseenter', mouseEnter);
      el.removeEventListener('mousemove', mouseMove);
    };
  };

  useEffect(() => {
    updateAttribute();
    const disposeSetupDrag = setupDrag();

    let ticking = false;
    const updateElementsHeight = () => {
      if (settings.calculateHeight) {
        if (!ticking) {
          window.requestAnimationFrame(() => {
            if (contentRef && contentRef.current) {
              const length = contentRef.current.childElementCount;

              // Find which element is the biggest
              // Cannot use .find here since .children is not a real array
              let biggestHeight = 0;
              for (let i = 0; i < length; i++) {
                const element = contentRef.current.children[i] as any;

                if (element.style && element.style.height) {
                  element.style.height = '';
                }

                const height = element.offsetHeight || element.clientHeight;
                if (height > biggestHeight) {
                  biggestHeight = height;
                }
              }

              // Then update everyone with the biggest size
              for (let i = 0; i < length; i++) {
                const element = contentRef.current.children[i] as any;
                if (element.style) {
                  element.style.height = `${biggestHeight}px`;
                }
              }
            }
            ticking = false;
          });
        }
        ticking = true;
      }
    };

    updateElementsHeight();

    window.addEventListener('resize', onScroll, false);
    window.addEventListener('resize', updateElementsHeight, false);
    if (contentRef && contentRef.current) {
      contentRef.current.addEventListener('change', updateElementsHeight, false);
    }
    return () => {
      window.removeEventListener('resize', onScroll);
      window.removeEventListener('resize', updateElementsHeight);
      if (contentRef && contentRef.current) {
        contentRef.current.removeEventListener('change', updateElementsHeight);
      }
      disposeSetupDrag();
    };
  }, []);

  return { containerRef, contentRef, transitionEnd, onScroll, onClick, currentOverflow: determineOverflow() };
};
