import styled from 'styled-components';

export default {
  Container: styled.div`
    margin: 0 auto;
    max-width: 324px;

    /* Tablet */
    @media (min-width: ${props => props.theme.breakpoints.small}) {
      max-width: 672px;
    }

    /* Laptop */
    @media (min-width: ${props => props.theme.breakpoints.large}) {
      max-width: 908px;
    }
  `
};
