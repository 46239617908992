import { ThemeProps, ThemeContext } from 'styled-components';
import { useContext } from 'react';

export const TabletAndUpNormalized = (props: ThemeProps<any>) =>
  `@media (min-width: ${props.theme.breakpoints.xsmall})`;
export const TabletAndUp = (props: ThemeProps<any>) => `@media (min-width: ${props.theme.breakpoints.small})`;
export const LaptopAndUp = (props: ThemeProps<any>) => `@media (min-width: ${props.theme.breakpoints.large})`;
export const DesktopAndUp = (props: ThemeProps<any>) => `@media (min-width: ${props.theme.breakpoints.xlarge})`;
export const TabletAndDown = (props: ThemeProps<any>) => `@media (max-width: ${props.theme.breakpoints.large})`;
export const LaptopAndDown = (props: ThemeProps<any>) => `@media (max-width: ${props.theme.breakpoints.xlarge})`;
export const MobileAndDown = (props: ThemeProps<any>) => `@media (max-width: ${props.theme.breakpoints.xsmall})`;

//This custom media query should be used only in very rare and specific cases.
export const CustomAndDown = (treshold: string) => `@media (max-width: ${treshold})`;

export const useTheme = () => useContext(ThemeContext);
