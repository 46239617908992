import React, { useState } from 'react';
/* 
A component to handle mouse hover events
Example usage:
  <Hover
    render={({ isActive, ...otherProps }) => (
      <ComeComponent
        {...otherProps}
 */
const Hover = (props: { render: (props: any) => any }) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <React.Fragment>
      {props.render({
        isHovered,
        onMouseEnter: () => {
          setIsHovered(true);
        },
        onMouseLeave: () => {
          setIsHovered(false);
        }
      })}
    </React.Fragment>
  );
};

export default Hover;
