import styled from 'styled-components';
import { GenericSpace, margin, MarginHelperProps, spacing2Value } from '../helpers';
import { ColorName } from '../Theme';

const Divider = styled.div<MarginHelperProps & { color?: ColorName; width?: string; leftShift?: GenericSpace }>`
  background-color: ${props => (props.color ? props.theme.colors[props.color] : props.theme.colors.disabled)};
  height: 1px;
  width: ${props => props.width || '100%'};
  ${props =>
    props.leftShift !== undefined ? `margin-left: -${spacing2Value(props.leftShift, props.theme.spacing.sm)};` : ''}
  ${margin}
`;

Divider.defaultProps = {
  marginBottom: 'default',
  marginTop: 'default'
};

export { Divider };
