import React from 'react';
import Styles from './styles';
import { doEditTranslation } from 'src/redux/modules/app/actions';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { AppState } from 'src/redux/reducers';
import { SVGIcon } from '@components';

const TranslationEditButton_ = (props: ResProps) => {
  const dispatch = useDispatch();

  return (
    <>
      {props.adminMode == 'translations-editing' && (
        <Styles.EditButton
          absolute={props.absolute}
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            dispatch(doEditTranslation(props.translationKey || 'none'));
          }}
        >
          <SVGIcon size="16px" src="/static/icons/editWithBorder.svg" />
        </Styles.EditButton>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      doEditTranslation
    },
    dispatch
  );

const mapStateToProps = ({ appReducer }: AppState) => ({
  adminMode: appReducer.adminMode
});

type ResProps = ReturnType<typeof mapStateToProps> & {
  translationKey?: string;
  absolute?: boolean;
};

const TranslationEditButton = connect(mapStateToProps, mapDispatchToProps)(TranslationEditButton_);

export default TranslationEditButton;
