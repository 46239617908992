import { Tile } from '@components';
import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import Styles from './styles';

export type ModalProps = PropsWithChildren<{
  onClose: () => void;
  open: boolean;
  width?: string;
  absolute?: boolean;
  fullscreen?: boolean;
  marginTop?: string;
  className?: string;
}>;

const Modal: FC<ModalProps> = ({ onClose, open, width, absolute, fullscreen, children, marginTop, className }) => {
  const [modalRoot, setModalRoot] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [open]);

  // Component unmounted
  useEffect(() => {
    setModalRoot(document.getElementById('modal-root'));
    return () => {
      onClose();
      document.body.style.overflow = 'auto';
    };
  }, []);

  const ModalContent = () => (
    <>
      {open && (
        <Styles.Container open={open}>
          <Styles.Content fullscreen={fullscreen}>
            <Styles.Inner marginTop={marginTop} absolute={absolute} fullscreen={fullscreen}>
              <Tile padding={absolute ? 'none' : 'sm'} width={width} data-test-id="modal" className={className}>
                <Styles.Header absolute={absolute}>
                  <Styles.CloseIconContainer onClick={() => onClose()}>&times;</Styles.CloseIconContainer>
                </Styles.Header>
                {children}
              </Tile>
            </Styles.Inner>
          </Styles.Content>
        </Styles.Container>
      )}
    </>
  );

  if (modalRoot && process.browser) {
    return ReactDOM.createPortal(<ModalContent />, modalRoot);
  } else {
    return <ModalContent />;
  }
};

export default Modal;
