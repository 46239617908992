import styled from 'styled-components';

export default {
  ToggleLabel: styled.label<{ disabled?: boolean }>`
    display: inline-block;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    margin: 0;
    opacity: ${props => (props.disabled ? 0.6 : 1)};
    height: 24px;

    & i {
      position: relative;
      display: inline-block;
      width: 41px;
      height: 20px;
      background-color: ${props => props.theme.colors.button.transparent};
      border: 2px solid ${props => props.theme.colors.handler};
      border-radius: 22px;
      vertical-align: text-bottom;
      transition: all 0.3s linear;

      ::before {
        content: '';
        position: absolute;
        left: 0;
        width: 16px;
        height: 16px;
        background-color: ${props => props.theme.colors.handler};
        border-radius: 50%;
        transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
        transition: all 0.25s linear;
      }

      ::after {
        content: '';
        position: absolute;
        left: 0;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        transform: translate3d(2px, 2px, 0);
        transition: all 0.2s ease-in-out;
      }
    }
    & input {
      display: none;
    }
    & input:checked + i {
      background-color: ${props => props.theme.colors.error};
      border: 2px solid ${props => props.theme.colors.error};
    }
    & input:checked + i::before {
      transform: translate3d(24px, 2px, 0) scale3d(0, 0, 0);
    }
    & input:checked + i::after {
      transform: translate3d(24px, 2px, 0);
      background-color: ${props => props.theme.colors.white};
    }
  `
};
