import styled from 'styled-components';
import Input from '../../components/Input';
import Animations from '../Animations';
import Scrollbar from '../../components/Scrollbar';

export default {
  Container: styled.div<{ disabled?: boolean }>`
    position: relative;
    cursor: pointer;
    ${Input.Text} {
      position: inherit;
      user-select: none;
      padding-right: ${props => props.theme.spacing.xmd};

      &:disabled {
        /* Restore the default input css so it doesn't look like it is disabled */
        ${props => (!props.disabled ? 'opacity: 1;' : '')}
      }
    }
  `,
  IconContainer: styled.div`
    /* This positions the icon right in front of the input */
    position: absolute;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 2;

    /* Puts the icon on the right side */
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 16px;
    box-sizing: border-box;

    > * {
      /* set up icon animation */
      transition: transform 100ms ease-in-out, fill 100ms ease-in-out;
      fill: ${props => props.theme.colors.input.onWhite};
    }

    &:hover {
      > * {
        transform: scale(1.3);
        fill: initial;
      }
    }
  `,
  OptionsContainer: styled(Animations.FadeDown)`
    position: absolute;
    margin: 0 auto;
    width: 100%;
    /*
      Need to use !important because FadeDown->animation sometimes (randomly) gets added after OptionsContainer rules.
      That causes height to be set back to 100% (css overriding rules)
      Related issue: #17006 
    */
    height: initial !important;
    box-sizing: border-box;

    background-color: ${props => props.theme.colors.white};
    border: 1px solid ${props => props.theme.colors.input.onWhite};
    border-top: none;
    z-index: 3;
    padding: ${props => props.theme.spacing.xsm} 0;

    box-shadow: 5px 20px 50px 0 rgba(0, 0, 0, 0.2);

    max-height: 225px;
    overflow-y: auto;
    overflow-x: hidden;
    ${Scrollbar};
  `,
  Option: styled.div<{ selected?: boolean }>`
    font-size: 14px;
    line-height: 26px;

    transition: background-color 100ms ease-in-out 0s;
    padding: ${props => props.theme.spacing.xsm} ${props => props.theme.spacing.sm};
    user-select: none;
    font-weight: bold;
    ${props =>
      props.selected
        ? `color: ${props.theme.colors.text.onWhitePrimary};`
        : `color: ${props.theme.colors.text.onWhite};`}

    &:hover {
      color: ${props => props.theme.colors.text.onWhitePrimary};
    }
  `
};
