import { helpers } from '@components';
import styled from 'styled-components';

export default {
  Container: styled.span`
    ${helpers};
    display: flex;
    align-items: center;
    justify-content: center;
  `
};
