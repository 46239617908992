import { Flex } from '@components';
import styled from 'styled-components';
import { ControlContainerProps } from '.';

export const getIconContent = (iconPath: string, iconSize?: string) => {
  return `
    content: '';
    display: inline-block;
    background: url(/static/icons/${iconPath}.svg) no-repeat center center;
    min-width: ${iconSize || '16px'};
    min-height: ${iconSize || '16px'};
    background-size: ${iconSize || '16px'} ${iconSize || '16px'};
  `;
};

export default {
  TextContainer: styled(Flex)<ControlContainerProps>`
    ${props => (props.leftIcon ? `:before { ${getIconContent(props.leftIcon, props.iconSize)} }` : '')}
    ${props => (props.rightIcon ? `:after { ${getIconContent(props.rightIcon, props.iconSize)} }` : '')}

    ${props =>
      props.activeOnHover &&
      `
      cursor: pointer;
      @media (hover: hover) {
        :hover {
          > span {
            color: ${props.theme.colors.error};
          }
          ${
            props.leftIcon
              ? `:before { ${getIconContent(props.leftActiveIcon || props.leftIcon, props.iconSize)} }`
              : ''
          }
          ${
            props.rightIcon
              ? `:after { ${getIconContent(props.rightActiveIcon || props.rightIcon, props.iconSize)} }`
              : ''
          }
      }
    }`}

    ${props =>
      props.underlineOnHover &&
      `
      @media (hover: hover) {
        :hover {
          > span {
            text-decoration: underline;
          }
        }
      }
    `}

    ${props =>
      props.leftSpacing && `> span { margin-left: ${props.theme.spacing[props.leftSpacing] || props.leftSpacing}; }`}

      ${props =>
      props.rightSpacing &&
      `> span { margin-right: ${props.theme.spacing[props.rightSpacing] || props.rightSpacing}; }`}

    ${props =>
      props.active &&
      `
      > span {
        color: ${props.theme.colors.error};
      }
      ${props.leftIcon ? `:before { ${getIconContent(props.leftActiveIcon || props.leftIcon)} }` : ''}
      ${props.rightIcon ? `:after { ${getIconContent(props.rightActiveIcon || props.rightIcon)} }` : ''}
      `}

    > span {
      position: relative;
      top: 1.5px;
    }
  `
};
