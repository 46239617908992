import styled from 'styled-components';
import { AppTheme } from 'src/components/Theme';
import helpers, { HelperProps } from '../helpers';

export type TileProps = HelperProps & {
  background?: keyof AppTheme['colors']['text'];
  color?: keyof AppTheme['colors']['text'];
  full?: boolean;
  textAlign?: string;
  variant?: 'flat' | 'raised' | 'x-Large' | 'resting' | 'no-shadow';
  vertical?: boolean;
  width?: string;
  centeredContent?: boolean;
};

const getShadowVariant = (variant?: TileProps['variant']): string => {
  if (variant === 'raised') return '10px 15px 80px 0 rgba(0,0,0,0.1);';
  if (variant === 'x-Large') return '0 96px 126px 0 rgba(0,0,0,0.26);';
  if (variant === 'resting') return '0 0 4px 0 rgba(0,0,0,0.1);';
  if (variant === 'no-shadow') return 'initial;';
  return '1px 1px 1px 1px rgba(0,0,0,0.01);';
};

const Tile = styled.div<TileProps>`
  padding: ${props => props.theme.spacing.default};
  box-shadow: ${props => getShadowVariant(props.variant)};
  background-color: ${props =>
    props.background ? props.theme.colors.text[props.background] : props.theme.colors.white};
  box-sizing: border-box;
  color: ${props => (props.color ? props.theme.colors.text[props.color] : props.theme.colors.text.onWhite)};
  ${props => (props.full ? 'width: 100%; height: 100%;' : '')}
  ${props => (props.width ? `max-width: ${props.width};` : '')}
  ${props => (props.vertical ? 'display: flex;' : '')}
  ${props => (props.vertical ? 'flex-direction: column;' : '')}
  ${props => (props.textAlign ? `text-align: ${props.textAlign};` : '')}
  ${helpers};

  ${props => (props.centeredContent ? 'display:flex; align-items: center; justify-content: center;' : '')};
`;

export default Tile;
