import React from 'react';
import * as CSS from 'csstype';
import { useIntl } from 'react-intl';
import Text, { TextProps } from 'src/components/Text';
import Tooltip, { TooltipProps } from 'src/components/Tooltip';
import TranslationEditButton from '../TranslationEditButton';
import { SpacingName } from 'src/components/Theme';
import { SVGIcon } from '@components';

export enum TooltipIconVariants {
  white = 'white'
}

type TooltipHelpTextProps = React.PropsWithChildren<
  TextProps & {
    content?: string;
    contentTranslationKey?: string;
    iconColor?: string;
    iconOpacityClosed?: string;
    iconSize?: string;
    iconVariant?: TooltipIconVariants;
    variant?: string;
    anchor?: TooltipProps['anchor'];
    tooltipWidth?: number;
    innerRender?: (content?: string) => React.ReactElement;
    contentPadding?: SpacingName;
    contentWeight?: CSS.FontWeightProperty;
    contentSize?: string;
    //In case onClick is provided, it doesn't display the tooltip at all.
    //Instead provided onClick is triggered
    onClick?: () => void;
  }
>;

const DEFAULT_ICON_SIZE = '24px';

const TooltipHelpText: React.FC<TooltipHelpTextProps> = props => {
  const intl = useIntl();

  let content = '';
  if (props.content) {
    content = props.content;
  } else if (props.contentTranslationKey) {
    const formattedMessage = intl.formatMessage({ id: props.contentTranslationKey });
    // Checkking for missing translation content
    if (formattedMessage != props.contentTranslationKey) {
      content = formattedMessage;
    }
  }

  const hasTooltipContent = !!content.length;
  const TextComponent = Text[props.variant || 'SmallerParagraph'];
  const svgOpenIconSrc = `/static/icons/info-full${props.iconVariant ? `-${props.iconVariant}` : ''}.svg`;
  const svgClosedIconSrc = `/static/icons/infoLine${props.iconVariant ? `-${props.iconVariant}` : ''}.svg`;

  const { onClick, ...restProps } = props;

  return (
    <TextComponent
      {...restProps}
      onClick={(e: any) => {
        if (onClick) {
          onClick();
          e.stopPropagation();
        }
      }}
    >
      {props.children}{' '}
      {hasTooltipContent && (
        <Tooltip
          tooltipWidth={props.tooltipWidth || 200}
          type={onClick ? 'disabled' : 'hover'}
          anchor={props.anchor || 'RIGHT_CENTER'}
          padding={props.contentPadding}
          innerRender={
            props.innerRender
              ? () => (props.innerRender ? props.innerRender(content) : <></>)
              : () => (
                  <Text.SmallerParagraph size={props.contentSize} align="left" weight={props.contentWeight || 'bold'}>
                    {content}
                  </Text.SmallerParagraph>
                )
          }
        >
          {isOpen =>
            isOpen ? (
              <SVGIcon color={props.iconColor} src={svgOpenIconSrc} size={props.iconSize || DEFAULT_ICON_SIZE} />
            ) : (
              <SVGIcon
                color={props.iconColor}
                opacity={props.iconOpacityClosed}
                src={svgClosedIconSrc}
                size={props.iconSize || DEFAULT_ICON_SIZE}
              />
            )
          }
        </Tooltip>
      )}
      {props.contentTranslationKey && <TranslationEditButton translationKey={props.contentTranslationKey} />}
    </TextComponent>
  );
};

export default TooltipHelpText;
