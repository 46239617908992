import React, { FC } from 'react';
import { HelperProps } from 'src/components/helpers';
import Text, { TextProps } from 'src/components/Text';
import Tooltip, { TooltipProps } from 'src/components/Tooltip';
import TooltipSelectStyles from './styles';
import { DataAnalytics } from 'src/utils/dataAnalytics';
import { AppTheme, SpacingName } from 'src/components/Theme';
import * as CSS from 'csstype';
import { AnchorEnum } from 'react-laag';
import { ControlButton } from '@components';
import { ControlButtonProps } from '../ControlButton';

export type TooltipOption = {
  text: React.ReactNode;
  value?: JSX.Element | string;
  onClick?: () => void;
  selected?: boolean;
};

type TooltipSelectProps = React.PropsWithChildren<
  {
    options: TooltipOption[];
    paddingOnlyInItems?: boolean;
    containerVerticalPadding?: SpacingName;
    containerHorizontalPadding?: SpacingName;
    itemVerticalPadding?: SpacingName;
    itemHorizontalPadding?: SpacingName;
    itemFontSize?: string;
    highlightItemsOnHover?: boolean;
    highlightItemsTextOnHover?: boolean;
    type?: TooltipProps['type'];
    tooltipWidth?: number;
    minWidth?: number;
    variant?: keyof typeof Text;
    standardColor?: keyof AppTheme['colors']['text'];
    rightIcon?: string;
    rightActiveIcon?: string;
    buttonTextWeight?: CSS.FontWeightProperty;
    tooltipAnchor?: AnchorEnum;
    hideArrow?: boolean;
    endInnerComponent?: React.ReactNode;
    itemsGap?: SpacingName;
    triggerOffset?: number;
    leftOffset?: number;
    weight?: CSS.FontWeightProperty;
    dataTestId?: string;
  } & ControlButtonProps &
    HelperProps &
    DataAnalytics
>;

const TooltipSelect: React.FC<TooltipSelectProps> = props => {
  // Since the `Text[...]`-component does not have an `onClick`-prop which is needed
  // below and is "support" by browsers on every element we added `any` for the moment...
  const TextComponent: FC<TextProps & any> = Text[props.variant || 'SmallerParagraph'];
  return (
    <TooltipSelectStyles.Container {...props}>
      <Tooltip
        tooltipWidth={props.tooltipWidth}
        minWidth={props.minWidth}
        type={props.type || 'click'}
        noPadding={props.paddingOnlyInItems}
        containerVerticalPadding={props.containerVerticalPadding}
        containerHorizontalPadding={props.containerHorizontalPadding}
        anchor={props.tooltipAnchor || 'BOTTOM_RIGHT'}
        hideArrow={props.hideArrow}
        triggerOffset={props.triggerOffset}
        leftOffset={props.leftOffset}
        innerRender={(_, close) => (
          <TooltipSelectStyles.ItemsContainer maxHeight="456px">
            {props.options.map((x, i) => (
              <TooltipSelectStyles.TooltipItem
                highlightOnHover={props.highlightItemsOnHover}
                highlightTextOnHover={props.highlightItemsTextOnHover}
                padding={props.paddingOnlyInItems ? 'sm' : 'none'}
                verticalPadding={props.itemVerticalPadding}
                key={i}
                /* Might be used by GTM */
                data-value={x?.value?.toString() || x?.text?.toString()}
                onClick={() => {
                  close();
                  x.onClick?.();
                }}
                data-analytics-id={props['data-analytics-id']}
              >
                <TextComponent
                  weight={props.weight || 'bold'}
                  size={props.itemFontSize}
                  color={x.selected ? 'onWhitePrimary' : props.standardColor ? props.standardColor : undefined}
                  marginBottom={
                    i + 1 === props.options.length
                      ? undefined
                      : props.paddingOnlyInItems
                      ? 'none'
                      : props.itemsGap || 'sm'
                  }
                >
                  {x.text}
                </TextComponent>
              </TooltipSelectStyles.TooltipItem>
            ))}
            {props.endInnerComponent}
          </TooltipSelectStyles.ItemsContainer>
        )}
      >
        {isOpen => (
          <TooltipSelectStyles.TooltipItem data-test-id={props.dataTestId}>
            <ControlButton
              variant="Span14_22"
              rightIcon={props.rightIcon || 'semiArrowDownPrimary'}
              rightActiveIcon={props.rightActiveIcon || props.rightIcon || 'semiArrowUpPrimary'}
              rightSpacing="xxsm"
              textProps={{
                weight: 'bold',
                color: props.standardColor || 'onWhitePrimary'
              }}
              active={isOpen}
              {...props}
            >
              {props.children}
            </ControlButton>
          </TooltipSelectStyles.TooltipItem>
        )}
      </Tooltip>
    </TooltipSelectStyles.Container>
  );
};

TooltipSelect.defaultProps = {
  highlightItemsOnHover: false,
  highlightItemsTextOnHover: true
};

export default TooltipSelect;
