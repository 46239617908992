import React from 'react';
import Styles from './styles';
import ReactDrawer from 'react-motion-drawer';
import { Flex, LocalizedText, Text } from '@components';

export type DrawerProps = React.PropsWithChildren<{
  showDrawer: boolean;
  handleChange: (open: boolean) => void;
  handleClose?(): void;
  right?: boolean;
  width?: number | string;
  //Default: true
  closeButton?: boolean;
  noPadding?: boolean;
  title?: string;
}>;

const Drawer: React.FC<DrawerProps> = props => {
  return (
    <Styles.Container>
      {process.browser && (
        <ReactDrawer
          width={props.width || 400}
          open={props.showDrawer}
          onChange={(open: boolean) => {
            if (!open) {
              props.handleClose?.();
            }
            props.handleChange(open);
          }}
          right={props.right}
        >
          <Styles.ContentContainer style={props.noPadding ? { padding: 0 } : undefined}>
            {props.closeButton !== false && (
              <Flex justifyContent="space-between" marginBottom="default" alignItems="center">
                <Text.Span24_28_bold>{props.title && <LocalizedText id={props.title} />}</Text.Span24_28_bold>
                <Styles.Close
                  onClick={() => {
                    props.handleClose?.();
                    props.handleChange(false);
                  }}
                >
                  <img src="/static/icons/cross.svg" />
                </Styles.Close>
              </Flex>
            )}
            <Styles.Content>{props.children}</Styles.Content>
          </Styles.ContentContainer>
        </ReactDrawer>
      )}
    </Styles.Container>
  );
};

export default Drawer;
