import React from 'react';
import { TableRowType } from '.';

const getFilledState = (rows: TableRowType[], isSelected = false) =>
  rows.reduce((acc, row) => ({ ...acc, [row.id]: isSelected }), {});

export const useTableSelection = (rows: TableRowType[]) => {
  const [selectedRowMap, setSelectedRowMap] = React.useState<Record<number, boolean>>({});

  React.useEffect(() => {
    setSelectedRowMap(getFilledState(rows, false));
  }, [rows]);

  const toggleRowSelection = (id: number) => {
    setSelectedRowMap(rowMap => ({ ...rowMap, [id]: !rowMap[id] }));
  };

  const hasSelectedRows = Object.values(selectedRowMap).some(Boolean);
  const hasAllSelectedRows = Object.values(selectedRowMap).every(Boolean);
  const toggleAllSelection = () => {
    setSelectedRowMap(getFilledState(rows, !hasSelectedRows));
  };
  const selectedRowIds = rows.filter(row => selectedRowMap[row.id]).map(({ id }) => id);
  return {
    selectedRowIds,
    selectedRowMap,
    toggleRowSelection,
    hasSelectedRows,
    toggleAllSelection,
    hasAllSelectedRows
  };
};
