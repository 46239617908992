import styled from 'styled-components';

export default {
  Container: styled.div<{ fixed?: boolean; topPos: number; topPositionInAbsoluteMode?: number }>`
    position: ${({ fixed }) => (fixed ? 'fixed' : 'absolute')};
    ${props =>
      props.fixed
        ? `top: ${props.topPos}px;`
        : props.topPositionInAbsoluteMode !== undefined
        ? `top: ${props.topPositionInAbsoluteMode}px;`
        : ''};
    z-index: 1;
  `
};
