import { ImageLoader, Flex } from '@components';
import React from 'react';
import { useDetectClickOutside } from 'src/hooks/useDetectClickOutside';
import ControlButton, { ControlButtonProps } from '../ControlButton';
import Styles from './styles';

type ControlDropdownProps = {
  selected?: boolean;
  handleClick?(): void;
  handleClose?(): void;
  text: React.ReactNode;
} & ControlButtonProps;

const ControlDropdown: React.FC<ControlDropdownProps> = ({
  children,
  text,
  selected,
  handleClick,
  handleClose,
  ...props
}) => {
  const outisideClickRef = useDetectClickOutside({
    onTriggered: handleClose
  });
  return (
    <Styles.Container fullHeight ref={selected ? outisideClickRef : null}>
      <Flex alignItems="center" onClick={handleClick}>
        <ControlButton
          activeOnHover
          active={selected}
          rightIcon="semiArrowDownGray"
          rightActiveIcon={selected ? 'semiArrowUpPrimary' : 'semiArrowDownPrimary'}
          rightSpacing="xxsm"
          {...props}
        >
          {text}
        </ControlButton>
      </Flex>
      <ImageLoader icons={['semiArrowUpPrimary']} />
      <Styles.DropdownContent isActive={selected}>{children}</Styles.DropdownContent>
    </Styles.Container>
  );
};

export default React.memo(ControlDropdown);
