import React from 'react';
import { ButtonProps, default as Button, default as ButtonStyles } from '../Button';
import { DataAnalytics } from '../../utils/dataAnalytics';
import { Spinner } from '@components';

type LoaderButtonProps = React.ButtonHTMLAttributes<unknown> &
  React.PropsWithChildren<
    {
      isLoading: boolean;
      size?: keyof typeof Button;
    } & ButtonProps &
      DataAnalytics
  >;

const LoaderButton: React.FC<LoaderButtonProps> = props => {
  const Component = ButtonStyles[props.size || 'xxLarge'];

  return (
    <Component
      {...props}
      disabled={props.disabled || props.isLoading}
      data-analytics-id={props['data-analytics-id']}
      data-test-id={props['data-test-id']}
    >
      {props.isLoading ? <Spinner.Default color="onPrimary" /> : props.children}
    </Component>
  );
};

export default React.memo(LoaderButton);
