import React from 'react';
import TileTitleUrlStyles from './styles';
import TextIcon from '../TextIcon';
import Breakpoints from '../Breakpoints';
import { Flex, Text, Loader, Tile } from '@components';

type TileTitleUrlProps = {
  title: string;
  url: () => React.ReactElement;
  icon: (active: boolean) => React.ReactElement;
  loading?: boolean;
};

const TileTitleUrl: React.FC<TileTitleUrlProps> = props => {
  return (
    <TileTitleUrlStyles.Container>
      {props.loading ? (
        <Loader width="100%" considerSpacing />
      ) : (
        <Tile fullHeight>
          <Flex alignItems="center" justifyContent="space-between" fullHeight>
            <TileTitleUrlStyles.ContentContainer>
              <Breakpoints.LaptopAndUp>
                <TextIcon leftIconComponent={props.icon} spacing="default" variant="H6">
                  {props.title}
                </TextIcon>
              </Breakpoints.LaptopAndUp>
              <Breakpoints.TabletAndDown>
                <Text.H6>{props.title}</Text.H6>
              </Breakpoints.TabletAndDown>
            </TileTitleUrlStyles.ContentContainer>
            {props.url()}
          </Flex>
        </Tile>
      )}
    </TileTitleUrlStyles.Container>
  );
};

export default React.memo(TileTitleUrl);
