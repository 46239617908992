import styled from 'styled-components';
import { LaptopAndUp, TabletAndUp } from '../stylingUtils';

export default {
  Container: styled.div`
    /* Mobile has 4 columns, so 4 = fullwidth */
    grid-column: span 4;

    /* Tablet */
    @media (min-width: ${props => props.theme.breakpoints.small}) {
      /* Tablet has 8 columns, so 8 = fullwidth */
      grid-column: span 4;
    }

    /* Laptop */
    @media (min-width: ${props => props.theme.breakpoints.large}) {
      grid-column: span 6;
    }

    a {
      text-decoration: none;
    }

    > * {
      padding: ${props => props.theme.spacing.default} !important;

      ${props => LaptopAndUp(props)} {
        padding: ${props => props.theme.spacing.xmd} !important;
      }
    }
  `,
  ContentContainer: styled.div`
    max-width: 300px;

    ${props => TabletAndUp(props)} {
      max-width: 70%;
    }
  `
};
