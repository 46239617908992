import { css } from 'styled-components';

export default css`
  /* width */
  ::-webkit-scrollbar {
    height: ${props => props.theme.spacing.xxsm};
    width: ${props => props.theme.spacing.xxsm};
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: ${props => props.theme.colors.disabled};
    border-radius: ${props => props.theme.spacing.xxsm};
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: ${props => props.theme.colors.handler};
    border-radius: ${props => props.theme.spacing.xxsm};

    &:hover {
      background-color: ${props => props.theme.colors.black};
    }
  }
`;
