import React from 'react';
import MarketLoadingStyles from './styles';
import { Text, Loader } from '@components';

const MarketLoading = () => {
  return (
    <MarketLoadingStyles.Container>
      <Text.LargeTitle marginTop="xlg">
        <Loader padding="default" width="100%" height="300px" />
      </Text.LargeTitle>
      <Text.LargeTitle marginTop="default">
        <Loader padding="default" width="100%" />
      </Text.LargeTitle>
      <Text.LargeTitle marginTop="default">
        <Loader padding="default" width="100%" />
      </Text.LargeTitle>
    </MarketLoadingStyles.Container>
  );
};

export default MarketLoading;
