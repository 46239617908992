import styled from 'styled-components';
import { Flex } from '@components';

export default {
  PaginationContainer: styled(Flex).attrs({ justifyContent: 'center' })``,
  PaginationItem: styled(Flex).attrs({ justifyContent: 'center', alignItems: 'center' })<{
    selected?: boolean;
    disabled?: boolean;
  }>`
    min-width: 16px;
    padding: 0 12px;
    height: 40px;
    cursor: pointer;
    opacity: ${props => (props.disabled ? 0.4 : 1)};

    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.4px;
    color: ${props => (props.selected ? props.theme.colors.text.onBlack : props.theme.colors.text.onWhite)};

    background-color: ${props =>
      props.selected ? props.theme.colors.text.onWhiteSecondary : props.theme.colors.text.onBlack};

    :hover {
      background-color: ${props =>
        props.selected ? props.theme.colors.text.onWhite : props.theme.colors.lightGraySecondary};
    }
  `
};
