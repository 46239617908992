import styled from 'styled-components';

export default {
  Container: styled.div<{ open: boolean }>`
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 2147483010; /* Bigger than Intercom z-index (https://www.intercom.com/) */
    ${props => (!props.open ? 'display: none;' : '')}
    overflow-y: scroll;

    @media (min-width: ${props => props.theme.breakpoints.small}) {
      height: 100%;
    }
  `,
  Content: styled.div<{ fullscreen?: boolean }>`
    box-sizing: border-box;
    max-width: 100%;
    margin: 0px auto;
    height: 100%;

    @media (min-width: ${props => props.theme.breakpoints.small}) {
      ${props =>
        props.fullscreen
          ? `padding: ${props.theme.spacing.lg};
             max-width: ${props.theme.breakpoints.xlarge};`
          : `height: auto;
             max-width: 768px;`}
    }
  `,
  Header: styled.div<{ absolute?: boolean }>`
    display: flex;
    justify-content: flex-end;

    ${props =>
      props.absolute
        ? `
          box-sizing: border-box;
          position: absolute;
          top: 0;
          width: 100%;
          padding: ${props.theme.spacing.sm};`
        : ''}
  `,
  CloseIconContainer: styled.div`
    cursor: pointer;
    font-size: 30px;
    line-height: 24px;
    :hover {
      color: ${props => props.theme.colors.primary};
    }
  `,
  Inner: styled.div<{ absolute?: boolean; fullscreen?: boolean; marginTop?: string }>`
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0;
    height: 100%;

    ${props =>
      (props.absolute ? `> * { position: relative; }` : '') +
      (props.fullscreen
        ? `> * { width: 100%; }`
        : `@media (min-width: ${props.theme.breakpoints.small}) {
             margin: 10% auto;
             height: auto;
          }`)}

    ${props => props.marginTop && `margin-top: ${props.marginTop}!important;`};
  `
};
