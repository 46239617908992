import { IconDefinition, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { SpacingName } from '../../components/Theme';
import IconStyles from './styles';

type IconProps = {
  faIcon?: IconDefinition;
  faIconSize?: SizeProp;
  iconComponent?: (active: boolean) => React.ReactElement;
  isActive?: boolean;
  left?: boolean;
  paddingBottom?: SpacingName;
  spacing: SpacingName;
};

type FaIconProps = Required<Pick<IconProps, 'faIcon'>> & Omit<IconProps, 'iconComponent' | 'isActive'>;

type IconComponentProps = Required<Pick<IconProps, 'iconComponent'>> & Omit<IconProps, 'faIcon' | 'faIconSize'>;

const FaIcon: FC<FaIconProps> = ({ left, spacing, faIcon, faIconSize, paddingBottom = 'none' }) => (
  <IconStyles.Container
    paddingBottom={paddingBottom}
    marginLeft={!left ? spacing : undefined}
    marginRight={left ? spacing : undefined}
  >
    <FontAwesomeIcon icon={faIcon} size={faIconSize} />
  </IconStyles.Container>
);

const IconComponent: FC<IconComponentProps> = ({
  left,
  spacing,
  iconComponent,
  isActive = false,
  paddingBottom = 'none'
}) => (
  <IconStyles.Container
    paddingBottom={paddingBottom}
    marginLeft={!left ? spacing : undefined}
    marginRight={left ? spacing : undefined}
  >
    {iconComponent(isActive)}
  </IconStyles.Container>
);

const Icon: React.FC<IconProps> = ({
  faIcon,
  faIconSize,
  iconComponent,
  isActive,
  left,
  spacing,
  paddingBottom = 'none'
}) => (
  <>
    {faIcon && (
      <FaIcon paddingBottom={paddingBottom} left={left} spacing={spacing} faIcon={faIcon} faIconSize={faIconSize} />
    )}
    {iconComponent && (
      <IconComponent
        paddingBottom={paddingBottom}
        left={left}
        spacing={spacing}
        iconComponent={iconComponent}
        isActive={isActive}
      />
    )}
  </>
);

export default Icon;
