import React from 'react';
import { Flex } from '@components';
import Styles from './styles';

export type TablePaginationProps = {
  page: number; // start from 1, 2, 3..
  itemsPerPage: number;
  total: number;
  onPageChange(pageNumber: number): void;
  dataTestId?: string;
  disabled?: boolean;
};

// for page numbers > 7
const TableDottedPaginationItems: React.FC<TablePaginationProps> = props => {
  const { page, itemsPerPage, onPageChange, total, disabled } = props;
  const middlePageCount = 3;

  const amountOfPages = Math.ceil(total / itemsPerPage);
  const shouldUseRightDots = page + 2 < amountOfPages;
  const shouldUseLeftDots = page > 3;

  let middleLeftPage = page - 1;
  if (!shouldUseLeftDots) {
    middleLeftPage = 3;
  }
  if (!shouldUseRightDots) {
    middleLeftPage = amountOfPages - 4;
  }
  return (
    <>
      <Styles.PaginationItem onClick={() => onPageChange(1)} disabled={disabled} selected={page === 1}>
        1
      </Styles.PaginationItem>
      {shouldUseLeftDots ? (
        <Styles.PaginationItem onClick={() => onPageChange(Math.max(middleLeftPage - 2, 2))}>...</Styles.PaginationItem>
      ) : (
        <Styles.PaginationItem onClick={() => onPageChange(2)} disabled={disabled} selected={page === 2}>
          2
        </Styles.PaginationItem>
      )}
      {Array.from({ length: middlePageCount }, (_, i) => {
        const currentPage = middleLeftPage + i;
        return (
          <Styles.PaginationItem
            key={i}
            selected={page === currentPage}
            onClick={() => onPageChange(currentPage)}
            disabled={disabled}
          >
            {currentPage}
          </Styles.PaginationItem>
        );
      })}
      {shouldUseRightDots ? (
        <Styles.PaginationItem onClick={() => onPageChange(Math.min(middleLeftPage + 4, amountOfPages - 1))}>
          ...
        </Styles.PaginationItem>
      ) : (
        <Styles.PaginationItem
          onClick={() => onPageChange(amountOfPages - 1)}
          disabled={disabled}
          selected={page === amountOfPages - 1}
        >
          {amountOfPages - 1}
        </Styles.PaginationItem>
      )}
      <Styles.PaginationItem
        onClick={() => onPageChange(amountOfPages)}
        disabled={disabled}
        selected={page === amountOfPages}
      >
        {amountOfPages}
      </Styles.PaginationItem>
    </>
  );
};

export const TablePagination: React.FC<TablePaginationProps> = props => {
  const { page, itemsPerPage, onPageChange, total, dataTestId, disabled } = props;
  const amountOfPages = Math.ceil(total / itemsPerPage);

  if (amountOfPages < 2) {
    return null;
  }

  const onPrevClick = () => onPageChange(page - 1);
  const onNextClick = () => onPageChange(page + 1);

  const useDotsCondition = amountOfPages > 7;
  return (
    <Styles.PaginationContainer marginTop="lg" data-test-id={dataTestId ? `${dataTestId}-pagintaion` : undefined}>
      <Flex alignItems="center" gap="xxsm">
        <Styles.PaginationItem onClick={onPrevClick} disabled={disabled || page === 1}>
          <img src="/static/icons/semiArrowLeft.svg" />
        </Styles.PaginationItem>
        {useDotsCondition ? (
          <TableDottedPaginationItems {...props} />
        ) : (
          Array.from({ length: amountOfPages }, (_, i) => {
            const currentPage = i + 1;
            return (
              <Styles.PaginationItem
                key={i}
                selected={page === currentPage}
                onClick={() => onPageChange(currentPage)}
                disabled={disabled}
              >
                {currentPage}
              </Styles.PaginationItem>
            );
          })
        )}
        <Styles.PaginationItem onClick={onNextClick} disabled={disabled || page === amountOfPages}>
          <img src="/static/icons/semiArrowRight.svg" />
        </Styles.PaginationItem>
      </Flex>
    </Styles.PaginationContainer>
  );
};
