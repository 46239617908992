import React from 'react';
import Styles from './styles';
import { DynamicWrapper } from '@utils/reactUtils';
import { AppLink, ImageLoader, Text, FlexProps, TextProps } from '@components';
import { SpacingName } from '../Theme';

export type ControlContainerProps = {
  leftIcon?: string;
  leftActiveIcon?: string;
  rightIcon?: string;
  rightActiveIcon?: string;
  iconSize?: string;
  leftSpacing?: SpacingName | string;
  rightSpacing?: SpacingName | string;
  activeOnHover?: boolean;
  underlineOnHover?: boolean;
  active?: boolean;
  href?: string;
  //Only used in case href is provided.
  //Default: undefined
  target?: string;
  //Used for internal links.
  //Default: undefined
  external?: boolean;
};

export type ControlButtonProps = React.PropsWithChildren<
  { variant?: keyof typeof Text; textProps?: TextProps & React.HTMLProps<HTMLParagraphElement> } & FlexProps &
    ControlContainerProps &
    React.HTMLAttributes<HTMLDivElement>
>;

const ControlButton: React.FC<ControlButtonProps> = ({
  children,
  variant,
  leftIcon,
  leftActiveIcon,
  rightIcon,
  rightActiveIcon,
  leftSpacing = leftIcon ? 'xsm' : undefined,
  textProps,
  ...rest
}) => {
  const TextComponent: React.FC<TextProps> = Text[variant || 'Span14_22'];
  const icons = [leftIcon, leftActiveIcon, rightIcon, rightActiveIcon].filter(Boolean) as string[];
  return DynamicWrapper(
    rest.href
      ? (children: React.ReactElement) => (
          <AppLink href={rest.href || ''} target={rest.target} external={rest.external} rel="noopener noreferrer">
            {children}
          </AppLink>
        )
      : (children: React.ReactElement) => <React.Fragment>{children}</React.Fragment>,
    <Styles.TextContainer
      alignItems="center"
      fullHeight
      leftSpacing={leftSpacing}
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      leftActiveIcon={leftActiveIcon}
      rightActiveIcon={rightActiveIcon}
      {...rest}
    >
      {Boolean(icons.length) && <ImageLoader icons={icons} />}
      <TextComponent {...textProps}>{children}</TextComponent>
    </Styles.TextContainer>
  );
};

export default React.memo(ControlButton);
