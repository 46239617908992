import * as CSS from 'csstype';
import styled, { css } from 'styled-components';
import helpers, { HelperProps } from '../helpers';
import { DesktopAndUp, LaptopAndUp, TabletAndUp } from '../stylingUtils';
import { AppTheme } from '../Theme';

type LinkProps = {
  underlinedByDefault?: boolean;
  underlineOnHover?: boolean;
  highlightedByDefault?: boolean;
  highlightOnHover?: boolean;
  variant?: 'standard' | 'no-underline' | 'functional-only';
  disabled?: boolean;
  disabledColor?: keyof AppTheme['colors']['text'];
};

export type TextProps = HelperProps & {
  size?: string;
  weight?: CSS.FontWeightProperty;
  color?: keyof AppTheme['colors']['text'];
  align?: CSS.TextAlignProperty;
  decoration?: CSS.TextDecorationProperty<unknown>;
  whiteSpace?: CSS.WhiteSpaceProperty;
  wordWrap?: CSS.WordWrapProperty;
  inline?: boolean;
  lineHeight?: CSS.LineHeightProperty<string>;
  letterSpacing?: CSS.LetterSpacingProperty<string>;
  numberOfLines?: number;
  height?: CSS.HeightProperty<string>;
  wordBreak?: CSS.WordBreakProperty;
};

export const Text = css<TextProps>`
  ${helpers};
  color: ${props => (props.color ? props.theme.colors.text[props.color] : props.theme.colors.text.onWhite)};
  ${props => (props.weight ? `font-weight: ${props.weight};` : '')};
  ${props => (props.align ? `text-align: ${props.align};` : '')};
  ${props => (props.decoration ? `text-decoration: ${props.decoration};` : '')};
  ${props => (props.whiteSpace ? `white-space: ${props.whiteSpace};` : 'white-space: normal;')};
  ${props => (props.wordWrap ? `word-wrap: ${props.whiteSpace};` : '')};
  ${props => (props.inline ? `display: inline-block;` : '')};
  ${props => (props.letterSpacing ? `letter-spacing: ${props.letterSpacing};` : '')};
  ${props => (props.height ? `height: ${props.height};` : '')};
  ${props => (props.wordBreak ? `word-break: ${props.wordBreak};` : '')};
  ${props =>
    props.numberOfLines
      ? `
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: ${props.numberOfLines};
      line-clamp: ${props.numberOfLines};
  `
      : ''};
  a {
    color: ${props => (props.color ? props.theme.colors.text[props.color] : props.theme.colors.text.onWhite)};
  }

  p {
    margin-block-start: 0;
  }

  img {
    vertical-align: middle;
    margin-top: -3px;
  }

  transition: color 100ms ease-out;
  box-sizing: border-box;
`;

export default {
  /**
   * Huge <H1 /> text component with:
   * - font-size: 120px
   * - line-height: 120px
   */
  HugeTitle: styled.h1<TextProps>`
    margin: 0;
    ${Text};
    font-size: ${props => (props.size ? props.size : '100px')};
    line-height: ${props => (props.lineHeight ? props.lineHeight : '120px')};
  `,
  /**
   * Huge <H1 /> text component with:
   * - font-size: 120px
   * - line-height: 120px
   */
  HugeTitleResponsive: styled.h1<TextProps>`
    margin: 0;
    ${Text};
    font-size: 46px;
    line-height: 38px;

    ${props => TabletAndUp(props)} {
      font-size: 82px;
      line-height: 92px;
    }

    ${props => LaptopAndUp(props)} {
      font-size: 100px;
      line-height: 120px;
    }
  `,
  /**
   * xx-Large <H1 /> text component with:
   * - font-size: 86px
   * - line-height: 80px
   */
  xxLargeTitle: styled.h1<TextProps>`
    margin: 0;
    ${Text};
    font-size: ${props => (props.size ? props.size : '86px')};
    line-height: 80px;
  `,
  /**
   * x-Large <H1 /> text component with:
   * - font-size: 68px
   * - line-height: 68px
   */
  xLargeTitle: styled.h1<TextProps>`
    margin: 0;
    ${Text};
    font-size: ${props => (props.size ? props.size : '68px')};
    line-height: 68px;
  `,
  /**
   * Large <H1 /> text component with:
   * - font-size: 60px
   * - line-height: 66px
   */
  LargeTitle: styled.h1<TextProps>`
    margin: 0;
    ${Text};
    font-size: ${props => (props.size ? props.size : '60px')};
    line-height: 66px;
  `,
  /**
   * Large <H1 /> text component with:
   * - font-size: 60px
   * - line-height: 66px
   */
  LargeTitleResponsive: styled.h1<TextProps>`
    margin: 0;
    ${Text};
    font-size: 28px;
    line-height: 30px;

    ${props => TabletAndUp(props)} {
      font-size: 38px;
      line-height: 38px;
    }

    ${props => LaptopAndUp(props)} {
      font-size: 60px;
      line-height: 66px;
    }
  `,
  /**
   * Medium <H1 /> text component with:
   * - font-size: 48px
   * - line-height: 54px
   */
  MediumTitle: styled.h1<TextProps>`
    margin: 0;
    ${Text};
    font-size: ${props => (props.size ? props.size : '48px')};
    line-height: ${props => (props.lineHeight ? props.lineHeight : '54px')};
  `,
  /**
   * Medium <H1 /> text component with:
   * - font-size: 48px
   * - line-height: 54px
   */
  MediumTitleResponsive: styled.h1<TextProps>`
    margin: 0;
    ${Text};
    font-size: 24px;
    line-height: 28px;

    ${props => TabletAndUp(props)} {
      font-size: 30px;
      line-height: 32px;
    }

    ${props => LaptopAndUp(props)} {
      font-size: ${props => (props.size ? props.size : '48px')};
      line-height: 54px;
    }
  `,
  /**
   * SmallTitle <H1 /> text component with:
   * - font-size: 36px
   * - line-height: 40px
   */
  SmallTitle: styled.h1<TextProps>`
    margin: 0;
    ${Text};
    font-size: ${props => (props.size ? props.size : '36px')};
    line-height: 40px;
    letter-spacing: 0.5px;
  `,
  /**
   * A <H2 /> text component with:
   * - font-size: 32px
   * - line-height: 38px
   */
  H2: styled.h2<TextProps>`
    margin: 0;
    ${Text};
    font-size: ${props => (props.size ? props.size : '32px')};
    line-height: 38px;
  `,
  /**
   * A <H2 /> text component with:
   * - font-size: 32px
   * - line-height: 38px
   */
  H2Responsive: styled.h2<TextProps>`
    margin: 0;
    ${Text};
    font-size: 28px;
    line-height: 30px;

    ${props => LaptopAndUp(props)} {
      font-size: 32px;
      line-height: 38px;
    }
  `,
  /**
   * A <H3 /> text component with:
   * - font-size: 28px
   * - line-height: 34px
   */
  H3: styled.h3<TextProps>`
    margin: 0;
    ${Text};
    font-size: ${props => (props.size ? props.size : '28px')};
    line-height: 34px;
  `,
  /**
   * A <H3 /> text component with:
   * - font-size: 28px
   * - line-height: 34px
   */
  H3Responsive: styled.h3<TextProps>`
    margin: 0;
    ${Text};
    font-size: 22px;
    line-height: 38px;

    ${props => TabletAndUp(props)} {
      font-size: 28px;
      line-height: 38px;
    }
  `,
  /**
   * A <H4 /> text component with:
   * - font-size: 24px
   * - line-height: 24px
   */
  H4: styled.h4<TextProps>`
    margin: 0;
    ${Text};
    font-size: ${props => (props.size ? props.size : '24px')};
    line-height: ${props => (props.lineHeight ? props.lineHeight : '26px')};
  `,
  /**
   * A <H4 /> text component with:
   * - font-size: 24px
   * - line-height: 24px
   */
  H4Responsive: styled.h4<TextProps>`
    margin: 0;
    ${Text};
    font-size: 18px;
    letter-spacing: 0.5px;
    font-weight: bold;

    ${props => TabletAndUp(props)} {
      font-size: 22px;
      line-height: 24px;
      letter-spacing: 0.7px;
    }

    ${props => LaptopAndUp(props)} {
      font-size: 24px;
      line-height: 26px;
      letter-spacing: initial;
    }
  `,
  /**
   * A <H5 /> text component with:
   * - font-size: 20px
   * - line-height: 24px
   * - letter-spacing: 0.5px
   */
  H5: styled.h5<TextProps>`
    margin: 0;
    ${Text};
    font-size: ${props => (props.size ? props.size : '20px')};
    line-height: ${props => (props.lineHeight ? props.lineHeight : '24px')};
    letter-spacing: 0.5px;
  `,
  /**
   * A <H5 /> text component with:
   * - font-size: 20px
   * - line-height: 24px
   * - letter-spacing: 0.5px
   */
  H5Responsive: styled.h5<TextProps>`
    margin: 0;
    ${Text};
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;

    ${props => TabletAndUp(props)} {
      font-size: 18px;
    }

    ${props => LaptopAndUp(props)} {
      font-size: 20px;
    }
  `,
  /**
   * A <H6 /> text component with:
   * - font-size: 17px
   * - line-height: 24px
   */
  H6: styled.h6<TextProps>`
    margin: 0;
    ${Text};
    font-size: ${props => (props.size ? props.size : '17px')};
    line-height: 24px;
  `,
  /**
   * A <H6 /> text component with:
   * - font-size: 17px
   * - line-height: 24px
   */
  H6Responsive: styled.h6<TextProps>`
    margin: 0;
    ${Text};
    font-size: ${props => (props.size ? props.size : '13px')};
    line-height: 19px;

    ${props => LaptopAndUp(props)} {
      font-size: ${props => (props.size ? props.size : '15px')};
      line-height: 24px;
    }

    ${props => DesktopAndUp(props)} {
      font-size: ${props => (props.size ? props.size : '17px')};
    }
  `,
  /**
   * Paragraph <p /> text component with:
   * - font-size: 18px
   * - line-height: 32px
   * - letter-spacing: 0.2px
   */
  Paragraph: styled.p<TextProps>`
    margin: 0;
    ${Text};
    font-size: ${props => (props.size ? props.size : '18px')};
    line-height: 32px;
    letter-spacing: 0.2px;
  `,
  /**
   * Paragraph <p /> text component with:
   * - font-size: 18px
   * - line-height: 32px
   * - letter-spacing: 0.2px
   */
  ParagraphResponsive: styled.p<TextProps>`
    margin: 0;
    ${Text};
    font-size: 16px;
    line-height: 24px;

    ${props => LaptopAndUp(props)} {
      font-size: 18px;
      line-height: 32px;
      letter-spacing: 0.2px;
    }
  `,
  /**
   * SmallParagraph <p /> text component with:
   * - font-size: 17px
   * - line-height: 26px
   * - letter-spacing: 0.5px
   */
  SmallParagraph: styled.p<TextProps>`
    margin: 0;
    ${Text};
    font-size: ${props => (props.size ? props.size : '17px')};
    line-height: 26px;
    letter-spacing: 0.5px;
  `,
  /**
   * SmallParagraph <p /> text component with:
   * - font-size: 17px
   * - line-height: 26px
   * - letter-spacing: 0.5px
   */
  SmallParagraphResponsive: styled.p<TextProps>`
    margin: 0;
    ${Text};
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.5px;

    ${props => LaptopAndUp(props)} {
      font-size: 17px;
      line-height: 26px;
    }
  `,
  ListSmallResponsive: styled.li<TextProps>`
    margin: 0;
    ${Text};
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.5px;

    ${props => LaptopAndUp(props)} {
      font-size: 17px;
      line-height: 26px;
    }
  `,
  ListSmaller: styled.li<TextProps>`
    margin: 0;
    ${Text};
    font-size: ${props => (props.size ? props.size : '13px')};
    line-height: 22px;
    letter-spacing: 0.4px;

    ${props => LaptopAndUp(props)} {
      font-size: 13px;
      line-height: 22px;
    }
  `,
  /**
   * SmallerParagraph <p /> text component with:
   * - font-size: 13px
   * - line-height: 22px
   * - letter-spacing: 0.4px
   */
  SmallerParagraph: styled.p<TextProps>`
    margin: 0;
    ${Text};
    font-size: ${props => (props.size ? props.size : '13px')};
    line-height: 22px;
    letter-spacing: 0.4px;
  `,
  /**
   * SmallParagraph <p /> text component with:
   * - font-size: 17px
   * - line-height: 26px
   * - letter-spacing: 0.5px
   */
  SmallerParagraphResponsive: styled.p<TextProps>`
    margin: 0;
    ${Text};
    font-size: ${props => (props.size ? props.size : '13px')};
    line-height: 22px;
    letter-spacing: 0.4px;

    ${props => LaptopAndUp(props)} {
      font-size: 13px;
      line-height: 22px;
    }
  `,
  /**
   * Caption <div /> text component with:
   * - font-size: 12px
   * - line-height: 20px
   * - letter-spacing: 0.5px
   */
  Caption: styled.div<TextProps>`
    margin: 0;
    ${Text};
    font-size: ${props => (props.size ? props.size : '12px')};
    line-height: 20px;
    letter-spacing: 0.5px;
  `,
  /**
   * Tiny <div /> text component with:
   * - font-size: 11px
   * - line-height: 18px
   * - letter-spacing: 0.5px
   */
  Tiny: styled.div<TextProps>`
    margin: 0;
    ${Text};
    font-size: ${props => (props.size ? props.size : '11px')};
    line-height: 18px;
    letter-spacing: 0.5px;
  `,
  LabelResponsive: styled.label<TextProps>`
    margin: 0;
    ${Text};
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.5px;

    span {
      font-size: 13px;
      line-height: 22px;
      letter-spacing: 0.5px;
    }

    ${props => LaptopAndUp(props)} {
      font-size: 17px;
      line-height: 26px;

      span {
        font-size: 17px;
        line-height: 26px;
      }
    }
  `,
  /* 
    underlinedByDefault-Default: NO
    underlineOnHover - Default: YES
    highlightedByDefault- Default: YES
    highlightOnHover - Default:NO 
  */
  Link: styled.a<TextProps & LinkProps>`
    ${Text}
    ${props =>
      props.underlinedByDefault === true ? 'text-decoration: underline!important;' : 'text-decoration: none!important;'}

    font-size: ${props => props.size};
    cursor: pointer;

    ${props => (props.disabled ? ` pointer-events: none;` : '')}

    &:hover {
      text-decoration: ${props => (props.underlineOnHover !== false ? 'underline' : 'none')}!important;
      text-decoration-color: ${props => props.theme.colors.text.onWhitePrimary};
      color: ${props => props.highlightOnHover && props.theme.colors.text.onWhitePrimary};

      span,
      p,
      div {
        color: ${props => props.highlightOnHover && props.theme.colors.text.onWhitePrimary};
      }
    }
    color: ${props => props.highlightedByDefault && props.theme.colors.text.onWhitePrimary}!important;

    ${props => (props.disabled && !props.disabledColor ? ` opacity: 0.4;` : '')}
    ${props =>
      props.disabled && props.disabledColor ? ` color: ${props.theme.colors.text[props.disabledColor]};` : ''}
  `,
  VisualLink: styled.span<TextProps>`
    color: ${props => props.theme.colors.text.onWhitePrimary};
    cursor: pointer;
    ${props => (props.inline ? `display: inline-block;` : '')};
    font-size: ${props => (props.size ? props.size : '11px')};

    &:hover {
      text-decoration: underline;
    }
  `,
  Div: styled.div<TextProps>`
    ${Text};
    ${props => (props.size ? `font-size: ${props.size};` : '')};
  `,
  //An attempt for text standarization.
  //It is to prevent text topograpgy being defined each time per each component.
  //If none of the above texts cannot be used then please use one of the below styles or create a new one.
  Span16_26: styled.span<TextProps>`
    color: ${props => (props.color ? props.theme.colors.text[props.color] : props.theme.colors.text.onWhiteSecondary)};
    font-size: ${props => (props.size ? props.size : '16px')};
    line-height: 26px;
    font-weight: 400;
    ${Text};
  `,
  Span16_26_bold: styled.span<TextProps>`
    color: ${props => (props.color ? props.theme.colors.text[props.color] : props.theme.colors.text.onWhiteSecondary)};
    font-size: ${props => (props.size ? props.size : '16px')};
    line-height: 26px;
    font-weight: 700;
    ${Text};
  `,
  Span16_22: styled.span<TextProps>`
    ${Text};
    color: ${props => props.theme.colors.text.onWhiteSecondary};
    font-size: ${props => (props.size ? props.size : '16px')};
    line-height: 22px;
    font-weight: 400;
  `,
  Span18_32: styled.span<TextProps>`
    color: ${props => props.theme.colors.text.onWhiteSecondary};
    font-size: ${props => (props.size ? props.size : '18px')};
    line-height: 32px;
    font-weight: 400;
    ${Text};
  `,
  Span24_28_bold: styled.span<TextProps>`
    color: ${props => props.theme.colors.text.onWhite};
    font-size: ${props => (props.size ? props.size : '24px')};
    line-height: 28px;
    font-weight: 700;
  `,
  Span18_26_bold: styled.span<TextProps>`
    ${Text};
    color: ${props => props.theme.colors.text.onWhite};
    font-size: ${props => (props.size ? props.size : '18px')};
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0.5px;
  `,
  Span16_24_bold: styled.span<TextProps>`
    font-size: ${props => (props.size ? props.size : '16px')};
    font-weight: 700;
    line-height: 24px;
    ${Text};
  `,
  Span14_20: styled.span<TextProps>`
    font-size: ${props => (props.size ? props.size : '14px')};
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.005em;
    ${Text};
  `,
  Span14_22: styled.span<TextProps>`
    color: ${props => props.theme.colors.text.onWhiteSecondary};
    font-size: ${props => (props.size ? props.size : '14px')};
    line-height: 22px;
    ${Text};
  `,
  Span14_22_bold: styled.span<TextProps>`
    color: ${props => props.theme.colors.text.onWhiteSecondary};
    font-size: ${props => (props.size ? props.size : '14px')};
    font-weight: 700;
    line-height: 22px;
    ${Text};
  `,
  Span16_20: styled.span<TextProps>`
    color: ${props => props.theme.colors.text.onWhite};
    font-size: ${props => (props.size ? props.size : '16px')};
    line-height: 20px;
    ${Text};
  `,
  Span22_22_bold: styled.span<TextProps>`
    color: ${props => props.theme.colors.text.onWhite};
    font-size: ${props => (props.size ? props.size : '22px')};
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.4px;
    ${Text};
  `,
  Span18_22: styled.span<TextProps>`
    color: ${props => props.theme.colors.text.onWhite};
    font-size: ${props => (props.size ? props.size : '18px')};
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.4px;
    ${Text};
  `,
  Span18_28_bold: styled.span<TextProps>`
    color: ${props => props.theme.colors.text.onWhite};
    font-size: ${props => (props.size ? props.size : '18px')};
    font-weight: 700;
    line-height: 28px;
    ${Text};
  `,
  Span18_28: styled.span<TextProps>`
    color: ${props => props.theme.colors.text.onWhite};
    font-size: ${props => (props.size ? props.size : '18px')};
    font-weight: 400;
    line-height: 28px;
    ${Text};
  `,
  Span13_18_bold: styled.span<TextProps>`
    color: ${props => props.theme.colors.text.onWhite};
    font-size: ${props => (props.size ? props.size : '13px')};
    font-weight: 700;
    line-height: 18px;
    ${Text};
  `,
  Span13_18: styled.span<TextProps>`
    color: ${props => props.theme.colors.text.onWhite};
    font-size: ${props => (props.size ? props.size : '13px')};
    font-weight: 400;
    line-height: 18px;
    ${Text};
  `,
  Span14_24_bold: styled.span<TextProps>`
    color: ${props => props.theme.colors.text.onWhiteSecondary};
    font-size: ${props => (props.size ? props.size : '14px')};
    font-weight: 700;
    line-height: 24px;
    ${Text};
  `,
  Span20_24: styled.span<TextProps>`
    font-size: ${props => (props.size ? props.size : '20px')};
    line-height: 24px;
    font-weight: 400;
    letter-spacing: 0.2px;
    ${Text};
  `,
  Span17_24_bold: styled.span<TextProps>`
    font-size: ${props => (props.size ? props.size : '17px')};
    line-height: 24px;
    font-weight: 700;
    letter-spacing: 0.5px;
    ${Text};
  `
};
