import styled from 'styled-components';
import helpers from '../helpers';

const Grid = styled.div<{ gapSize?: string; columnWidth?: number }>`
  /* Mobile */
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: ${props => props.gapSize || '24px'};
  grid-row-gap: ${props => props.gapSize || '24px'};
  margin: 0 auto;

  & > div {
    ${({ columnWidth }) => columnWidth && `grid-column: span ${columnWidth}`}
  }

  /* Tablet(768+) */
  @media (min-width: ${props => props.theme.breakpoints.small}) {
    grid-template-columns: repeat(8, 1fr);
    grid-column-gap: ${props => props.gapSize || '32px'};
    grid-row-gap: ${props => props.gapSize || '32px'};
  }

  /* Laptop(1025+) */
  @media (min-width: ${props => props.theme.breakpoints.large}) {
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: ${props => props.gapSize || '28px'};
    grid-row-gap: ${props => props.gapSize || '28px'};
  }

  /* Desktop(1440+) */
  @media (min-width: ${props => props.theme.breakpoints.xlarge}) {
    grid-column-gap: ${props => props.gapSize || '36px'};
    grid-row-gap: ${props => props.gapSize || '36px'};
  }

  ${helpers};
`;

export default Grid;
