export const breakpoints = {
  mobile: 576,
  tablet: 768,
  laptop: 1025,
  desktop: 1440
};

const styleBreakpoints = {
  xsmall: `${breakpoints.mobile}px`,
  small: `${breakpoints.tablet}px`,
  medium: '992px',
  large: `${breakpoints.laptop}px`,
  xlarge: `${breakpoints.desktop}px`
};

const colors = {
  white: '#ffffff',
  black: '#3A3A3A',
  gray: '#444444',

  primary: '#D90223',
  secondary: '#647F8A',

  lightGray: '#FAFAFA',
  lightGraySecondary: '#E6E6E6',
  background: '#f6f7f8',
  handler: '#AAAAAA',

  disabled: '#DDE2E6',

  green: '#00BF6F',
  yellow: '#FCD85C',
  orange: '#FF9454',

  error: '#D3222A',

  /* Temporary HSCode bottom border color: */
  lightBorderGray: '#D2D2D2',

  /* Temporary HSCode, no products selected border: */
  lighterBorderGray: '#D3D3D3',

  tertiaryGreen: '#E9E4D3',
  tertiaryBlue: '#C6CEDB',

  notificationBlue: '#0E88E9',

  text: {
    onWhite: '#3A3A3A',
    onWhiteSecondary: '#545454',
    onWhiteAlternate: '#54636B',
    onWhiteLight: '#7F7F7F',
    onWhitePrimary: '#D90223',
    onWhiteDark: '#000',
    onWhiteDisabled: '#999fa3',
    onDarkLight: '#FFFFFF',
    onBlack: '#fff',
    onLightRed: '#D3222B',
    onWhiteAlternateTitle: '#2A3C46',
    onWhiteError: '#D3222A',
    onWhiteSuccess: '#00BF6F',
    onPrimary: '#fff',
    onGreyDark: '#333f48',
    lightGray: '#D2D2D2',
    lightGraySecondary: '#E6E6E6',
    darkGray: '#767676',
    onWhiteSelected: '#D3222A',
    grey: '#AAAAAA',
    inherit: 'inherit'
  },

  checkbox: {
    onWhite: '#AAAAAA'
  },

  input: {
    onWhite: '#AAAAAA',
    black: '#3A3A3A'
  },

  button: {
    transparent: 'transparent',
    white: '#ffffff',
    onWhite: '#D3222A',
    onWhiteHover: '#B31C23',
    onWhiteActive: '#90191e', //'#A11C22',
    onWhiteGrey: '#D2D2D2',
    onWhiteSuccess: '#00BF6F',
    grey: '#AAAAAA',
    primary: '#D90223'
  }
};

export type SpacingList = {
  /**
   * 0px
   */
  none: string;
  /**
   * 2px
   */
  xxxsm: string;
  /**
   * 4px
   */
  xxsm: string;
  /**
   * 8px
   */
  xsm: string;
  /**
   * 16px
   */
  sm: string;
  /**
   * 24px
   */
  default: string;
  /**
   * 32px
   */
  md: string;
  /**
   * 42px
   */
  xmd: string;
  /**
   * 48px
   */
  lg: string;
  /**
   * 52px
   */
  lgx: string;
  /**
   * 61px
   */
  lgxx: string;
  /**
   * 72px
   */
  xlg: string;
  /**
   * 96px
   */
  xxlg: string;
  /**
   * 144px
   */
  xxxlg: string;
  /**
   * 192px
   */
  xxxxlg: string;

  //[JU] We should remove completely this from the code along with it's references.
  //non standard
  3: string;
};

const spacing: SpacingList = {
  none: '0px',
  xxxsm: '2px',
  xxsm: '4px',
  xsm: '8px',
  sm: '16px',
  default: '24px',
  md: '32px',
  xmd: '42px',
  lg: '48px',
  lgx: '52px',
  lgxx: '61px',
  xlg: '72px',
  xxlg: '96px',
  xxxlg: '144px',
  xxxxlg: '192px',

  //[JU] We should remove completely this from the code along with it's references.
  //non standard
  3: '3px'
};

export type SpacingName = keyof SpacingList;

const Theme = {
  name: 'Default',
  colors,
  breakpoints: styleBreakpoints,
  deviceBreakpoints: breakpoints,
  spacing
};

export type AppTheme = typeof Theme;

export type TextColorName = keyof AppTheme['colors']['text'];
export type InputColorName = keyof AppTheme['colors']['input'];
export type ColorName = keyof AppTheme['colors'];

export default Theme;
