import { useCallback, useEffect, useMemo, useRef } from 'react';

interface Props {
  onTriggered?: (e: Event) => void;
  disableClick?: boolean;
  disableTouch?: boolean;
}

type EventConfigItem = [boolean | undefined, string, (e: Event) => void];

//Hook used to detect clicks outside a component. onTriggered function is triggered on `click` or `touch`` event.
export function useDetectClickOutside({ onTriggered, disableClick, disableTouch }: Props) {
  const ref = useRef(null);

  const clickOrTouchListener = useCallback(
    (e: Event) => {
      if (ref && ref.current) {
        const result = (ref.current as any).contains(e.target);
        if (!result) {
          onTriggered?.(e);
        }
      }
    },
    [ref.current, onTriggered]
  );

  const eventsConfig: EventConfigItem[] = useMemo(
    () => [
      [disableClick, 'click', clickOrTouchListener],
      [disableTouch, 'touchstart', clickOrTouchListener]
    ],
    [disableClick, disableTouch, clickOrTouchListener]
  );

  useEffect(() => {
    eventsConfig.map(eventConfigItem => {
      const [isDisabled, eventName, listener] = eventConfigItem;

      if (!isDisabled && onTriggered) {
        document.addEventListener(eventName, listener);
      }
    });

    return () => {
      eventsConfig.map(eventConfigItem => {
        const [isDisabled, eventName, listener] = eventConfigItem;

        if (!isDisabled && onTriggered) {
          document.removeEventListener(eventName, listener);
        }
      });
    };
  }, [eventsConfig]);

  return ref;
}
