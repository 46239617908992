import * as CSS from 'csstype';
import styled from 'styled-components';
import helpers, { HelperProps } from '../helpers';
import { SpacingName } from '../Theme';

export type FlexProps = HelperProps & {
  alignItems?: CSS.AlignItemsProperty;
  justifyContent?: CSS.JustifyContentProperty;
  alignSelf?: CSS.AlignSelfProperty;
  justifySelf?: CSS.JustifySelfProperty;
  vertical?: boolean;
  reverse?: boolean;
  gap?: SpacingName | boolean;
  mobile?: boolean;
  tablet?: boolean;
  laptop?: boolean;
  desktop?: boolean;
  full?: boolean;
  fullItems?: boolean;
  flex?: number;
  disabled?: boolean;
  $wrap?: boolean;
};

const Flex = styled.div<FlexProps>`
  display: flex;
  flex-shrink: column;
  align-items: ${props => (props.alignItems ? props.alignItems : 'initial')};
  justify-content: ${props => (props.justifyContent ? props.justifyContent : 'initial')};
  align-self: ${props => (props.alignSelf ? props.alignSelf : 'initial')};
  justify-self: ${props => (props.justifySelf ? props.justifySelf : 'initial')};
  flex-direction: ${props =>
    props.vertical ? (props.reverse ? 'column-reverse' : 'column') : props.reverse ? 'row-reverse' : 'row'};
  flex: ${props => (props.flex ? props.flex : 'initial')};
  flex-wrap: ${props => (props.$wrap ? 'wrap' : 'nowrap')};
  ${props => (props.full ? 'width: 100%; height: 100%;' : '')}
  ${props => (props.onClick ? `cursor: pointer;` : '')}
  //The opacity is eventually to be customized if needed
  ${props => (props.disabled ? `pointer-events: none; filter: grayscale(1); opacity: 0.4;` : '')}


  /* For every element inside this component, but only the first nested */
  > * {
    ${props => (props.fullItems ? 'width: 100%; height: 100%;' : '')}
    /* If not the last one */
    &:not(:last-child) {
      ${props =>
        props.gap
          ? `${props.vertical ? 'margin-bottom' : 'margin-right'}: ${
              props.gap === true ? props.theme.spacing.sm : props.theme.spacing[props.gap]
            };`
          : ''}
    }
  }

  /* The following code defines if the flex should be vertical
  if the current screen is mobile/tablet/laptop */
  ${props =>
    props.mobile
      ? `@media (max-width: ${props.theme.breakpoints.small}) {
          flex-direction: column;
          > * {
            /* If not the last one */
            &:not(:last-child) {
              margin-right: initial;
              ${
                props.gap
                  ? `margin-bottom: ${props.gap === true ? props.theme.spacing.sm : props.theme.spacing[props.gap]};`
                  : ''
              }
            }
          }
        }`
      : ''}

  ${props =>
    props.tablet
      ? `@media (max-width: ${props.theme.breakpoints.large}) {
        flex-direction: column;
        > * {
          /* If not the last one */
          &:not(:last-child) {
            margin-right: initial;
            ${
              props.gap
                ? `margin-bottom: ${props.gap === true ? props.theme.spacing.sm : props.theme.spacing[props.gap]};`
                : ''
            }
          }
        }
      }`
      : ''}

${props =>
    props.laptop
      ? `@media (max-width: ${props.theme.breakpoints.xlarge}) {
        flex-direction: column;
        > * {
          /* If not the last one */
          &:not(:last-child) {
            margin-right: initial;
            ${
              props.gap
                ? `margin-bottom: ${props.gap === true ? props.theme.spacing.sm : props.theme.spacing[props.gap]};`
                : ''
            }
          }
        }
      }`
      : ''}

  ${helpers};
`;

export default Flex;
