import Link from 'next/link';
import React, { ReactNode } from 'react';
import Styles from './styles';
import { useLocale } from 'src/locales';

//For internal links a href must be without added localization and without slash at the beginning. Localization will be added automatically.
//For external links (external set to true) full url must be provided.
const AppLink = (props: {
  href: string;
  children: ReactNode | string;
  external?: boolean;
  target?: string;
  rel?: string;
}) => {
  const locale = useLocale();
  const calculatedUrl = props.external || props.href.startsWith('http') ? props.href : `/${locale}/${props.href}`;
  return (
    <Link href={calculatedUrl}>
      <Styles.A {...props} href={calculatedUrl}>
        {props.children}
      </Styles.A>
    </Link>
  );
};

export default AppLink;
