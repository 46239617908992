import styled from 'styled-components';
import { HeaderHeroProps } from '.';
import { HeaderHeroContentProps } from '.';

export default {
  Container: styled.div<HeaderHeroProps>`
    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      display: block;

      ${props => (props.backgroundColor ? 'opacity: 1;' : 'filter: brightness(46%);')};

      z-index: 2;
      ${({ image, positionHorizontal = 'center', positionVertical = 'center' }) =>
        image ? `background: url(${image}) ${positionHorizontal} ${positionVertical} ;` : ''}

      background-repeat: no-repeat;
      background-size: ${props => props.imageSize || 'cover'};

      background-color: ${props => props.backgroundColor && props.theme.colors[props.backgroundColor]};
    }
  `,
  Content: styled.div<HeaderHeroContentProps>`
    position: inherit;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    ${({ adaptiveHeight = false }) => (adaptiveHeight ? 'padding-top: 90px; padding-bottom: 90px;' : 'height: 40vh;')}
    width: 100%;
  `
};
