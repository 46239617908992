import { helpers } from '@components';
import styled from 'styled-components';
import { LaptopAndUp } from '../stylingUtils';
import { SpacingName } from '../Theme';

export default {
  Container: styled.div<{ floatingPosition?: boolean }>`
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.08);
    background-color: ${props => props.theme.colors.white};

    ${props =>
      props.floatingPosition &&
      `
      ${helpers};

        ${LaptopAndUp(props)} {
          position: fixed;
          left: 0;
          z-index: 4;

          top: 50%;

          @media (max-height: ${props.theme.breakpoints.small}) {
            top: 375px;
          }

          transform: translate(0%, -50%);
        }

    `}
  `,
  Item: styled.div<{ active?: boolean; spacing?: SpacingName }>`
    > * {
      display: inline-block;
    }

    a {
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    ${props => LaptopAndUp(props)} {
      > * {
        box-sizing: border-box;
        display: block;

        margin: ${props => (props.spacing ? props.theme.spacing[props.spacing] : props.theme.spacing.default)} 0;
        padding: ${props => `${props.theme.spacing.xxsm} ${props.theme.spacing.default}`};
        padding-right: 0;
        margin-right: 20px;
        border-left: ${props => (props.active ? `3px solid ${props.theme.colors.primary}` : '3px solid transparent')};
        border-right: 3px solid transparent;
        transition: border-left 200ms ease-in-out;

        > * {
          padding-right: ${props => props.theme.spacing.xxsm};
        }

        img,
        svg {
          height: 24px !important;
          width: auto !important;
          max-width: 24px !important;

          color: ${props => (props.active ? props.theme.colors.primary : props.theme.colors.text.onWhite)};
        }
      }

      :first-child {
        > * {
          margin-top: ${props => props.theme.spacing.default};
        }
      }

      :last-child {
        > * {
          margin-bottom: ${props => props.theme.spacing.default};
        }
      }
    }
  `
};
