import React from 'react';
import { store } from 'react-notifications-component';
import 'animate.css/animate.min.css';
import 'react-notifications-component/dist/theme.css';
import { Flex, LocalizedText, SVGIcon } from '@components';

export type NotificationType = 'success' | 'danger' | 'info' | 'default' | 'warning' | undefined;

type NotificationContentProps = {
  translationKey?: string;
  customContent?: React.ReactNode;
  type?: NotificationType;
};

const NotificationContent = (props: NotificationContentProps) => (
  <Flex gap="sm" alignItems="center" justifyContent="space-between">
    <Flex gap="sm" alignItems="center" justifyContent="space-between">
      <SVGIcon
        size="17px"
        src={props.type == 'danger' ? '/static/icons/errorWhite.svg' : '/static/icons/checkWhite.svg'}
      />
      <div>{props.translationKey ? <LocalizedText id={props.translationKey} /> : props.customContent}</div>
    </Flex>
    <SVGIcon size="22px" src="/static/icons/crossWhite.svg" />
  </Flex>
);

const Notifications = {
  showMesage: (translationKey?: string, type?: NotificationType, customContent?: any, duration?: number) => {
    store.addNotification({
      message: <NotificationContent translationKey={translationKey} type={type} customContent={customContent} />,
      type: type,
      insert: 'top',
      container: 'top-center',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        //Danger means something went wrong and it is impportant to let user take time.
        duration: duration !== undefined ? duration : type == 'danger' ? 0 : 4000,
        onScreen: false
      },
      slidingEnter: {
        duration: 700,
        timingFunction: 'ease-in-out'
      },
      slidingExit: {
        duration: 500,
        timingFunction: 'ease-in-out'
      }
    });
  },
  showSuccessMesage: (translationKey?: string, customContent?: any, duration?: number) => {
    Notifications.showMesage(translationKey, 'success', customContent, duration);
  },
  showDangerMesage: (translationKey?: string, customContent?: any, duration?: number) => {
    Notifications.showMesage(translationKey, 'danger', customContent, duration);
  }
};

export default Notifications;
