import styled, { keyframes } from 'styled-components';
import helpers, { HelperProps } from '../helpers';

const loading = keyframes`
  0% {
		background-position: 100%
	}

	100% {
		background-position: 0%
	}
`;

const Loader = styled.div<
  HelperProps & { width?: string; height?: string; considerSpacing?: boolean; transparency?: number }
>`
  width: ${props => (props.width ? props.width : '100%')};
  height: ${props => (props.height ? props.height : '100%')};

  background: ${props =>
    `linear-gradient(to right, rgba(230, 230, 230, ${
      props.transparency ? props.transparency : 1
    }) 40%, rgba(212, 212, 212, ${props.transparency ? props.transparency : 1}) 50%, rgba(230, 230, 230, ${
      props.transparency ? props.transparency : 1
    }) 60%)`};

  background-size: 400% 400%;
  animation: ${loading} 2s ease infinite;

  box-sizing: ${props => (props.considerSpacing ? 'border-box' : 'initial')};

  ${helpers}
`;

export default Loader;
