import React, { useEffect, useState } from 'react';
import Text from '../../components/Text';
import SelectInputStyles from './styles';
import Input from '../../components/Input';
import ChipList from '../ChipList';
import Flex from '../../components/Flex';
import LocalizedText from 'src/components/LocalizedText';
import SVGIcon from '../../components/SVGIcon';

type SelectOption = { name: string } | { [key: string]: any };

type SelectInputProps = {
  options?: SelectOption[];
  placeholder?: string;
  onOptionClick?: (option: SelectOption, selectedList: SelectOption[], e: React.MouseEvent) => void;
  multi?: boolean;
  displayChips?: boolean;
  field?: string;
  selected?: SelectOption[];
  disabled?: boolean;
  getFieldDescription?: (option: SelectOption) => string;
  // if true, you cannot check off an option anymore by selecting it again
  disableUnselect?: boolean;
};

const SelectInput: React.FC<SelectInputProps> = props => {
  const [selectHidden, setSelectHidden] = useState(true);
  const [selectedItems, setSelectedItems] = useState<SelectOption[]>(props.selected || []);
  const [selected, setSelected] = useState('');

  const field = props.field || 'name';

  useEffect(() => {
    if (!props.selected?.[0]?.name) return;

    setSelected(props.selected[0].name);
    setSelectedItems(props.selected);
  }, [props.selected]);

  const selectedIndex = (option: SelectOption) =>
    (props.selected || selectedItems).findIndex(x => x[field] === option[field]);

  const toggleSelected = (option: SelectOption) => {
    const index = selectedIndex(option);
    // Already selected?
    if (index !== -1) {
      // Diselect
      const newArray = [...selectedItems.slice(0, index), ...selectedItems.slice(index + 1)];
      setSelectedItems(newArray);
      !props.disableUnselect && setSelected('');
      return newArray;
    } else {
      // Select
      const newArray = !props.multi ? [option] : [...selectedItems, option];
      setSelectedItems(newArray);
      setSelected(props.getFieldDescription ? props.getFieldDescription(newArray[0]) : newArray[0][field]);
      return newArray;
    }
  };

  return (
    <>
      <SelectInputStyles.Container onMouseLeave={() => setSelectHidden(true)} disabled={props.disabled}>
        <Input.Text
          onClick={() => setSelectHidden(!selectHidden)}
          placeholder={props.placeholder}
          value={selected}
          readOnly
        />
        <SelectInputStyles.IconContainer onClick={() => setSelectHidden(!selectHidden)}>
          <img src="/static/icons/semiArrowDownTinyGray.svg" />
        </SelectInputStyles.IconContainer>
        {!selectHidden && (
          <SelectInputStyles.OptionsContainer seconds={0.2}>
            {props.options && props.options.length > 0 ? (
              props.options.map(option => {
                return (
                  <SelectInputStyles.Option
                    key={option[field]}
                    onClick={e => {
                      const newSelected = toggleSelected(option);
                      if (props.onOptionClick) props.onOptionClick(option, newSelected, e);
                      setSelectHidden(true);
                    }}
                    selected={selectedIndex(option) !== -1}
                    data-analytics-id={props['data-analytics-id']}
                  >
                    {props.getFieldDescription ? props.getFieldDescription(option) : option[field]}
                  </SelectInputStyles.Option>
                );
              })
            ) : (
              <Flex vertical full alignItems="center" justifyContent="center" padding="default">
                <SVGIcon src="/static/icons/listLight.svg" marginBottom="sm" />
                <Text.SmallParagraph color="onWhiteLight">
                  <LocalizedText id="noData" description="noData" />
                </Text.SmallParagraph>
              </Flex>
            )}
          </SelectInputStyles.OptionsContainer>
        )}
      </SelectInputStyles.Container>
      {props.displayChips && (
        <ChipList
          isFromSelect
          disabled={props.disabled}
          field={field}
          chips={props.selected || selectedItems}
          onChipClick={(chip, e) => {
            const newSelected = toggleSelected(chip);
            if (props.onOptionClick) props.onOptionClick(chip, newSelected, e);
          }}
        />
      )}
    </>
  );
};

export default React.memo(SelectInput);
