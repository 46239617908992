import { Flex } from '@components';
import React from 'react';
import { SpacingName } from '../Theme';
import Styles from './styles';

type BarIndicatorProps = {
  maxValue?: number | string | undefined;
  value?: number | string | undefined;
  marginTop?: SpacingName;
};

const BarIndicator: React.FC<BarIndicatorProps> = props => {
  return (
    <Flex marginTop={props.marginTop} gap="xxsm">
      {[...Array(Number(props.maxValue))].map((x, i) => (
        <Styles.Tile key={i} noPadding active={i < Number(props.value)} />
      ))}
    </Flex>
  );
};

BarIndicator.defaultProps = {
  marginTop: 'sm'
};

export default React.memo(BarIndicator);
